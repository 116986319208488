import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React, { createRef } from 'react'
import { handleNavigation } from "../../utilities/src/CustomHelpers";
import { getStorageData } from "../../../framework/src/Utilities";
export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    id: string;
    handleCommonPath:(path:string) => void
}

interface SS {
    id: any;
}
  
interface S {
  channelActiveImg: string | null;
  podcastEpisodes:any;
  podcastData:any;
  loading:boolean;
  isDeleteEpisode:boolean;
  deleteId:string;
}

export default class BuildController extends BlockComponent<Props, S, SS> {
  apiPodcastEpisodesCallId: string = "";
  apiDeleteEpisodesCallId: string = "";
  scrollRef: any = createRef();
  
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      channelActiveImg: null,
      podcastEpisodes:[],
      podcastData:[],
      loading:false,
      isDeleteEpisode:false,
      deleteId:"",
    };
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getPodcastEpisodes()
    setTimeout(() => {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
     // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiPodcastEpisodesCallId != null &&
      this.apiPodcastEpisodesCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );    
      if (responseJson && !responseJson.errors) {
        this.setState({podcastData:responseJson.podcast.data.attributes,podcastEpisodes:responseJson.podcast.data.attributes.episodes.data, loading:false})
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiDeleteEpisodesCallId != null &&
      this.apiDeleteEpisodesCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );    
      if (responseJson && !responseJson.errors) {
        this.setState({isDeleteEpisode:false})
        this.getPodcastEpisodes()
      }
    }
    // Customizable Area End
  }

  deleteEpisode = async () => {
    let token = await getStorageData("token");

    const header = {
      token: JSON.parse(token),
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDeleteEpisodesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteEpisode}/${this.state.deleteId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodDeleteType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleChannelClick = (imageId: string) => {
    this.setState({ channelActiveImg: imageId });
  };


  handleDeleteClick = (imageId: string) => {
    this.setState({ isDeleteEpisode: true , deleteId:imageId});
  };

  handleChannelManagement = () => {
    this.props.handleCommonPath("ChannelManagement")
  }

  onCancelHandler = () => {
    this.setState({
      isDeleteEpisode: false,
    });
  }

  handleMediaManagement = () => {
    this.props.handleCommonPath("MediaManagement")
  }

  handleUploadNewContent = () => {
    this.props.handleCommonPath("NewContent")
  }

  getPodcastEpisodes = async () => {
    this.setState({loading:true})
    let token = await getStorageData("token");

    const header = {
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiPodcastEpisodesCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.podcastData
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

}
