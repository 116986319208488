// Customizable Area Start
import { createRef } from 'react';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import { IBlock } from '../../../../framework/src/IBlock';
import MessageEnum, { getName } from '../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../framework/src/RunEngine';
import { suggested1, suggested2 } from '../../../dashboard/src/assets';
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    handleCommonPath: (path: string) => void;
    // Customizable Area End
};

// Customizable Area Start
export interface TrendingEpisodeDetail {
    id: number;
    src: any;
    artist: string;
    name: string;
    selected: boolean;
    added: boolean;
};
interface S {
    trendingEpisode: TrendingEpisodeDetail[],
};
// Customizable Area End

interface SS {
    id: any;
};

export default class TrendingEpisodeController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getTrendingEpisodeApiCallId: any;
    scrollRef: any = createRef();
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            trendingEpisode: [],
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.fetchTrendingEpisodes();
        this.setState({
            trendingEpisode: [
                { id: 1, src: suggested1, artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false },
                { id: 2, src: suggested2, artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false },
                { id: 3, src: suggested2, artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: true, added: true },
                { id: 4, src: suggested1, artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false },
                { id: 5, src: suggested1, artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: true, added: true },
                { id: 6, src: suggested1, artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false },
                { id: 7, src: suggested2, artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false },
                { id: 8, src: suggested2, artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: true, added: true },
                { id: 9, src: suggested1, artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false },
                { id: 10, src: suggested1, artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: true, added: true },
                { id: 11, src: suggested1, artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false },
                { id: 12, src: suggested2, artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false },
                { id: 13, src: suggested2, artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: true, added: true },
                { id: 14, src: suggested1, artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false },
                { id: 15, src: suggested1, artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: true, added: true },
                { id: 16, src: suggested1, artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false },
                { id: 17, src: suggested2, artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false },
                { id: 18, src: suggested2, artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: true, added: true },
                { id: 19, src: suggested1, artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false },
                { id: 20, src: suggested1, artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: true, added: true },
            ],
        });
        setTimeout(() => {
            if (this.scrollRef.current) {
                this.scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
            }
        }, 0);
    }

    fetchTrendingEpisodes = () => { };
    // Customizable Area End
}
