// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from '../../../framework/src/Message';
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { createRef } from "react";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath: (path:string) => void;
}

export interface S {
  sponsoredPost: string,
  typeOfPost: {
    audio: boolean,
    video: boolean,
    textOnly: boolean,
    graphic: boolean,
  },
}

export interface SS {
  id: any;
}

export default class HostMonetizationSponsoredPostController extends BlockComponent<
  Props,
  S,
  SS
> {

  getmemoizationSponsoredPostDataApiCallId = "";
  updateMonitizationSponsoredPostCallId = "";
  deleteMonetizeSponsoredPostCallId = "";
  scrollRef1: any = createRef();

  async receive(from: string, message: Message) {   
    if (this.updateMonitizationSponsoredPostCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForUpdateSponsoredPostApi(message);
    }
    if (this.getmemoizationSponsoredPostDataApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForMonetizationSponsoredPostData(message);
    }
    if (this.deleteMonetizeSponsoredPostCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForDeleteMonetizationSponsoredPostData(message);
    }
  }

  async componentDidMount() {
    this.getmemoizationSponsoredPostData()
    setTimeout(() => {
      if (this.scrollRef1.current) {
        this.scrollRef1.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
  }

  getmemoizationSponsoredPostData = async () => {

    let token = await getStorageData("token");
    let prodcastId = await getStorageData("podcast_id");
    const header = {
      token: JSON.parse(token),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getmemoizationSponsoredPostDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getmonetizationData}?podcast_id=${prodcastId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateMonitization = async (values:any) => {
    console.log(values,"valuess");
    
    let token = await getStorageData("token");
    let prodcastId = await getStorageData("podcast_id");
    let monetization_id = await getStorageData("monetization_id")
    let arr = [];
    for(let item in values.typeOfPost){
      if(values.typeOfPost[item] === true){
          arr.push(item)
      }
  }
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: JSON.parse(token),
    };
    const body = {
      "monetization": {
        "id": monetization_id,
        "podcast_id": prodcastId,
        "post_count": values.sponsoredPost,       
        "type_of_post":arr,
      }
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateMonitizationSponsoredPostCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateMonitization}/${monetization_id}`

    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),

      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),

      configJSON.patchAPiMethod
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteMonetizationSponsoredPost = async () => {
    let token = await getStorageData("token");
    let monetization_id = await getStorageData("monetization_id")
    const header = {
      token: JSON.parse(token),
    };

    const getMonetizationrequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteMonetizeSponsoredPostCallId = getMonetizationrequest.messageId;
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteMonetize}/${monetization_id}`
    );
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );
    runEngine.sendMessage(getMonetizationrequest.id, getMonetizationrequest);
  };

  handleResForDeleteMonetizationSponsoredPostData = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));  
    if (responseJson.message) {
      removeStorageData("monetization_id")
      removeStorageData("monetizationIds")
      removeStorageData("monetizationCategories")
     this.props.handleCommonPath("HostMonetizationSetup")          
    }
  };
  handleResForMonetizationSponsoredPostData = async (message: Message) => {

    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 

    if (responseJson.data) {
     this.setState({
      sponsoredPost : responseJson.data.attributes.post_count,
      typeOfPost : {
        audio : responseJson.data.attributes.type_of_post.includes("audio"),
        video : responseJson.data.attributes.type_of_post.includes("video"),
        textOnly : responseJson.data.attributes.type_of_post.includes("textOnly"),
        graphic : responseJson.data.attributes.type_of_post.includes("graphic")
      },

     })
    }
  }; 
  
  constructor(props: Props) {

    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),

      getName(MessageEnum.NavigationPayLoadMessage),
      
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      sponsoredPost: '',
      typeOfPost: {
        audio: false,
        video: false,
        textOnly: false,
        graphic: false,
      },
    };
  }

  handleResForUpdateSponsoredPostApi = async (message: Message) => {

    const responseApi = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (responseApi.data) {
      this.props.handleCommonPath("HostMonetizationChooseTemplate")    
    }
  };
 


}
// Customizable Area End
