export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const imgVisbility = require("../assets/visibility.svg");
export const imgVisbilityOff = require("../assets/visibilityOff.svg");
export const backgroundImg = require("../assets/backgroundImg.jpeg").default;
export const mainLogo = require("../assets/mainLogo.png").default;
export const logoSvg = require("../assets/logoSvg.svg").default;
export const visibility_icon = require("../assets/visibility_copy.svg").default;
export const checkbox = require("../assets/checkbox.svg").default;
export const visibility_cross = require("../assets/visibilityCross.svg").default;
export const darkBg = require("../assets/darkIcon.png").default;
export const lightBg = require("../assets/lightIcon.png").default;
export const checkboxTicked  = require("../assets/tickedCheckbox.png").default;
export const hostBg  = require("../assets/hostImage.png").default;
export const star = require("../assets/star_24px.png").default;
export const leftImage  = require("../assets/imageLeft.png").default;
export const rightImage  = require("../assets/imageRight.png").default;
export const hostMethod3  = require("../assets/hostMethod3.png").default;
export const group_applepay  = require("../assets/group_applepay.png").default;
export const group_googlepay  = require("../assets/group_googlepay.png").default;
export const group_klarna  = require("../assets/group_klarna.png").default;
export const group_maestro  = require("../assets/group_maestro.png").default;
export const group_paypal  = require("../assets/group_paypal.png").default;
export const group_visa  = require("../assets/group_visa.png").default;
export const  cart = require("../assets/image_cta.png").default;
export const rssFeedBackground = require("../assets/rss_feed_background.png").default;
export const validated = require("../assets/validated.svg").default;
export const not_validated = require("../assets/not_validated.svg").default;
export const validatedPreview = require("../assets/validLinkPreview.png").default;
export const preview1440 = require("../assets/preview1440.png").default;
export const goBack = require("../assets/goBack.svg").default;
export const  upload = require("../assets/upload.png").default;
export const image_group = require("../assets/image_group.png").default;
export const image_group_1 = require("../assets/image_group_1.png").default;
export const podcastImage = require("../assets/product_card_small_item_gen.png").default;
export const tshirtIcon = require("../assets/tshirtIcon.png").default;
export const recommended_episode_1 = require("../assets/image3.png").default;
export const recommended_episode_2 = require("../assets/image4.png").default;
export const recommended_episode_3 = require("../assets/image5.png").default;
export const ad_1 = require("../assets/image1.png").default;
export const ad_2 = require("../assets/image2.png").default;
export const template1 = require("../assets/template1.png").default;
export const template2 = require("../assets/template2.png").default;
export const template3 = require("../assets/template3.png").default;
export const template4 = require("../assets/template4.png").default;
export const appLogo = require("../assets/appLogo.png").default;
export const image_building1 = require("../assets/image_building1.png").default;
export const image_building2 = require("../assets/image_building2.png").default;
export const image_building3 = require("../assets/image_building3.png").default;
export const image_building4 = require("../assets/image_building4.png").default;
export const image_building5 = require("../assets/image_building5.png").default;
export const image_building6 = require("../assets/image_building6.png").default;
export const image_building7 = require("../assets/image_building7.png").default;
export const image_building8 = require("../assets/image_building8.png").default;
export const image_building9 = require("../assets/image_building9.png").default;
export const image_building10 = require("../assets/image_building10.png").default;
export const image_number1 = require("../assets/image_number1.png").default;
export const image_number2 = require("../assets/image_number2.png").default;
export const image_number3 = require("../assets/image_number3.png").default;
export const image_number4 = require("../assets/image_number4.png").default;
export const image_number5 = require("../assets/image_number5.png").default;
export const image_number6 = require("../assets/image_number6.png").default;
export const image_number7 = require("../assets/image_number7.png").default;
export const image_number8 = require("../assets/image_number8.png").default;
export const image_number9 = require("../assets/image_number9.png").default;
export const image_number10 = require("../assets/image_number10.png").default;
export const template = require("../assets/template.png").default;
export const templateSelected = require("../assets/template_sceond.png").default;
export const offeringConsulting = require("../assets/offeringConsulting.png").default;
export const sponsoredPost = require("../assets/sponsoredPost.png").default;
export const chooseTemplate1 = require("../assets/chooseTemplate1.png").default;
export const chooseTemplate2 = require("../assets/chooseTemplate2.png").default;
export const chooseTemplate3 = require("../assets/chooseTemplate3.png").default;
export const sellingDigitalProducts = require("../assets/sellingDigitalProduct.png").default;
export const affiliateMarketing = require("../assets/affiliateMarketing.png").default;
export const hostingWebinar = require("../assets/hostingWebinar.png").default;
export const merchandiseSales = require("../assets/merchandiseSales.png").default;
export const imageAirlPlay = require("../assets/imageAirplay.png").default;
export const imagePlayCircle = require("../assets/imagePlayCircle.png").default;
export const imageFullScreen = require("../assets/imageFullscreen.png").default;
export const imageSwitchUnchecked = require("../assets/switch.png").default;
export const imageSwitchChecked = require("../assets/switch_selected.png").default;

export const box1 = require("../assets/box1.png").default;
export const box2 = require("../assets/box2.png").default;
export const box3 = require("../assets/box3.png").default;
export const edit = require("../assets/edit.png").default;
export const edit_w = require("../assets/edit_w.png").default;
export const rightMostImage = require("../assets/podcastImage.svg").default;