import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { URLSearchParams } from "url";
import React from "react"
import { leftArrow, rightArrow } from "../../dashboard/src/assets";
import { search1 } from "./assets";
interface ArrowProps {
  onClick: () => void;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleCommonPath: (path:string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  searchText: string;
  searchList: any;
  activeId: number;
  activeFirstName: string;
  activeLastName: string;
  activeUserName: string;
  activeEmail: string;
  activePhoneNumber: string;
  activeCountryCode: string;
  activeType: string;
  activeDeviceId: string;
  activeCreatedAt: string;
  isVisible: boolean;

  searchValue : string;
  searchPodcast : any;
  searchEpisode : any;
  searchPost : any;
  filterCategory : any;
  filteredChannels : any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SearchController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  searchApiCallId: any;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      searchText: "",
      searchList: [],
      activeId: 0,
      activeFirstName: "",
      activeLastName: "",
      activeUserName: "",
      activeEmail: "",
      activePhoneNumber: "",
      activeCountryCode: "",
      activeType: "",
      activeDeviceId: "",
      activeCreatedAt: "",
      isVisible: false,

      searchValue: "",
      searchPodcast: [
        {
          "id": "55",
          "type": "podcast",
          "attributes": {
            "name": "Test Podcast",
            "description": "dfghjklgfhj",
            "location": "sfdg",
            "explicit_lang": true,
            "adult": true,
            "timezone": "zxcbn",
            "podcast_time": "2000-01-01T12:20:12.000Z",
            "host_type": "solo",
            "rss_url": null,
            "share_channel_count": 0,
            "channel_url": "Coming Soon Channel Url",
            "embed_code": "Coming Soon Embed data",
            "cover_image": null,
            "episodes": {
              "data": [
                {
                  "id": "1",
                  "type": "episode",
                  "attributes": {
                    "name": "indore",
                    "description": "indore",
                    "episode_number": 1,
                    "episode_content": null,
                    "cover_image": null,
                    "adult": null,
                    "podcast_season": null,
                    "premium": null,
                    "episode_type": null,
                    "expiry_date": null,
                    "explicit_content": null,
                    "publish_date": null,
                    "episode_time": null,
                    "account": null,
                    "share_episode_count": 0,
                    "episode_url": "Coming Soon Episode Url",
                    "embed_code": "Coming Soon Embed Data",
                    "chapters": {
                      "data": []
                    },
                    "save_episode": {
                      "saved": false,
                      "saved_id": null
                    },
                    "liked_episode": {
                      "liked": false,
                      "liked_id": null,
                      "count": 1
                    },
                    "comments": {
                      "data": []
                    },
                    "added": {
                      "song_added": false
                    }
                  }
                },
              ]
            },
          }
        }
      ],
      searchEpisode: [
        {
          "id": "45",
          "type": "podcast",
          "attributes": {
            "name": "Test Podcast",
            "description": "dfghjklgfhj",
            "location": "sfdg",
            "explicit_lang": true,
            "adult": true,
            "timezone": "zxcbn",
            "podcast_time": "2000-01-01T12:20:12.000Z",
            "host_type": "solo",
            "rss_url": null,
            "share_channel_count": 0,
            "channel_url": "Coming Soon Channel Url",
            "embed_code": "Coming Soon Embed data",
            "cover_image": null,
            "episodes": {
              "data": [
                {
                  "id": "30",
                  "type": "episode",
                  "attributes": {
                    "description": "indore",
                    "name": "test",
                    "episode_content": null,
                    "episode_number": 1,
                    "cover_image": null,
                    "podcast_season": null,
                    "episode_type": null,
                    "explicit_content": null,
                    "expiry_date": null,
                    "publish_date": null,
                    "episode_time": null,
                    "account": null,
                    "share_episode_count": 0,
                    "episode_url": "Coming Soon Episode Url",
                  }
                },
              ]
            },
          }
        },
      ],
      searchPost: [
        {
          "id": "77",
          "type": "podcast",
          "attributes": {
            "name": "Test Podcast",
            "description": "dfghjklgfhj",
            "location": "sfdg",
            "timezone": "zxcbn",
            "host_type": "solo",
            "rss_url": null,
            "channel_url": "Coming Soon Channel Url",
            "cover_image": null,
          }
        },
      ],
      filterCategory: "",
      filteredChannels:[
        {
          imageUrl: search1,
          songName: "abcd",
          artist: "efgh",
          following: "true"
        }      
      ]
      // Customizable Area End
    };
    // Customizable Area Start
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();

    // Customizable Area Start 
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start   
    // Customizable Area End
  }

  // Customizable Area Start
  

  getSuggestedSlickSettings() {
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
  
    return {
      dots: true,
      slidesToScroll: 1,
      infinite: false,
      speed: 800,
      initialSlide: 0,
      nextArrow: React.createElement(this.nextSponsoredArrow, { onClick: () => {}, innerWidth }),
      slidesToShow: 6,
      prevArrow: React.createElement(this.prevSponsoredArrow, { onClick: () => {}, innerWidth }),
      appendDots: (dots:any) => (
        <div
          style={{
            top:"50%",
            width: '100%',
            position: 'absolute',
            textAlign: 'center',
          }}
        >
          <ul 
          style={{ margin: 0, padding: 0 }}>
             {dots}
         </ul>
        </div>
      ),
      responsive: [
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1700,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 6,
            slidesToScroll: Math.min(4, 6),
          },
        },
      ],
    };
  }   
  
  getSuggestedSlickSettings1() {
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
  
    return {
      dots: true,
      infinite: false,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: React.createElement(this.nextSponsoredArrow, { onClick: () => {}, innerWidth }),
      prevArrow: React.createElement(this.prevSponsoredArrow, { onClick: () => {}, innerWidth }),
      appendDots: (dots:any) => (
        <div
          style={{
            position: 'absolute',
            top:"470px",
            width: '100%',
            textAlign: 'center',
          }}
        >
          <ul style={{ margin: 0, padding: 0 }}> {dots} </ul>
        </div>
      ),
      responsive: [
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: 1, // Always 1 slide
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1700,
          settings: {
            slidesToShow: 1, // Always 1 slide
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 1, // Always 1 slide
            slidesToScroll: 1,
          },
        },
      ],
    };
  }
  
  
  prevSponsoredArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick } = props;
  
    return (
      <div
        onClick={onClick}
        style={{
          height: "50px",
          width: "50px",
          borderRadius: "50%",
          background: "#FF0807",
          position: "absolute",
          top: "25%",
          left: "-2%",
          transform: "translateY(-50%)",
          cursor: "pointer",
          zIndex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={leftArrow}
          style={{
            width: "70%",
            height: "70%",
          }}
          alt="Arrow"
        />
      </div>
    );
  };
  
  nextSponsoredArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick } = props;
  
    return (
      <div
        onClick={onClick}
        style={{
          height: "50px",
          width: "50px",
          borderRadius: "50%",
          background: "#FF0807",
          position: "absolute",
          top: "25%",
          right: "-2%",
          transform: "translateY(-50%)",
          cursor: "pointer",
          zIndex: 1,
          display: "flex", 
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={rightArrow}
          style={{
            width: "70%", // Adjust size for better fit
            height: "70%",
          }}
          alt="Arrow"
        />
      </div>
    );
  };
  

  
  
  handleSearchChange = (event: any) => {
    this.setState({
      searchValue: event.target.value
    })
  }

  txtInputSearchTextProps = {
    onChangeText: (text: string) => {
      this.setState({ searchText: text });
    },
  };

  setSearchText = (text: string) => {
    this.setState({ searchText: text });
  };

  hideModal = () => {
    this.setState({ isVisible: !this.state.isVisible });
  };

  setModal = (item: any) => {
    this.setState({
      activeId: item.id,
      activeFirstName: item.attributes.first_name,
      activeLastName: item.attributes.last_name,
      activeUserName: item.attributes.user_name,
      activeEmail: item.attributes.email,
      activePhoneNumber: item.attributes.phone_number,
      activeCountryCode: item.attributes.country_code,
      activeType: item.type,
      activeDeviceId: item.attributes.device_id,
      activeCreatedAt: item.attributes.created_at,
      isVisible: !this.state.isVisible,
    });
  };

  getSearchList = (token: string) => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const attrs = {
      query: this.state.searchText,
    };

    this.searchApiCallId = requestMessage.messageId;
    let urlParams = new URLSearchParams(attrs).toString();

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getSearchApiEndPoint}?${urlParams}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  // Customizable Area End
}
