import React from "react";
// Customizable Area Start
import { styled, Box, Typography, Button, IconButton, Card, CardContent, CardMedia, Link, Grid, Dialog, DialogContent, Divider, DialogActions, Avatar, Collapse, Menu, MenuItem, ListItemIcon, CardActionArea } from "@mui/material";
import { Star, StarBorder, StarHalf, Phone, InfoOutlined, AttachMoney, Laptop, ShoppingCartOutlined, LocalOfferOutlined, MenuBook, ThumbUpOffAlt, ChatBubbleOutline, Share, FavoriteBorder, PlaylistAdd, PlayCircleFilled, Favorite, PlaylistAddCheck, CloseOutlined, Close, FileUploadOutlined, MicExternalOn, Send, PushPinOutlined, MoreHoriz, TurnedInNotOutlined, NotificationsNoneOutlined, VisibilityOff, Block, LocationOn } from '@mui/icons-material';
import { podcastImage, tshirtIcon, recommended_episode_1, recommended_episode_2, recommended_episode_3, ad_1, ad_2 } from "./assets";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import HostCatalogueController, { Props } from "./HostCatalogueController";
import { ResolveCondition } from "../../utilities/src/CustomHelpers";
const MIN_WINDOW_HEIGHT = 900

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class HostCatalogue extends HostCatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderHostCommunityPost = (getHostCommunityPost:any[]) => {
    return (
      <>
        <Box sx={{ marginRight: "22px" }}>
          <Box
            sx={{
              display: 'flex',
              backgroundColor: '#44403C',
              borderRadius: '8px',
              padding: '22px 33px 22px 37px',
              width: '100%',
            }}
          >
            <Box display={"flex"} textAlign={"center"}>
              <Avatar
                alt="User Avatar"
                src={podcastImage}
                sx={{ marginRight: '18px', width: '56px', height: '56px' }}
              />
            </Box>
            <Box sx={{ width: '100%', height: '80px', background: '#000', borderRadius: '0 0 7px 7px', display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
              <input
                type="text"
                placeholder='Create A Post'
                style={{
                  background: '#000',
                  border: 'none',
                  outline: 'none',
                  width: '100%',
                  fontFamily: "Nexa-Regular",
                  fontSize: '14px',
                  lineHeight: '26px',
                  color: "#fff",
                  padding: "17px 20.27px 0px 27.43px"
                }}
              />
              <Box>
                <MicExternalOn
                  style={{
                    width: "27px",
                    height: '27px',
                    borderRadius: "25px",
                    background: '#FF0000',
                    color: '#fff',
                    padding: '4.5px',
                    marginRight: "12px"
                  }} />
                <Send style={{ width: "27px", height: '27px', borderRadius: "25px", background: '#DADADA', color: '#fff', padding: '4.5px', marginRight: "12.71px" }} /></Box>
            </Box>
          </Box>

          <div>
            {getHostCommunityPost.map((post) => (
              <Box
                key={post.id}
                sx={{
                  width: "100%",
                  padding: "33px 41px 18px 43px",
                  borderRadius: "20px",
                  marginTop: "25px",
                  border: this.state.expandedCardId.includes(post.id)
                    ? "5px solid #EC2025"
                    : "none",
                  background: "#44403C",
                  color: "#fff",
                  transition: "#EC2025 0.3s ease",
                }}
                onClick={() => this.handleCardClick(post.id)}
              >
                <Box sx={{ display: "flex", marginBottom: "18px" }}>
                  <Avatar
                    src={post.avatar}
                    sx={{ marginRight: "40px", width: "56px", height: "56px" }}
                  />
                  <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
                    <Box>
                      <Typography variant="subtitle1" sx={{ ...webStyles.cardIcon }}>
                        {post.name}
                      </Typography>
                      <Typography
                        variant="caption"
                        style={{ fontSize: "10px", fontFamily: "Nexa-Regular" }}
                      >
                        {post.date}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontSize: "14px",
                          lineHeight: "26px",
                          fontFamily: "Nexa-Regular",
                        }}
                      >
                        {post.body}
                      </Typography>
                    </Box>
                    <Box display={"flex"} alignItems={"flex-start"}>
                      <IconButton style={{ color: "#fff", paddingTop: "6px", transform: "rotate(40deg)"}}>
                        <PushPinOutlined />
                      </IconButton>
                      <IconButton style={{ color: "#fff",  paddingTop: "0px" }} onClick={(e) => this.handleMenuOpen(e)}>
                        <MoreHoriz fontSize={"large"} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "0px 85.39px 0px 94.64px ",
                  }}
                >
                  <Collapse
                    in={this.state.expandedCardId.includes(post.id)}
                    timeout="auto"
                    unmountOnExit
                    sx={{ mt: "-22px", mb: "12px" }}
                  >
                    <Box
                      sx={{
                        padding: 2,
                      }}
                    >
                      <Box sx={{}}>
                        <img
                          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ1MCJuxcGsjCT21fKnMVPxxdcVMcgU13jIqA&s"
                          style={{
                            width: "650.83px",
                            height: "308px",
                            borderRadius: "43px",
                            paddingTop: "15px",
                          }}
                        />
                      </Box>
                    </Box>
                  </Collapse>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "22px",
                      color: "#fff",
                    }}
                  >
                    <Typography variant="caption" sx={{ ...webStyles.cardIcon }}>
                      {post.days}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "11px",
                      }}
                    >
                      <ThumbUpOffAlt fontSize="medium" />
                      <Typography variant="caption" sx={{ ...webStyles.cardIcon }}>
                        {post.likes}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "11px",
                      }}
                    >
                      <ChatBubbleOutline fontSize="medium" />
                      <Typography variant="caption" sx={{ ...webStyles.cardIcon }}>
                        {post.comments}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "11px",
                      }}
                    >
                      <Share fontSize="medium" />
                      <Typography variant="caption" sx={{ ...webStyles.cardIcon }}>
                        {post.shares}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))}
          </div>
        </Box>

        <Menu
          onClose={this.handleMenuClose}
          open={Boolean(this.state.anchorEl)}
          anchorEl={this.state.anchorEl}
          sx={{
            "& .MuiPaper-root": {
              backgroundColor: "#44403C",
              color: "#FFFFFF",
              border: "1px solid #ffffff",
              width: "200px",
            },
          }}
        >
          <Box sx={{
            "&:hover": {
              backgroundColor: "#FF0807",
            },
            marginTop: "-8px",
          }}>
            <MenuItem
              sx={{ height: "60px" }}
              onClick={this.handleMenuClose}
            >
              <ListItemIcon>
                <TurnedInNotOutlined style={{ color: "#FFFFFF", fontSize: "36px" }} />
              </ListItemIcon>
              <span style={{ fontSize: "14px", marginLeft: "8px" }}> Save </span>
            </MenuItem>
          </Box>
          <Divider sx={{ borderColor: "#ffffff" }} />
          <Box sx={{
            "&:hover": {
              backgroundColor: "#FF0807",
            },
          }}>
            <MenuItem
              onClick={this.handleMenuClose}
              sx={{ height: "60px" }}
            >
              <ListItemIcon>
                <NotificationsNoneOutlined style={{ color: "#FFFFFF", fontSize: "36px" }} />
              </ListItemIcon>
              <span style={{ fontSize: "14px", marginLeft: "8px" }}>Turn on notifications</span>
            </MenuItem>
          </Box>
          <Divider sx={{ borderColor: "#ffffff" }} />
          <Box sx={{
            "&:hover": {
              backgroundColor: "#FF0807",
            },
          }}>
            <MenuItem
              onClick={this.handleMenuClose}
              sx={{ height: "60px" }}
            >
              <ListItemIcon>
                <VisibilityOff style={{ color: "#FFFFFF", fontSize: "36px" }} />
              </ListItemIcon>
              <span style={{ fontSize: "14px", marginLeft: "8px" }}> Hide</span>
            </MenuItem>
          </Box>
          <Divider sx={{ borderColor: "#ffffff" }} />
          <Box sx={{
            "&:hover": {
              backgroundColor: "#FF0807",
            },
            marginBottom: "-8px",
          }}>
            <MenuItem
              onClick={this.handleMenuClose}
              sx={{ height: "60px" }}
            >
              <ListItemIcon>
                <Block style={{ color: "#FFFFFF", fontSize: "36px" }} />
              </ListItemIcon>
              <span style={{ fontSize: "14px", marginLeft: "8px" }}>Block User</span>
            </MenuItem>
          </Box>
        </Menu>
      </>
    )
  }

  renderHostChannelInfo = (hostChannelInfoData:any[]) => {
    const { name, subtitle, description, location, cover_image } = this.state.hostCardInfoData;
    
    return (
      <>
        <Box
          sx={{
            backgroundColor: "#44403C",
            color: "#fff",
            padding: "2rem",
            borderRadius: "8px",
            margin: "0 10px 0 0",
          }}
        >
          <Grid gap="30px" container spacing={2} alignItems="center">
            <Grid item>
              <Card
                sx={{
                  width: "150px", height: "125px",
                  overflow: "hidden",
                  borderRadius: "3px",
                }}
              >
                <img
                  src={cover_image?.image_link}
                  alt="Podcast Cover"
                  style={{ width: "150px", height: "125px", objectFit: "cover" }}
                />
              </Card>
            </Grid>
            <Grid style={{ display:"grid", gap:"5px"}} item xs>
              <Typography variant="h5" sx={{ ...webStyles.mainCardHeader }}>
                {name}
              </Typography>
              <Typography variant="h4" sx={{ fontFamily: "Nexa-Heavy", fontSize: "30px", lineHeight:"30px", textTransform: "uppercase", fontWeight: 900, color: "#FFFFFF" }}>
              {subtitle}
              </Typography>
              <Box display="flex" alignItems="center" mt={1}>
                <LocationOn fontSize="small" />
                <Typography variant="body1" ml={1} sx={{ fontFamily: "Nexa-Heavy", fontSize: "18px", fontWeight: 900, color: "#FFFFFF" }}>
                  {location}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box mt={4}>
            <Typography variant="h6" fontWeight="bold" gutterBottom sx={{ ...webStyles.descriptionTypo }}>
              About
            </Typography>
            <Typography variant="body2" paragraph sx={{ fontFamily: "Nexa-Regular", fontSize: "14px", fontWeight: 100, color: "#FFFFFF" }}>
              {description}
            </Typography>
          </Box>

          <Box mt={4}>
            <Typography variant="h6" gutterBottom sx={{ ...webStyles.descriptionTypo }}>
              Main Host:
            </Typography>
            <Box display="flex" alignItems="center" mt={2}>
              <Avatar
                src={podcastImage}
                alt="Ana James"
                sx={{ width: "67px", height: "67px", marginRight: "1rem" }}
              />
              <Box>
                <Typography variant="body1" fontWeight="bold" sx={{ ...webStyles.descriptionTypo }}>
                  Ana James
                </Typography>
                <Typography variant="body2" sx={{ fontFamily: "Nexa-Regular", fontSize: "14px", fontWeight: 100, color: "#FFFFFF" }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer
                  vulputate.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        <Typography sx={{ fontFamily: "Nexa-Heavy", fontSize: "30px", fontWeight: 900, color: "#FFFFFF", marginTop: "95px", marginBottom: "20px" }}>Other shows from this Host</Typography>

        <Box
          sx={{
            backgroundColor: "#44403C",
            color: "#fff",
            padding: "2rem 2rem 2rem 2rem",
            borderRadius: "8px",
            margin: "0 10px 0 0",
            display: "flex",
            gap: "35px",
            justifyContent: "center",
            flexWrap: "wrap"
          }}
        >
          {hostChannelInfoData.map((card) => (
            <Card
              key={card.id}
              sx={{
                maxWidth: "250px",
                width: "100%",
                height: "307px",
                backgroundColor: "#000",
                borderRadius: "16px",
                position: "relative",
                margin: "10px",
              }}
            >
              <Box
                width={"fit-content"}
                position={"absolute"}
                top={6}
                zIndex={9}
                right={10}
              >
                <Typography sx={{ ...webStyles.followBtn }}>Follow</Typography>
              </Box>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="223"
                  image={card.image}
                  alt={card.title}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    component="div"
                    sx={webStyles.headerTypography}
                  >
                    {card.title}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      fontFamily: "Nexa-Heavy",
                      fontSize: "14px",
                      fontWeight: 900,
                      color: "#FFFFFF",
                      textTransform: "capitalize",
                    }}
                  >
                    {card.subtitle}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          ))}
        </Box>
      </>
    )
  }
  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <>
        <div style={webStyles.container1} className="container1" ref={this.scrollRef}>
          <StyledWrapperSetting>
            <Box className="header-section-host" id="header-section-host-1">
              <Box display="flex" flexDirection="row" gap="20px" justifyContent="space-between">
                <Box display="flex" gap="20px" flexDirection="row">
                  <img src={this.state.hostCardInfoData.cover_image?.image_link} alt="Podcast" className="podacst-image-host" />
                  <Box className="podcast-header-content-host">
                    <Typography variant="h5" component="h2" style={{ fontWeight: 900, fontSize: "30px", lineHeight: "25px", textTransform: "uppercase", fontFamily: "Nexa-Heavy" }}>{this.state.hostCardInfoData.name}</Typography>
                    <Typography className="podcast-name-host">{this.state.hostCardInfoData.subtitle}</Typography>
                    <Box alignItems="center" display="flex" id="stars">
                      {[...Array(this.state.fullStars)].map((_, index) => (
                        <IconButton style={{ padding: 0 }} key={`full-${index}`} >
                          <Star style={{ color: '#fff' }} />
                        </IconButton>
                      ))}
                      {this.state.hasHalfStar && (
                        <IconButton key="half-star" style={{ padding: 0 }}>
                          <StarHalf style={{ color: '#fff' }}  />
                        </IconButton>
                      )}
                      {[...Array(this.state.emptyStars)].map((_, index) => (
                        <IconButton  key={`empty-${index}`} style={{ padding: 0 }}>
                          <StarBorder style={{ color: '#fff' }} />
                        </IconButton>
                      ))}
                    </Box>
                    <Box display="flex" marginBottom="10px" gap="8px">
                      <Button className="left-section-buttons" variant="outlined">Rate or Review</Button>
                      <Button className="left-section-buttons" variant="outlined" >Share Channel</Button>
                    </Box>
                    <Typography className="total-reviews" >{this.state.totalReviews}K Reviews</Typography>
                    <Box display="flex" marginTop="10px" gap="10px">
                      <Button variant="contained" data-test-id="follow-button" className="left-section-bottom-buttons" style={{ backgroundColor: (this.state.followButtonText === "Follow" ? "#FF5722" : "black") }} onClick={() => this.handleFollowButton()}>{this.state.followButtonText}</Button>
                      <Button  className="left-section-bottom-buttons" variant="contained" ><AttachMoney style={{ width: "24px", height: "24px" }} />Subscribe</Button>
                      <IconButton className="podcast-header-info-icon" ><InfoOutlined /></IconButton>
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" justifyContent="flex-end" flexDirection="row" alignItems="flex-start" style={{ marginTop: '40px' }}>
                  <Box display="flex" gap="20px" flexDirection="column">
                    <Box flexDirection="row" display="flex"  gap="20px">
                      <Box display="flex" gap="10px" flexDirection="column">
                        <Button variant="outlined" style={{ backgroundColor: '#FF0807', color: '#fff', borderColor: '#FF0807', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><Laptop style={{ width: "24px", height: "20px" }} />Webinar</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#030303', borderColor: '#030303', color: '#fff', height: "44px", width: '164px', borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><img src={tshirtIcon} />Buy Swag</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#fff', borderColor: '#fff', color: '#030303', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontFamily: "Nexa-Heavy", fontWeight: "bold" }}><AttachMoney style={{ width: "24px", height: "24px" }} />Support Host</Button>
                      </Box>
                      <Box display="flex" gap="10px" flexDirection="column">
                        <Button variant="outlined" style={{ borderColor: '#FF0807', backgroundColor: '#FF0807', color: '#fff', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><ShoppingCartOutlined style={{ width: "24px", height: "20px" }} />Buy Now</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#030303', borderColor: '#030303', width: '164px', height: "44px", color: '#fff', borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><MenuBook style={{ width: "24px", height: "20px" }} />Learn More</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#fff', borderColor: '#fff', color: '#030303', width: '164px', height: "44px", textTransform: 'capitalize', borderRadius: "8px", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><LocalOfferOutlined style={{ width: "24px", height: "20px" }} />Get Offer</Button>
                      </Box>
                    </Box>
                    <Box display="flex" gap="10px" flexDirection="column">
                      <Button variant="outlined" style={{ color: '#fff', height: "44px", width: '345px', borderRadius: "8px", border: "1px solid white", fontWeight: "bold", textTransform: "capitalize", fontFamily: "Nexa-Heavy" }} startIcon={<Phone />}>
                        Contact Host
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="left-body-section-tabs" id="left-body-section-tabs-1">
              <Box sx={{ display: 'flex', gap: '22px' }}>
                <Button variant="outlined" className="section-tabs" data-test-id="tab-button" onClick={() => this.handleTabChanges('Episodes')} sx={{ borderRadius: (this.state.selectedTab === 'Episodes' ? '9px 9px 0px 0px' : '9px'), height: (this.state.selectedTab === 'Episodes' ? '53px' : '40px') }}>Episodes</Button>
                <Button variant="outlined" data-test-id="tab-button" className="section-tabs" onClick={() => this.handleTabChanges('Community')} sx={{ borderRadius: (this.state.selectedTab === 'Community' ? '9px 9px 0px 0px' : '9px'), height: (this.state.selectedTab === 'Community' ? '53px' : '40px') }}>Community</Button>
                <Button variant="outlined" className="section-tabs" data-test-id="tab-button" onClick={() => this.handleTabChanges('ChannelInfo')} sx={{ borderRadius: (this.state.selectedTab === 'ChannelInfo' ? '9px 9px 0px 0px' : '9px'), height: (this.state.selectedTab === 'ChannelInfo' ? '53px' : '40px') }}>Channel Info</Button>
              </Box>
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: '65% 35%', gap: '24px', marginBottom: "6%" }}>
              <div className="scrollableWrapper" id="scrollableWrapper">
                <Box className="left-body-section" sx={{ overflowY: 'scroll', maxHeight: '64rem' }} id="left-body-section-1"
                display={this.state.selectedTab === "Episodes" ? "block" : "none"}
                >
                  {this.state.hostEpisodesData?.data?.map((episode: any) => {
                    const attributes = episode?.attributes
                    return (<Card className="podcast-card" id="podcast-card-1">
                      <CardMedia
                        component="img"
                        sx={{ width: 151 }}
                        image={attributes.cover_image}
                        className="podcast-media"
                      />
                      <Box className="podcast-inner-box" id="podcast-inner-box-1">
                        <CardContent className="podcast-inner-box-content" id="podcast-inner-box-content-1">
                          <Typography component="div" variant="h6" color="#fff" className="podcast-inner" id="podcast-inner-1">
                            {attributes.name}
                          </Typography>
                          <Typography variant="subtitle1" color="#fff" component="div" id="ep-10" className="podcast-inner">
                            {attributes.description}
                          </Typography>
                          <Typography variant="body2" color="#fff" sx={{ marginTop: '10px' }} className="podcast-inner" id="podcast-inner-3">
                            Episode {attributes.episode_number}
                          </Typography>
                          <Typography variant="body2" color="#fff" sx={{ marginTop: '10px', fontFamily: 'Nexa-Regular' }}>
                            {attributes.episode_content}
                          </Typography>
                        </CardContent>
                        <Box className="podcast-action-buttons" id="podcast-action-buttons-1">
                          <IconButton aria-label="like" id="ep-10-like" sx={{ color: 'white' }}>
                            <ThumbUpOffAlt />
                          </IconButton>
                          <Typography sx={{ marginRight: '15px', color: 'white' }} className="podcast-inner">{attributes.liked_episode.count}</Typography>
                          <IconButton sx={{ color: 'white' }} aria-label="comment">
                            <ChatBubbleOutline />
                          </IconButton>
                          <Typography sx={{ color: 'white', marginRight: '15px', }} className="podcast-inner">{attributes.comments.data.length}</Typography>
                          <IconButton sx={{ color: 'white' }} aria-label="share">
                            <Share />
                          </IconButton>
                          <Typography className="podcast-inner" sx={{ color: 'white' }}>{attributes.share_episode_count}</Typography>
                        </Box>
                      </Box>
                      <Box className="podcast-saving-options" id="ep-10-buttons" data-test-id="podcast-saving-options-1">
                        <Button variant="outlined" startIcon={<FavoriteBorder style={{ marginLeft: "20%" }} />} className="podcast-save-button">
                          <Typography style={{ fontWeight: 900, lineHeight: "26px", fontSize: "10px", whiteSpace: "nowrap", fontFamily: "Nexa-Heavy" }}>Save For Later</Typography>
                        </Button>
                        <Button variant="outlined" className="podcast-save-button" startIcon={<PlaylistAdd style={{ marginLeft: "20%" }} />}>
                          <Typography style={{ fontSize: "10px", fontWeight: 900, whiteSpace: "nowrap", lineHeight: "26px", fontFamily: "Nexa-Heavy" }}>Add to Playlist</Typography>
                        </Button>
                      </Box>
                      <Box className="play-icon" id="ep-10-play-button-host">
                        <PlayCircleFilled style={{ color: 'red', fontSize: '40px', marginBottom: '-62%' }} />
                      </Box>
                    </Card>)
                  })}

                </Box>
                <Box className="left-body-section" sx={{ overflowY: 'scroll', maxHeight: '64rem' }} display={this.state.selectedTab === "Community" ? "block" : "none"}>
                {this.renderHostCommunityPost(this.state.hostCommunityPostData)}
              </Box>

              <Box className="left-body-section" sx={{ overflowY: 'scroll', maxHeight: '64rem' }} display={this.state.selectedTab === "ChannelInfo" ? "block" : "none"}>
                {this.renderHostChannelInfo(this.state.hostChannelInfoData)}
              </Box>
              </div>
              <Box sx={{ minHeight: '100vh'  }}>
                <Grid container  spacing={2} direction="column" className="right-body-section">
                  <Typography className="sponsored-title" id= "sponsored">Sponsored Recommendations</Typography>
                  <Grid item>
                    <Card className="recommended-cards" id="recommended-cards-1">
                      <Box sx={{ flexDirection: 'column', display: 'flex' }}>
                        <CardContent>
                          <Typography variant="h6" sx={{ fontFamily: "Nexa-Heavy", color: '#fff' }}>
                            The True
                          </Typography>
                          <Typography sx={{ color: '#fff', fontFamily: "Nexa-Regular" }} variant="body2">
                            Cost of Success Ep. 9
                          </Typography>
                        </CardContent>
                      </Box>
                      <CardMedia
                        component="img"
                        image={recommended_episode_1}
                        sx={{
                          width: "20%",
                          height: "100%",
                          objectFit: 'cover',
                          marginLeft: 'auto',
                          marginRight: "-11px"
                        }}
                        alt="The True Episode"
                      />
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card className="recommended-cards" id= "recommended-cards-2">
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent>
                          <Typography variant="h6" sx={{ color: '#fff', fontFamily: 'Nexa-Heavy' }}>
                            All Consuming
                          </Typography>
                          <Typography variant="body2" sx={{ color: '#fff', fontFamily: 'Nexa-Regular' }}>
                            Fantomy Ep. 12
                          </Typography>
                        </CardContent>
                      </Box>
                      <CardMedia
                        component="img"
                        image={recommended_episode_2}
                        alt="The True Episode"
                        sx={{
                          height: "100%",
                          width: "20%",
                          marginLeft: 'auto',
                          objectFit: 'cover',
                          marginRight: "-11px"
                        }}
                      />
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card className="recommended-cards" id= "recommended-cards-3">
                      <Box sx={{ flexDirection: 'column',display: 'flex' }}>
                        <CardContent>
                          <Typography sx={{ color: '#fff', fontFamily: 'Nexa-Heavy' }} variant="h6">
                            This American Life
                          </Typography>
                          <Typography variant="body2" sx={{color: '#fff', fontFamily: 'Nexa-Regular'}}>
                            Love your era Ep. 8
                          </Typography>
                        </CardContent>
                      </Box>
                      <CardMedia
                        component="img"
                        sx={{
                          width: "20%",
                          height: "100%",
                          objectFit: 'cover',
                          marginLeft: 'auto',
                          marginRight: "-11px",
                        }}
                        image={recommended_episode_3}
                        alt="The True Episode"
                      />
                    </Card>
                  </Grid>
                </Grid>
                <Box flexDirection="column" display="flex"  gap="25px">
                  <Typography variant="h6" sx={{ color: '#fff', marginTop: 4, fontFamily: 'Nexa-Heavy' }}>
                    Advertising
                  </Typography>
                  <Box
                    component="img"
                    src={ad_1}
                    sx={{ height: '20rem', borderRadius: "13px" }}
                    alt="Burger Ad"
                  />
                  <Box
                    component="img"
                    alt="Heinz Ad"
                    sx={{ height: '20rem', borderRadius: "13px" }}
                    src={ad_2}
                  />
                </Box>
              </Box>
            </Box>
          </StyledWrapperSetting>
          <div style={{
            backgroundColor: "#030303"
          }}>
            <Footer navigation={this.props.navigation} id={"1"} />
          </div>
        </div>
        <Dialog open={this.props.isShowEditHostLogoModal} onClose={this.handleCloseEditLogoDialog} PaperProps={{
          style: {
            width: '45rem',
            margin: '0 auto',
            borderRadius: "8px 8px 30px 8px",
            height: '52%',
          },
        }}
          componentsProps={{
            backdrop: {
              style: {
                backgroundColor: 'rgba(0, 0, 0, 0.3)',
                backdropFilter: 'blur(4px)',
              },
            },
          }}
          maxWidth={false}
        >
          <IconButton
            onClick={this.props.closeEditHostLogoModal} 
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: '#fff'
            }}
            data-test-id="second-dialog-close-icon"
          >
            <CloseOutlined />
          </IconButton>
          <DialogContent sx={{ textAlign: "center", backgroundColor: "#44403C", padding: "30px" }}>
            <Typography variant="h6" align="center" style={{ color: "#fff", marginBottom: "2%", marginLeft: "45%", marginTop: "4%", fontFamily: 'Nexa-Heavy' }}>
              New Logo Image:
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: "space-between"
              }}
            >
              <div style={{ width: '30%', marginLeft: "7%" }}>
                <img
                  src={
                    this.state.imagePreviewUrl
                      ? this.state.imagePreviewUrl
                      : (this.state.logo && typeof this.state.logo === "object" && "name" in this.state.logo
                        ? URL.createObjectURL(this.state.logo)
                        : this.state.logo || "")
                  }
                  alt="Logo"
                  style={{ width: '108%', height: '12.7rem', borderRadius: '10px', border: "1px solid white", padding: "0% 4% 0% 4%" }}
                />
              </div>
              <div
                style={{
                  padding: '20px',
                  border: '2px dashed #fff',
                  borderRadius: '10px',
                  width: '42%',
                  height: '10rem',
                  backgroundColor: '#3D3D3D',
                  marginRight: "5%"
                }}
              >
                {this.state.tempLogoUrl ? (
                  <Box sx={{ position: 'relative', width: '100%' }}>
                    <img src={this.state.tempLogoUrl} alt="Preview" style={{ width: '95%', height: '10rem', borderRadius: '8px' }} />
                    <IconButton
                      onClick={() => this.setState({ imagePreviewUrl: "", selectedFile: null, tempLogoUrl: "" })}
                      sx={{ position: 'absolute', top: 2, right: 10, color: '#000000' }}
                      data-test-id="logo-url"
                    >
                      <Close />
                    </IconButton>
                  </Box>
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <Box>
                      <FileUploadOutlined style={{ color: '#FF0807' }} />
                    </Box>
                    <Typography variant="body2" style={{ color: "#fff", fontFamily: 'Nexa-Heavy', width: "75%", marginLeft: "13%" }}>
                      Drag & Drop Your File Here OR
                    </Typography>
                    <Button
                      variant="contained"
                      component="label"
                      style={{ marginTop: '10px', backgroundColor: '#FF0807', fontFamily: 'Nexa-Heavy', width: "101px", fontSize: "10px" ,height: "26px", borderRadius: "8px", textTransform: "none" }}
                    >
                      Browse file
                      <input
                        hidden
                        accept="image/*"
                        data-test-id="upload_file"
                        type="file"
                        onChange={this.handleFileChange}
                      />
                    </Button>
                    <Typography variant="body2" style={{ color: "#fff", marginTop: '10px', fontFamily: 'Nexa-Heavy', width: "75%", marginLeft: "13%" }}>
                      Max File Size: 200mb Supports JPG and PNG files
                    </Typography>
                  </div>
                )}
              </div>
            </div>
          </DialogContent>
          <Divider />
          <DialogActions sx={{ justifyContent: 'center', backgroundColor: "#44403C", height: '18%', display: "flex", gap: "16px" }}>
            <Button onClick={this.props.closeEditHostLogoModal}  variant="contained" style={{ backgroundColor: "#000000", textTransform: "capitalize", fontFamily: 'Nexa-Heavy', width: "25%", height: "65%", borderRadius: "5px" }} data-test-id="cancel">
              Cancel
            </Button>
            <Button
              onClick={this.handleDoneClick}
              variant="contained"
              disabled={!this.state.selectedFile}
              style={{
                backgroundColor: ResolveCondition((this.state.selectedFile !== null), "#FF0807", "#A9A9A9"),
                textTransform: 'capitalize',
                fontFamily: 'Nexa-Heavy',
                width: "20%",
                height: "65%",
                borderRadius: "5px",
                color:"#FFFFFF"
              }}
              data-test-id="done"
            >
              Done
            </Button>
          </DialogActions>
        </Dialog>
      </>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const webStyles = {
  container1: {
    maxHeight: "100%",
    padding: "20px 50px",
    paddingTop: 0,
    overflowY: "auto",
  } as const,
  cardIcon: {
    fontSize: '14px', lineHeight: '26px', fontWeight: 900, fontFamily: 'Nexa-Heavy'
  },
  headerTypography: {
    fontFamily: "Nexa-Heavy", fontSize: "14px", fontWeight: 900, color: "#FFFFFF", textTransform :"uppercase"
  },
  followBtn: {
    background: "#FF0807", color: "#fff", borderRadius: "8px", fontFamily: "Nexa-Heavy", fontWeight: 900, padding: "6px"
  },
  mainCardHeader: {
    fontFamily: "Nexa-Heavy", fontSize: "26px", fontWeight: 900, color: "#FFFFFF"
  },
  descriptionTypo: {
    fontFamily: "Nexa-Heavy", fontSize: "18px", fontWeight: 900, color: "#F5F5F5"
  },
}
const StyledWrapperSetting = styled("div")({
  // margin: "0 auto",
  height: "auto",
  // width: "100%",
  paddingRight: "2%",
  paddingBottom: "0.05%",
  background: "#1C1917",
  "& *": {
    boxSizing: "border-box",
  },
  "& .header-section-host": {
    backgroundColor: "#44403C",
    width: "102%",
    minHeight: "20rem",
    borderRadius: "0.5rem",
    padding: "1rem",
    // marginLeft: "90px",
    marginTop: "18px"
  },
  "& .podacst-image-host": {
    width: "269px",
    height: "259px",
    borderRadius: "5px",
    marginTop: "13px",
    marginLeft: "8px"
  },
  "& .podcast-name-host": {
    fontWeight: "900",
    fontSize: "16px",
    lineHeight: "56px",
    fontFamily: "Nexa-Heavy"
  },
  "& .podcast-header-content-host": {
    color: "white",
    fontWeight: "bold",
    marginTop: "13px"
  },
  "& .podcast-header-info-icon": {
    width: "44px",
    height: '44px',
    borderRadius: "8px",
    color: "white",
    backgroundColor: "#FF0807",
    marginTop: "5px",
    "&:hover": {
      backgroundColor: "#FF0807",
    }
  },
  "& .left-section-buttons": {
    color: "white",
    border: "1px solid white",
    borderRadius: "8px",
    fontWeight: "bold",
    textTransform: 'capitalize',
    marginTop: "5px",
    fontFamily: "Nexa-Heavy",
    "&:hover": {
      color: "white",
      border: "1px solid white",
    }
  },
  "& .total-reviews": {
    fontSize: "18px",
    fontWeight: "900",
    fontFamily: "Nexa-Heavy",
    lineHeight: "22px",
    marginTop: "15px"
  },
  "& .left-section-bottom-buttons": {
    backgroundColor: "#FF5722",
    color: "white",
    borderRadius: "8px",
    height: '44px',
    width: "164px",
    fontWeight: "bold",
    textTransform: 'capitalize',
    borderColor: "#FF5722",
    marginTop: "5px",
    fontFamily: "Nexa-Heavy",
    "&:hover": {
      backgroundColor: "#FF5722",
      borderColor: "#FF5722",
    }
  },
  "& .left-body-section-tabs": {
    height: "52px",
    // width: "536px",
    // marginLeft: "90px",
    marginTop: "22px"
  },
  "& .scrollableWrapper": {
    height: "65rem",
    position: "relative",
    paddingRight: "20px",
    overflow: "hidden",
  },
  "& .section-tabs": {
    backgroundColor: '#44403C',
    color: 'white',
    borderColor: "#44403C",
    textTransform: "capitalize",
    fontFamily: "Nexa-Heavy",
    "&:hover": {
      backgroundColor: '#44403C',
      borderColor: "#44403C",
    },
    marginBottom: "0px"
  },
  "& .left-body-section": {
    width: "100%",
    height: "100%",
    overflowY: "scroll",
    paddingRight: "10px",
    scrollbarColor: "#FF0807 #4D4D4D",
    scrollbarWidth: "thin",

    "&::-webkit-scrollbar": {
      width: "15px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#4D4D4D",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#FF4500",
      borderRadius: "10px",
    },
  },
  "& .podcast-card": {
    display: 'flex',
    gap: "24px",
    backgroundColor: '#44403C',
    // width: "1005px",
    height: "242px",
    "&:first-of-type": {
      marginTop: "0px",
    },
    "&:not(:first-of-type)": {
      marginTop: "20px",
    }
  },
  "& .podcast-inner-box-content": {
    flex: '1 0 auto',
    paddingBottom: 0
  },
  "& .podcast-inner-box": {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  "& .podcast-saving-options": {
    display: 'flex',
    flexDirection: 'column',
    gap: "9px"
  },
  "& .podcast-action-buttons": {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 2,
    paddingBottom: 10
  },
  "& .podcast-save-button": {
    color: 'white',
    borderColor: '#FF0807',
    backgroundColor: "#FF0807",
    borderRadius: "8px",
    marginLeft: "46%",
    marginRight: "0px",
    height: "26px",
    width: "118px",
    textTransform: "capitalize",
    gap: "8px",
    "&:hover": {
      borderColor: '#FF0807',
      backgroundColor: "#FF0807",
    },
    "&:not(:first-of-type)": {
      marginTop: "0px",
    },
    "&:first-of-type": {
      marginTop: "25px",
    }
  },
  "& .play-icon": {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    height: '10%',
    marginRight: "2%",
    marginTop: "19%"
  },
  "& .podcast-media": {
    width: "123px",
    height: "118px",
    marginTop: "34px",
    marginLeft: "44px",
    borderRadius: "3px"
  },
  "& .right-body-section": {
    marginTop: "-35px",
    // width: "47.8%", 
    // height: "33%"
  },
  "& .sponsored-title": {
    marginLeft: "15px",
    fontFamily: 'Nexa-Heavy',
    color: "#fff"
  },
  "& .podcast-inner": {
    fontFamily: 'Nexa-Heavy'
  },
  "& .recommended-cards": {
    display: 'flex',
    backgroundColor: '#44403C',
    color: '#fff',
    // width: '478px', 
    height: '80px',
    borderRadius: '6px',
    borderLeft: '5px solid red'
  }
});

// Customizable Area End
