// Customizable Area Start
import React from "react";
import { Box, Typography, Button, TextField, RadioGroup, FormControlLabel, Radio, Grid, Slider, IconButton } from "@mui/material";
import HostSetupRssFeedController, {
    EpisodeDetails,
    Props,
} from "./HostSetupRssFeedController";
import { goBack, upload } from "./assets";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import { Form, Formik, FormikErrors } from "formik";
import * as Yup from "yup";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import Replay10Icon from "@mui/icons-material/Replay10";
import Forward10Icon from "@mui/icons-material/Forward10";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";

export default class HostSetupRssEpisodeInfo extends HostSetupRssFeedController {
    constructor(props: Props) {
        super(props);
    }

    validationSchema = Yup.object().shape({
        episodes: Yup.array()
            .of(Yup.object().shape({
                title: Yup.string().required("Title is required"),
                description: Yup.string().required("Description is required"),
                episode_type: Yup.number().required("Select episode type"),
                publication_date: Yup.date().required("Publish date is required"),
                audio_track: Yup.string().required("Audio file is required"),
                unique_id: Yup.string().required("Unique Id is required"),
                content_rating: Yup.string().required("Content Rating is required"),
            }))
            .required("Episodes are required"),
    });

    render() {
        return (
            <Box ref={this.scrollRef} sx={{ maxHeight: '100%', overflowY: "auto" }}>
                <Box style={styles.mainWrapper}>
                    <Box style={styles.title}>
                        <img onClick={this.handleGoBackToFeed} src={goBack} width={24} height={24} />
                        <span>Back</span>
                    </Box>
                    <Box>
                        <Box style={styles.container}>
                            <Box sx={styles.smContainer}>
                                <Typography variant="h3" sx={styles.heading}>Episode Info</Typography>
                                <Typography variant="body1" sx={styles.subHeading}>
                                    We are showing you your 4 most recent episodes and what required information is
                                    missing from them. Please go into Media Management once your channel is
                                    set up and provide the same information for the rest of your episodes to
                                    ensure that all of your episodes will be available on your channel.
                                </Typography>
                                <Formik
                                    initialValues={{ episodes: this.state.episodeDetails }}
                                    enableReinitialize={true}
                                    validateOnMount={true}
                                    validationSchema={this.validationSchema}
                                    onSubmit={(values) => {
                                        this.handleEpisodeSubmit(values);
                                    }}
                                >
                                    {({ handleChange, handleSubmit, values, errors, setFieldValue }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <Box sx={styles.episodeMainContainer}>
                                                {values.episodes.slice(0, 4).map((episode, index) => {
                                                    return (
                                                        <Box sx={styles.episodeContainer} key={episode.unique_id}>
                                                            <Box>
                                                                <Typography variant="h6" sx={styles.fieldsHeading}>Episode Title*</Typography>
                                                                <TextField
                                                                    id="outlined-basic"
                                                                    name={`episodes[${index}].title`}
                                                                    value={episode.title}
                                                                    onChange={handleChange}
                                                                    sx={styles.inputField}
                                                                    error={Boolean((errors?.episodes as FormikErrors<EpisodeDetails>[] | undefined)?.[index]?.title)}
                                                                />
                                                            </Box>
                                                            <Box>
                                                                <Typography variant="h6" sx={styles.fieldsHeading}>Audio File Info*</Typography>
                                                                <Box
                                                                    data-test-id="drop"
                                                                    onDrop={(e) => this.handleDropFile(e, setFieldValue, index)}
                                                                    onDragOver={(e: any) => { e.preventDefault() }}
                                                                    sx={{
                                                                        margin: "auto",
                                                                        marginTop: "2%",
                                                                        marginBottom: "3%",
                                                                        height: "180px",
                                                                        borderRadius: '10px',
                                                                        padding: '20px',
                                                                        textAlign: 'center',
                                                                        cursor: 'pointer',
                                                                        backgroundColor: 'transparent',
                                                                        backgroundSize: 'cover',
                                                                        backgroundPosition: 'center',
                                                                        border: Boolean((errors?.episodes as FormikErrors<EpisodeDetails>[] | undefined)?.[index]?.audio_track) ? '2px dashed #FF0807' : '2px dashed #ccc',
                                                                    }}
                                                                >
                                                                    <input
                                                                        type="file"
                                                                        accept="audio/*"
                                                                        style={{ display: 'none' }}
                                                                        id="file-input"
                                                                        data-test-id="selectFile"
                                                                        onChange={(e) => this.handleSelectFile(e, setFieldValue, index)}
                                                                    />
                                                                    <label htmlFor="file-input">
                                                                        <Box sx={{ marginTop: "1.5%", lineHeight: 2 }}>
                                                                            <img src={upload} alt="upload" />
                                                                            <Typography sx={styles.uploadImgText}>
                                                                                Drap & Drop your file here
                                                                                <br />
                                                                                OR
                                                                            </Typography>
                                                                            <Button
                                                                                sx={{
                                                                                    ...styles.button,
                                                                                    color: "white",
                                                                                    background: "#FF0807",
                                                                                    width: "auto",
                                                                                    height: "35px",
                                                                                    padding: "10px 16px",
                                                                                    margin: "10px 0",
                                                                                    "&: hover": {
                                                                                        background: "#FF0807",
                                                                                    }
                                                                                }}
                                                                            >
                                                                                Browse file
                                                                            </Button>
                                                                            <Typography sx={styles.imgValidateText}>
                                                                                Max. File Size: 200mb
                                                                            </Typography>
                                                                        </Box>
                                                                    </label>
                                                                </Box>
                                                                <Box
                                                                    sx={{
                                                                        marginBottom: "30px",
                                                                        padding: "16px 100px",
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        backgroundColor: "#000",
                                                                        color: "#fff",
                                                                        borderRadius: 2,
                                                                    }}
                                                                >
                                                                    <Typography variant="body2" sx={{ color: "#F8FAFC", fontFamily: "Inter", fontSize: "14px", fontWeight: 700 }}>
                                                                        {this.formatTime(this.state.currentTime[index])}
                                                                    </Typography>
                                                                    <audio
                                                                        ref={this.audioRefs[index]}
                                                                        src={values.episodes[index]?.audio_track}
                                                                        onTimeUpdate={() => this.handleTimeUpdate(index)}
                                                                        onLoadedMetadata={() => this.handleLoadedMetadata(index)}
                                                                    />

                                                                    <Box sx={{ flexGrow: 1, marginLeft: 2, marginRight: 2 }}>
                                                                        <Box
                                                                            sx={{
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                                alignItems: "center",
                                                                                gap: 4,
                                                                                marginBottom: 1,
                                                                            }}
                                                                        >
                                                                            <IconButton data-test-id="rewindBtn" onClick={() => this.handleRewind(index)} sx={{ color: "#fff" }}>
                                                                                <Replay10Icon />
                                                                            </IconButton>
                                                                            <IconButton
                                                                                data-test-id="playPauseBtn"
                                                                                onClick={() => this.handlePlayPause(index)}
                                                                                sx={{
                                                                                    color: "#fff",
                                                                                    backgroundColor: this.state.isPlaying[index] ? "red" : "#fff",
                                                                                    "&:hover": { backgroundColor: this.state.isPlaying[index] ? "darkred" : "#ddd" },
                                                                                }}
                                                                            >
                                                                                {this.state.isPlaying[index] ? (
                                                                                    <PauseIcon sx={{ color: "black" }} />
                                                                                ) : (
                                                                                    <PlayArrowIcon sx={{ color: "red" }} />
                                                                                )}
                                                                            </IconButton>
                                                                            <IconButton data-test-id="forwardBtn" onClick={() => this.handleForward(index)} sx={{ color: "#fff" }}>
                                                                                <Forward10Icon />
                                                                            </IconButton>
                                                                        </Box>
                                                                        <Slider
                                                                            data-test-id="episodeSlider"
                                                                            value={this.state.currentTime[index]}
                                                                            max={this.state.duration[index]}
                                                                            onChange={(e, newValue) => this.handleSliderChange(index, newValue)}
                                                                            sx={{
                                                                                color: "red",
                                                                                "& .MuiSlider-thumb": {
                                                                                    backgroundColor: "white",
                                                                                },
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                    <Typography variant="body2" sx={{ color: "#F8FAFC", fontFamily: "Inter", fontSize: "14px", fontWeight: 700 }}>
                                                                        {this.formatTime(this.state.duration[index])}
                                                                    </Typography>
                                                                    <Box sx={{ display: "flex", alignItems: "center", paddingLeft: "50px" }}>
                                                                        <VolumeUpIcon sx={{ color: "#fff", marginRight: 1 }} />
                                                                        <Slider
                                                                            data-test-id="volumeSlider"
                                                                            value={this.state.volume[index]}
                                                                            min={0}
                                                                            max={1}
                                                                            step={0.1}
                                                                            onChange={(e, newValue) => this.handleVolumeChange(index, newValue)}
                                                                            sx={{
                                                                                width: 100,
                                                                                color: "white",
                                                                                "& .MuiSlider-thumb": {
                                                                                    backgroundColor: "white",
                                                                                },
                                                                            }}
                                                                        />
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            <Grid container spacing={3}>
                                                                <Grid item md={5} sm={12}>
                                                                    <Box>
                                                                        <Typography variant="h6" sx={styles.fieldsHeading}>Episode Type*</Typography>
                                                                        <RadioGroup
                                                                            id="outlined-basic"
                                                                            row
                                                                            aria-labelledby="radio-buttons-group-label"
                                                                            name={`episodes[${index}].episode_type`}
                                                                            value={values.episodes[index]?.episode_type}
                                                                            onChange={handleChange}
                                                                            sx={{ marginTop: "2%", background: "black", gap: "40px", paddingLeft: '18px', borderRadius: "5px", alignItems: "center" }}
                                                                        >
                                                                            <FormControlLabel
                                                                                value={0}
                                                                                control={<Radio sx={{
                                                                                    margin: "0px",
                                                                                    "&:before": {
                                                                                        content: '""',
                                                                                        width: 12,
                                                                                        height: 12,
                                                                                        borderRadius: "50%",
                                                                                        border: "2px solid #CBD5E1",
                                                                                        background: "black",
                                                                                    },
                                                                                    "&.Mui-checked:before": {
                                                                                        content: '""',
                                                                                        width: 5,
                                                                                        height: 5,
                                                                                        borderRadius: "50%",
                                                                                        border: "6px solid red",
                                                                                        backgroundColor: "white",
                                                                                    },
                                                                                    "& .css-hyxlzm": { display: "none" }
                                                                                }} />}
                                                                                label="Normal"
                                                                                sx={{ color: "#CBD5E1", paddingLeft: '5px', alignItems: "center", fontFamily: "Nexa-Regular", marginRight: "0px", height: "55px" }}
                                                                                componentsProps={{
                                                                                    typography: {
                                                                                        sx: {
                                                                                            paddingTop: '3px',
                                                                                        },
                                                                                    },
                                                                                }}
                                                                            />
                                                                            <FormControlLabel
                                                                                value={1}
                                                                                control={<Radio sx={{
                                                                                    "&:before": {
                                                                                        content: '""',
                                                                                        width: 12,
                                                                                        height: 12,
                                                                                        borderRadius: "50%",
                                                                                        border: "2px solid #CBD5E1",
                                                                                        background: "black",
                                                                                    },
                                                                                    "&.Mui-checked:before": {
                                                                                        content: '""',
                                                                                        width: 5,
                                                                                        height: 5,
                                                                                        borderRadius: "50%",
                                                                                        border: "6px solid red",
                                                                                        backgroundColor: "white",
                                                                                    }, "& .css-hyxlzm": { display: "none" }
                                                                                }} />}
                                                                                label="Trailer"
                                                                                sx={{ color: "#CBD5E1", padding: '5px', alignItems: "center", fontFamily: "Nexa-Regular" }}
                                                                                componentsProps={{
                                                                                    typography: {
                                                                                        sx: {
                                                                                            paddingTop: '3px',
                                                                                        },
                                                                                    },
                                                                                }}
                                                                            />
                                                                            <FormControlLabel
                                                                                value={2}
                                                                                control={<Radio sx={{
                                                                                    "&:before": {
                                                                                        content: '""',
                                                                                        width: 12,
                                                                                        height: 12,
                                                                                        borderRadius: "50%",
                                                                                        border: "2px solid #CBD5E1",
                                                                                        background: "black",
                                                                                    },
                                                                                    "&.Mui-checked:before": {
                                                                                        content: '""',
                                                                                        width: 5,
                                                                                        height: 5,
                                                                                        borderRadius: "50%",
                                                                                        border: "6px solid red",
                                                                                        backgroundColor: "white",

                                                                                    }, "& .css-hyxlzm": { display: "none" }
                                                                                }} />}
                                                                                label="Bonus"
                                                                                sx={{ color: "#CBD5E1", paddingRight: '5px', alignItems: "center", fontFamily: "Nexa-Regular" }}
                                                                                componentsProps={{
                                                                                    typography: {
                                                                                        sx: {
                                                                                            paddingTop: '3px',
                                                                                        },
                                                                                    },
                                                                                }}
                                                                            />
                                                                        </RadioGroup>
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item md={3.5} sm={12}>
                                                                    <Box>
                                                                        <Typography variant="h6" sx={styles.fieldsHeading}>Season Number</Typography>
                                                                        <TextField
                                                                            id="outlined-basic"
                                                                            name={`episodes[${index}].season_number`}
                                                                            value={episode.season_number}
                                                                            onChange={handleChange}
                                                                            sx={styles.inputField}
                                                                            error={Boolean((errors?.episodes as FormikErrors<EpisodeDetails>[] | undefined)?.[index]?.season_number)}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item md={3.5} sm={12}>
                                                                    <Box>
                                                                        <Typography variant="h6" sx={styles.fieldsHeading}>Episode Number</Typography>
                                                                        <TextField
                                                                            id="outlined-basic"
                                                                            name={`episodes[${index}].episode_number`}
                                                                            value={episode.episode_number}
                                                                            onChange={handleChange}
                                                                            sx={styles.inputField}
                                                                            error={Boolean((errors?.episodes as FormikErrors<EpisodeDetails>[] | undefined)?.[index]?.episode_number)}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item md={6} sm={12}>
                                                                    <Box>
                                                                        <Typography variant="h6" sx={styles.fieldsHeading}>Publication Date*</Typography>
                                                                        <TextField
                                                                            id="outlined-basic"
                                                                            name={`episodes[${index}].publication_date`}
                                                                            value={episode.publication_date}
                                                                            onChange={handleChange}
                                                                            sx={styles.inputField}
                                                                            error={Boolean((errors?.episodes as FormikErrors<EpisodeDetails>[] | undefined)?.[index]?.publication_date)}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item md={6} sm={12}>
                                                                    <Box>
                                                                        <Typography variant="h6" sx={styles.fieldsHeading}>Unique Episode Identifier*</Typography>
                                                                        <TextField
                                                                            id="outlined-basic"
                                                                            name={`episodes[${index}].unique_id`}
                                                                            value={episode.unique_id}
                                                                            onChange={handleChange}
                                                                            sx={styles.inputField}
                                                                            error={Boolean((errors?.episodes as FormikErrors<EpisodeDetails>[] | undefined)?.[index]?.unique_id)}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item md={6} sm={12}>
                                                                    <Box>
                                                                        <Typography variant="h6" sx={styles.fieldsHeading}>Episode Length</Typography>
                                                                        <TextField
                                                                            id="outlined-basic"
                                                                            name={`episodes[${index}].episode_length`}
                                                                            value={this.convertMillisecondsToTime(episode.episode_length)}
                                                                            onChange={handleChange}
                                                                            sx={styles.inputField}
                                                                            error={Boolean((errors?.episodes as FormikErrors<EpisodeDetails>[] | undefined)?.[index]?.episode_length)}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                                <Grid item md={6} sm={12}>
                                                                    <Box>
                                                                        <Typography variant="h6" sx={styles.fieldsHeading}>Content Rating*</Typography>
                                                                        <TextField
                                                                            id="outlined-basic"
                                                                            name={`episodes[${index}].content_rating`}
                                                                            value={episode.content_rating}
                                                                            onChange={handleChange}
                                                                            sx={styles.inputField}
                                                                            error={Boolean((errors?.episodes as FormikErrors<EpisodeDetails>[] | undefined)?.[index]?.content_rating)}
                                                                        />
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                            <Box sx={{ marginTop: "20px" }}>
                                                                <Typography variant="h6" sx={styles.fieldsHeading}>Episode Summary*</Typography>
                                                                <TextField
                                                                    id="outlined-basic"
                                                                    name={`episodes[${index}].description`}
                                                                    value={this.stripHtml(episode.description)}
                                                                    onChange={handleChange}
                                                                    multiline
                                                                    rows={5}
                                                                    sx={{
                                                                        width: "100%",
                                                                        margin: "2% 0",
                                                                        "& .MuiOutlinedInput-root": {
                                                                            fontSize: "18px",
                                                                            padding: 0,
                                                                            "& fieldset": {
                                                                                borderColor: "#CBD5E1",
                                                                            },
                                                                            "&:hover fieldset": {
                                                                                borderColor: "#CBD5E1",
                                                                            },
                                                                            "&.Mui-focused fieldset": {
                                                                                borderColor: "#CBD5E1",
                                                                            },
                                                                        },
                                                                        "& .MuiInputLabel-root": {
                                                                            color: "#CBD5E1",
                                                                        },
                                                                        "& .MuiInputBase-input": {
                                                                            color: "#CBD5E1",
                                                                            padding: "16.5px 14px",
                                                                            backgroundColor: "black",
                                                                            "&:-webkit-autofill": {
                                                                                backgroundColor: "black !important",
                                                                                WebkitBoxShadow: "0 0 0 1000px black inset",
                                                                                WebkitTextFillColor: "#CBD5E1 !important",
                                                                            },
                                                                        },
                                                                    }}
                                                                    error={Boolean((errors?.episodes as FormikErrors<EpisodeDetails>[] | undefined)?.[index]?.description)}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    );
                                                })}
                                            </Box>
                                            <Box sx={styles.buttonWrapper}>
                                                <Button
                                                    type="submit"
                                                    sx={{ ...styles.button, color: "white !important", background: Object.keys(errors || {}).length > 0 ? "#B2B3B3" : "#FF0807" }}
                                                    data-test-id="continueBtn"
                                                    disabled={Object.keys(errors || {}).length > 0}
                                                >
                                                    Continue
                                                </Button>
                                            </Box>
                                        </Form>
                                    )}
                                </Formik>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Footer navigation={this.props.navigation} id={""} />
            </Box>
        );
    }
};

const styles = {
    mainWrapper: {
        padding: "20px 50px",
    },
    title: {
        display: "flex",
        alignItems: "center",
        gap: 10,
        color: 'white',
        paddingBottom: "1%",
        fontWeight: 900,
        fontSize: "30px",
        fontFamily: "Nexa-Heavy",
    },
    heading: {
        fontFamily: "Nexa-Heavy",
        fontSize: "40px",
        fontWeight: 900,
        lineHeight: "40px",
        color: "#FFFFFF",
        marginBottom: "15px",
    },
    subHeading: {
        fontFamily: "Nexa-Regular",
        fontSize: "24px",
        fontWeight: 400,
        lineHeight: "32px",
        color: "#FFFFFF",
        marginBottom: "15px",
    },
    episodeMainContainer: {
        marginTop: "80px",
        display: "flex",
        flexDirection: "column",
        gap: "50px",
    },
    episodeContainer: {
        background: "#44403C",
        padding: "30px 60px 30px 30px",
        borderRadius: "8px",
    },
    fieldsHeading: {
        fontFamily: "Nexa-Heavy",
        fontSize: "24px",
        fontWeight: 900,
        lineHeight: "32px",
        color: "#FFFFFF",
    },
    uploadImgText: {
        marginTop: "2px",
        fontFamily: "Nexa-Heavy",
        fontSize: "14px",
        fontWeight: 900,
        lineHeight: "20px",
        color: "#FFFFFF",
    },
    imgValidateText: {
        fontFamily: "Nexa-Heavy",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18px",
        color: "#FFFFFF",
    },
    inputField: {
        width: "100%",
        margin: "3% 0",
        "& .MuiOutlinedInput-root": {
            fontSize: "18px",
            "& fieldset": {
                borderColor: "#CBD5E1",
            },
            "&:hover fieldset": {
                borderColor: "#CBD5E1",
            },
            "&.Mui-focused fieldset": {
                borderColor: "#CBD5E1",
            },
        },
        "& .MuiInputLabel-root": {
            color: "#CBD5E1",
        },
        "& .MuiInputBase-input": {
            color: "#CBD5E1",
            backgroundColor: "black",
            "&:-webkit-autofill": {
                backgroundColor: "black !important",
                WebkitBoxShadow: "0 0 0 1000px black inset",
                WebkitTextFillColor: "#CBD5E1 !important",
            },
        },
    },
    buttonWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "30px",
    },
    button: {
        width: "380px",
        borderRadius: "8px",
        fontSize: "14px",
        fontWeight: 900,
        textTransform: "capitalize" as const,
        marginTop: "3%",
        border: "none",
        textAlign: "center" as const,
        padding: "16px",
        fontFamily: "Nexa-Heavy",
        backgroundColor: "#FF0807",
        "&:hover": {
            backgroundColor: "#FF0807",
        },
    },
    container: {
        color: "white",
        width: "95%",
        background: "#312F2D",
        paddingBottom: "20px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "16px 16px 8px 8px",
        borderTop: "114px solid #FF0807",
        height: "auto",
    },
    smContainer: {
        width: "100%",
        margin: "40px 150px",
    },
}
// Customizable Area End
