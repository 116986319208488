import React from 'react'
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    handleCommonPath: (path: string) => void;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    allCategories: []
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class AllCategoriesController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getAllCategoriesDataCallId: string = "";
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            allCategories: []
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.getAllCategoriesData()
    }

    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId === this.getAllCategoriesDataCallId) {
              if (responseJson && responseJson.data) {
                const apiData = responseJson.data.map((item:any) => item.attributes)
                
              this.setState({
                allCategories: apiData
          });
              } else {
                this.setState({ allCategories: [] });
              }
            }
            
      
          }
        // Customizable Area End
    }

    // Customizable Area Start
    getAllCategoriesData = () => {
        const header = {
          "Content-Type": configJSON.getAllCategoriesDataContentType,
        };
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.getAllCategoriesDataCallId = requestMessage.messageId;
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.getAllCategoriesDataEndPoint
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpGetType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
      }
    // Customizable Area End
}
