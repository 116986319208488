import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
import { ad_1 , Image_dots} from "./assets";

export interface Post {
  data: [];
  id: string;
  type: string;
  attributes: PostAttributes;
}

interface PostAttributes {
  name: string | null;
  body: string;
  pinned: boolean;
  podcast_id: number;
  account_id: number;
  created_at: string;
  updated_at: string;
  post_notification: boolean;
  share_post_count: number;
  post_url: string;
  photos: Photo[];
  account: Account;
  tags: string[];
  liked_post: LikedPost;
  save_post: SavePost;
  comments: Comments;
}

interface Photo {
  photot_link: string;
  photot_name: string;
  upload_date: string;
  photot_id: number;
}

interface Account {
  id: string;
  type: string;
  attributes: AccountAttributes;
}

interface AccountAttributes {
  activated: boolean;
  country_code: string;
  email: string;
  first_name: string;
  full_phone_number: string;
  last_name: string;
  phone_number: string;
  type: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  bio: string | null;
  profile_picture: string | null;
  user_location: string;
  roles: Role[];
  podcast_categories_ids: number[];
}

interface Role {
  id: number;
  name: string;
  created_at: string;
  updated_at: string;
}

interface LikedPost {
  liked: boolean;
  liked_id: string | null;
  count: number;
}

interface SavePost {
  saved: boolean;
  saved_id: string | null;
}

interface Comments {
  data: any[];
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  totalStars: number;
  reviewCount: number;
  fullStars: number;
  hasHalfStar: boolean;
  emptyStars: number;
  totalReviews: number;
  podcastName: string;
  followButtonText: string;
  selectedTab: string,
  selectedFile:any,
  textareaValue:string,
  communityData:any,
  isContactHost:boolean;
  name:string;
  title:string;
  description:string;
  review:string;
  phone_num:string;
  contactSuccess:boolean;
  errorMsg:string;
  open :boolean;
  openRateOrReview : boolean;
  showComments:boolean;
  comments: any;
  activeCommentId: any;
  openDropDown: boolean;
  openDropDownUser: boolean;
  openPopup:boolean;
  anchorEl:any;
  communityListData : Array<Post>
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
    // Customizable Area Start
    communityPostCallId:string="";
    contactHostCallId:string="";
    getCommunityDataApiCallId:string = "";
    // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
    // Customizable Area Start
      totalStars: 5,
      reviewCount: 3.5,
      fullStars: 0,
      hasHalfStar: false,
      emptyStars: 0,
      totalReviews: 1.5,
      podcastName: "Cost of Winning",
      followButtonText: "Follow",
      selectedTab: "Episodes",
      selectedFile:null,
      textareaValue:"",
      communityData:[]  ,
      isContactHost:false, 
      name:"",
      title:"",
      description:"",
      review : "",
      phone_num:"",
      contactSuccess:false,
      errorMsg:"",
      open : false,
      openRateOrReview: false,
      showComments:false,
      comments: [
        {
          id: 1,
          image: ad_1,
          title: "Jimmy Argylle",
          description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis.",
          date: "08/15/24",
          likes: "900",
          comments: "120",
          dotsImage: Image_dots,
          subComments: [
            {
              id: 2,
              image: ad_1,
              title: "Michael C. Jordan",
              description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis.",
              date: "08/15/24",
              likes: "900",
              comments: "120",
              dotsImage: Image_dots,
              subComments: [
                {
                  id: 3,
                  image: ad_1,
                  title: "Jack Mcguire",
                  description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis.",
                  date: "08/15/24",
                  likes: "900",
                  comments: "120",
                  dotsImage: Image_dots,
                }
              ]
            }
          ]
        },
        {
          id: 4,
          image: ad_1,
          title: "Naty Velasco",
          description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis.",
          date: "08/15/24",
          likes: "900",
          comments: "120",
          dotsImage: Image_dots,
          subComments: [
            {
              id: 5,
              image: ad_1,
              title: "Andrew Jackson",
              description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis.",
              date: "08/15/24",
              likes: "900",
              comments: "120",
              dotsImage: Image_dots,
              subComments: [
                {
                  id: 6,
                  image: ad_1,
                  title: "Jeanine Mitchells",
                  description: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis.",
                  date: "08/15/24",
                  likes: "900",
                  comments: "120",
                  dotsImage: Image_dots,
                }
              ]
            }
          ]
        }
      ],
      activeCommentId: null,
      openDropDown : false,
      openDropDownUser : false,
      openPopup:false,
      anchorEl:null,
      communityListData : []
    // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start   
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.calculateStars(this.state.reviewCount);
     // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      // let token = localStorage.getItem('authToken');
      this.setState({ token: token });
      this.getListRequest(token);
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const ApiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    
    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.communityPostCallId
    ) {
    
     this.setState({selectedFile:null,textareaValue:"",communityData:ApiResponse?.data})
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.contactHostCallId != null &&
      this.contactHostCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson.status === 500) {
        this.setState({errorMsg:"Internal Server Error"})
      }
      if (responseJson.errors) {
        const errorMsg = responseJson.errors[0].phone_number || responseJson.errors[0].name || responseJson.errors[0]  ;
        this.setState({ errorMsg });
      }    
      if (responseJson && !responseJson.errors) {
        this.setState({ isContactHost: false, contactSuccess:true , errorMsg:"", title:"", description:"", name:"", phone_num:""});
      } 
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId != null &&
      this.getProductApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ arrayHolder: responseJson.data });
        runEngine.debugLog("arrayHolder", this.state.arrayHolder);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCommunityDataApiCallId != null &&
      this.getCommunityDataApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
         this.communitySuccessResponse(responseJson)
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  onCancelHandler = () => {
    this.setState({
      isContactHost: false,
    });
  }

  onCloseHandler = () => {
    this.setState({
      contactSuccess: false,
    });
  }

  onSendHandler = () => {
    this.setState({
      isContactHost: true,
    });
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState(prevState => ({
      ...prevState,
      [name]: value,
    }));
  }

  saveContactHostData = async () => {
    let token = await getStorageData("token",true);
    let podcastId = await getStorageData("podcast_id")
    const header = {
      token: token,
      "Content-Type": configJSON.productApiContentType,
    };

    const data = {
      message: {
        podcast_id: podcastId,
        name: this.state.name,
        title: this.state.title,
        description: this.state.description,
        phone_number: this.state.phone_num,
      },
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.contactHostCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postContactHost
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
 
  calculateStars = (reviewCount: number) => {
    const fullStars = Math.floor(reviewCount);
    const hasHalfStar = reviewCount % 1 !==0 ? true : false;
    const emptyStars  = this.state.totalStars - fullStars - (hasHalfStar ? 1 : 0)
    this.setState({fullStars, hasHalfStar, emptyStars});
  }
  handleFollowButton = () => {
    if(this.state.followButtonText === "Follow") {
      this.setState({followButtonText: "Followed"});
    }
    else if(this.state.followButtonText === "Followed") {
      this.setState({followButtonText: "Follow"});
    }
  }
  handleTabChanges = (tab: string) => {
    const newTab = tab;
    this.setState({selectedTab: newTab, showComments:false}, () => {
      this.getCommunityPost();
    });
  }
   handleFileUpload = (event:any) => {
    const file = event.target.files[0];
    if (file) {
      this.setState({
        selectedFile:file
      })
    }
  };

  handleRemoveFile = () => {
  this.setState({
    selectedFile:null
  })  };

  handleTextareaChange = (event:any) => {
    this.setState({ textareaValue: event.target.value });
  };

  getCommunityPost = async () => {
    let token = await getStorageData("token",true);
    let prodcastId = await getStorageData("podcast_id");
   
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCommunityDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCommunityEndPoint + prodcastId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  communitySuccessResponse =(communityDataList: Post)=>{
    this.setState({communityListData : communityDataList.data })
  }

    
  handleSend = async () => {
    let token = await getStorageData("token",true);
    let prodcastId = await getStorageData("podcast_id")
    const header = {
      token: token,
    };

    let formdata = new FormData();
    formdata.append("data[podcast_id]",prodcastId)
    formdata.append("data[body]", this.state.textareaValue);
    formdata.append("data[photos][]", this.state.selectedFile);

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.communityPostCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.postCommunityEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
     formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleOpenShareChannel = () => {
   this.setState({open : true})
  }

  handleCloseShareChannel = () => {
    this.setState({open : false})
  }
  
  handleOpenRateOrReview = () => {
    this.setState({openRateOrReview : true})
   }
 
   handleCloseRateOrReview = () => {
     this.setState({openRateOrReview : false})
   }

   showComments = () => {
    this.setState({showComments : true})
   }

  handleReply = (parentId:any) => {
    const newComment = {
      id: Date.now(),
      content: this.state.textareaValue,
      subComments: [],
    };
  
    const addReply = (comments:any) => {
      return comments.map((comment:any) => {
        if (comment.id === parentId) {
          comment.subComments.push(newComment);
        } else {
          comment.subComments = addReply(comment.subComments);
        }
        return comment;
      });
    };
  
    this.setState((prevState) => ({
      comments: addReply(prevState.comments),
      textareaValue: "",
      activeCommentId: null,
    }));
  };
  
  showCommentBox = (event:React.MouseEvent<HTMLDivElement> | number) => {
    if(typeof event !== "number"){
      const commentId = Number(event.currentTarget?.getAttribute('data-id'));
      this.setState({ activeCommentId: commentId });
    }   
  };
  
  handleClick = (event:any, id:number) => {
    this.setState({ anchorEl: event.currentTarget, openPopup: !this.state.openPopup, activeCommentId: id });
  };
  
  handleClose = () => {
    this.setState({ anchorEl: null, openPopup: !this.state.openPopup });
  };

   handleOpenDropDown = () => {
    this.setState({openDropDown: !this.state.openDropDown});
   }

   handleOpenDropDownUser = () => {
    this.setState({openDropDownUser: !this.state.openDropDownUser});
   }
  // Customizable Area End
}
