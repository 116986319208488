import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React, { createRef } from 'react'
import { handleNavigation } from "../../utilities/src/CustomHelpers";
import { micImg, telephoneImg } from "./assets";
import { next, prev } from "../../dashboard/src/assets";
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    handleCommonPath:(path:string) => void;
}

interface SS {
    id: any;
}

interface IMainPlaylist {
  id: string;
  cover_image: string ; 
  name: string;
  description: string;
  episode_time: string | null; 
  songAdded: boolean;
}
  
interface S {
  playlistData: Array<{
    imageUrl: string;
    title: string;
    subtitle: string;
    duration: string;
}>;
selectedPlaylist: number | null;
openPlaylistModal: boolean;
selectedPlaylistData: {
  imageUrl: string;
  title: string;
  description: string;
  playListData: Array<{
    imageUrl: string;
    title: string;
    description: string;
    duration: string;
    added: boolean
  }>;
} | null
mainPlaylistInfo: IMainPlaylist[];
}

interface ArrowProps {
  onClick: () => void;
  
}

export default class AddPlayListController extends BlockComponent<Props, S, SS> {
  getMainPlaylistApiCallId: string = ""
  boxScrollRef: any = createRef();

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.state = {
        playlistData: [],
        selectedPlaylist: null,
        openPlaylistModal: false,
        selectedPlaylistData: null,
        mainPlaylistInfo: []
    };

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount(): Promise<void> {
    // Replace `dataList` with your actual API call or data source
    const dataList = [
      {
        imageUrl: micImg,
        title: "The Art of Drink",
        subtitle: "Sed Ut perspicats unde oais",
        duration: "1:50:00",
      },
      {
        imageUrl: micImg,
        title: "Coffee Tactics",
        subtitle: "Exploring the depths of caffeine",
        duration: "2:15:30",
      },
      {
        imageUrl: micImg,
        title: "The Art of Drink",
        subtitle: "Sed Ut perspicats unde oais",
        duration: "1:50:00",
      },
      {
        imageUrl: micImg,
        title: "Coffee Tactics",
        subtitle: "Exploring the depths of caffeine",
        duration: "2:15:30",
      },
      {
        imageUrl: micImg,
        title: "The Art of Drink",
        subtitle: "Sed Ut perspicats unde oais",
        duration: "1:50:00",
      },
      {
        imageUrl: micImg,
        title: "Coffee Tactics",
        subtitle: "Exploring the depths of caffeine",
        duration: "2:15:30",
      },
    ];

    this.getMainPlaylist()

    // Update the state with fetched data
    this.setState({ playlistData: dataList });

    // mockdata
    const selectedPlaylistMockData = {
      imageUrl: micImg,
      title: "The True Podcast",
      description: "Cost of success",
      playListData: [
        {
          imageUrl: micImg,
          title: "Calm Waters",
          description: "A soothing instrumental track.",
          duration: "3:45",
          added: true,
        },
        {
          imageUrl: micImg,
          title: "Evening Glow",
          description: "Perfect music for an evening stroll.",
          duration: "4:20",
          added: false,
        },
        {
          imageUrl: micImg,
          title: "Midnight Blues",
          description: "A jazzy track for late nights.",
          duration: "5:05",
          added: true,
        },
        {
          imageUrl: micImg,
          title: "Morning Breeze",
          description: "Start your day with this refreshing tune.",
          duration: "2:50",
          added: true,
        },
        {
          imageUrl: micImg,
          title: "Morning Breeze",
          description: "Start your day with this refreshing tune.",
          duration: "2:50",
          added: true,
        },
        {
          imageUrl: micImg,
          title: "Morning Breeze",
          description: "Start your day with this refreshing tune.",
          duration: "2:50",
          added: false,
        },
      ],
    };

    this.setState({selectedPlaylistData: selectedPlaylistMockData})
    setTimeout(() => {
      if (this.boxScrollRef.current) {
        this.boxScrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
  
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {

      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      if(this.getMainPlaylistApiCallId === apiRequestCallId) {
        this.handleMainPlaylistResponse(from, message);
      }
    }
  }


  getSearchSlickSettings(){
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;

    return(
       {
        dots: false,
        infinite: true,
        speed: 800,
        slidesToShow: 6,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: React.createElement(this.nextSearchArrow, { onClick: () => {}, innerWidth }),
        prevArrow: React.createElement(this.prevSearchArrow, { onClick: () => {}, innerWidth }),
        responsive: [
          {
            breakpoint: 1920,
            settings: {
              slidesToShow: 6,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 1440,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4
            }
          },
          {
            breakpoint: 1700, 
            settings: {
              slidesToShow: 5, 
              slidesToScroll: 1
            }
          },
        ]
       }
    )
  }

  prevSearchArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <img
        src={prev} 
        onClick={onClick} 
        style={{
          position: 'absolute',
          left: innerWidth > 1440 ? "-6.5%" : "-5.5%",
          transform: 'translateY(-50%)',
          top: innerWidth > 1440 ? "55%" : "40%",
          cursor: 'pointer',
          zIndex: 1,
        }} 
        alt="Previous Arrow"
      />
    )
  }

  nextSearchArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
      <img 
      onClick={onClick}
      style={{
        right: innerWidth > 1440 ? "-11.5%" : "-5.5%",
        position: 'absolute',
        top: innerWidth > 1440 ? "55%" : "40%",
        transform: 'translateY(-50%)',
        cursor: 'pointer',
        zIndex: 1
      }} 
      alt="Next Arrow"
      src={next} 
      />
    )
  }

  handlePlaylistRowClick = (index: number) => {
    this.setState({ selectedPlaylist: index });
    this.setState({openPlaylistModal: true})
  }

  handlePlaylistClose = () => {
    this.setState({openPlaylistModal: false})
  }

  handleNavigatePlaylist = () => {
    this.props.handleCommonPath("Playlist")
  }

  getMainPlaylist = async() => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const token = await getStorageData('token')

    let cleanedToken = token.slice(1, -1);

  
    this.getMainPlaylistApiCallId = requestMessage?.messageId

    const getPlaylistId  = await getStorageData('main_playlist_id')     

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
          "Content-Type": configJSON.validationApiContentType,
          token: cleanedToken
      })
      );
    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.songListApiEndPoint}/${getPlaylistId}`
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleMainPlaylistResponse = async(from: string, message: Message) => {
    let apiResponse = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if(apiResponse?.playlist?.data?.id) {
      const filteredPlaylist: IMainPlaylist[] = 
      apiResponse?.playlist?.data?.attributes?.songs?.data?.map((item: any) => ({
        cover_image: item.attributes.cover_image || null,
        id: item.id,
        description: item.attributes.description || '',
        name: item.attributes.name || '',
        songAdded: item.attributes.added.song_added,
        episode_time: item.attributes.episode_time || null,
      })) || [];

      this.setState({mainPlaylistInfo: filteredPlaylist})
    }
  }

}
