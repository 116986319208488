// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from '../../../framework/src/Message';
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { createRef } from "react";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath:(path:string) => void
}

export interface S {
  digitalProductsWebsite : string;
}

export interface SS {
  id: any;
}

export default class HostmonetizationSellingDigitalProductsController extends BlockComponent<
  Props,
  S,
  SS
> {

  getmemoizationSellingDataApiCallId = "";
  updateMonitizationCallId = "";
  deleteMonetizeSellingDigitalCallId = "";
  boxScrollRef1: any = createRef();

  async receive(from: string, message: Message) {   
    if (this.updateMonitizationCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForUpdateSponsoredApi(message);
    }
    if (this.getmemoizationSellingDataApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForMonetizationData(message);
    }
    if (this.deleteMonetizeSellingDigitalCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForDeleteMonetizationSellingDigitalData(message);
    }
  }

  handleResForDeleteMonetizationSellingDigitalData = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));  
    if (responseJson.message) {
      removeStorageData("monetization_id")
      removeStorageData("monetizationIds")
      removeStorageData("monetizationCategories")
     this.props.handleCommonPath("HostMonetizationSetup")          
    }
  };

  async componentDidMount() {
    this.getmemoizationSellingDigitalData()
    setTimeout(() => {
      if (this.boxScrollRef1.current) {
        this.boxScrollRef1.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
  }

  getmemoizationSellingDigitalData = async () => {
    let token = await getStorageData("token");
    let prodcastId = await getStorageData("podcast_id");
    const header = {
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getmemoizationSellingDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getmonetizationData}?podcast_id=${prodcastId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateMonitizationSellingDigital = async (values:any) => {
    
    let token = await getStorageData("token");
    let prodcastId = await getStorageData("podcast_id");
    let monetization_id = await getStorageData("monetization_id")
   
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: JSON.parse(token),
    };
    const body = {
      "monetization": {
        "id": monetization_id,
        "podcast_id": prodcastId,
        "digital_product_url": values.digitalProductsWebsite,
      }
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateMonitizationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateMonitization}/${monetization_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deleteMonetizationSellingDigital = async () => {
    let token = await getStorageData("token");
    let monetization_id = await getStorageData("monetization_id")
    const header = {
      token: JSON.parse(token),
    };

    const getMonetizationrequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteMonetizeSellingDigitalCallId = getMonetizationrequest.messageId;
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteMonetize}/${monetization_id}`
    );
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );
    runEngine.sendMessage(getMonetizationrequest.id, getMonetizationrequest);
  };
  handleResForMonetizationData = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));  
    if (responseJson.data) {
     this.setState({
      digitalProductsWebsite : responseJson.data.attributes.digital_product_url
     })
    }
  };  
  handleResForUpdateSponsoredApi = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson.data) {
      this.props.handleCommonPath("HostMonetizationOfferingConsultancy")
    }
  };
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      digitalProductsWebsite : ""
    };
  }

  handleDigitalProductsRoute = (route: any) => {
    if (route.includes("Membership/Subscription Model")) {
      this.props.handleCommonPath("ChooseFeatureSubscriptionTier")
    }
    else if (route.includes("Sponsored Content and Partnerships")) {
      this.props.handleCommonPath("SponsoredContentPartnership")
    }
    else {
      this.props.handleCommonPath("HostMonetizationSetup")
    }
  }


}
// Customizable Area End
