import React from "react";
// Customizable Area Start
import { styled, Box, Typography, Button, IconButton, Card, CardContent, CardMedia, Link, Grid,TextField , Avatar, Dialog, Modal } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Star, StarBorder, StarHalf, Phone, InfoOutlined, AttachMoney, Laptop, ShoppingCartOutlined, LocalOfferOutlined, MenuBook, ThumbUpOffAlt, ChatBubbleOutline, Share, FavoriteBorder, PlaylistAdd, PlayCircleFilled } from '@mui/icons-material';
import { podcastImage, tshirtIcon, recommended_episode_1, recommended_episode_2, recommended_episode_3, ad_1, ad_2 , Image_dots, messageLogo,imageEyeoff,imageBlock,imageEdit,imageDelete} from "./assets";
import {
  createTheme,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from "@mui/material/styles";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
const MIN_WINDOW_HEIGHT = 900
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { configJSON, Post } from "./CatalogueController";
import Divider from '@mui/material/Divider'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import BlockIcon from '@mui/icons-material/Block';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import moment from "moment";
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderPodcastCard = ({
    title="",
    subtitle="",
    episode="",
    description="",
    likes="",
    comments="",
    shares="",
  }) => (
    <Card className="podcast-card">
      <CardMedia
        component="img"
        sx={{ width: 151 }}
        image={podcastImage}
        alt="Podcast episode"
        className="podcast-media"
      />
      <Box className="podcast-inner-box">
        <CardContent className="podcast-inner-box-content" >
          <Typography component="div" variant="h6" color="#fff" className="podcast-inner">
            {title}
          </Typography>
          <Typography variant="subtitle1" color="#fff" component="div" className="podcast-inner">
            {subtitle}
          </Typography>
          <Typography variant="body2" color="#fff" sx={{ marginTop: '10px' }} className="podcast-inner">
            {episode}
          </Typography>
          <Typography variant="body2" color="#fff" sx={{ marginTop: '10px', fontFamily: 'Nexa-Regular' }}>
            {description}
            <Link style={{ color: '#fff' }}>See More</Link>
          </Typography>
        </CardContent>
        <Box className="podcast-action-buttons">
          <IconButton aria-label="like" sx={{ color: 'white' }}>
            <ThumbUpOffAlt />
          </IconButton>
          <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">
            {likes}
          </Typography>
          <IconButton aria-label="comment" sx={{ color: 'white' }}>
            <ChatBubbleOutline />
          </IconButton>
          <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">
            {comments}
          </Typography>
          <IconButton onClick={this.handleOpenShareChannel} aria-label="share" sx={{ color: 'white' }}>
            <Share />
          </IconButton>
          <Typography sx={{ color: 'white' }} className="podcast-inner">
            {shares}
          </Typography>
        </Box>
      </Box>
      <Box className="podcast-saving-options">
        <Button variant="outlined" className="podcast-save-button" startIcon={<FavoriteBorder />}>
          <Typography
            style={{
              fontWeight: 900,
              fontSize: '10px',
              lineHeight: '26px',
              whiteSpace: 'nowrap',
              fontFamily: 'Nexa-Heavy',
            }}
          >
            Save For Later
          </Typography>
        </Button>
        <Button variant="outlined" className="podcast-save-button" startIcon={<PlaylistAdd />}>
          <Typography
            style={{
              fontWeight: 900,
              fontSize: '10px',
              lineHeight: '26px',
              whiteSpace: 'nowrap',
              fontFamily: 'Nexa-Heavy',
            }}
          >
            Add to Playlist
          </Typography>
        </Button>
      </Box>
      <Box className="play-icon">
        <PlayCircleFilled style={{ color: 'red', marginBottom: '10px', fontSize: '40px' }} />
      </Box>
    </Card>
  );

  menuItemsData = [
    {
      activeState: 2,
      trueState: { img: imageEdit, text: "Edit", bgColor: "#FF0807" },
      falseState: { img: imageEyeoff, text: "Hide", bgColor: "#FF0807" },
    },
    {
      activeState: 2,
      trueState: { img: imageDelete, text: "Delete", bgColor: "#44403C" },
      falseState: { img: imageBlock, text: "Block User", bgColor: "#44403C" },
    },
  ];
  
  
  renderComments = (comments: any, paddingLeft: number,openPopup:boolean) => {
    return comments.map((comment: any, index: number) => (
      <React.Fragment key={comment.id}>
        <Typography variant="body2" color="white">
          {comment.content}
        </Typography>
        <Card
          className="podcast-card-comment"
          style={{
            borderRadius: "0px",
            backgroundColor: index % 2 === 0 ? "#312F2D" : "#44403C",
          }}
          onClick={this.showComments}
        >
          <Box className="podcast-inner-box">
            <CardContent
              className="podcast-inner-box-content"
              style={{
                display: "flex",
                borderBottom: "1px solid #E0E0E0",
                padding: `22px 33px 22px ${paddingLeft}px`,
              }}
              >
                <Box sx={{display:"flex"}} data-test-id="comment-box" data-id={comment.id} onClick={this.showCommentBox}           
                >
              <Box>
                <img
                  src={comment.image}
                  alt="Avatar"
                  style={{
                    width: "56px",
                    height: "56px",
                    borderRadius: "50%",
                    marginRight: "10px",
                  }}
                />
              </Box>
              <Box>
                <Box className="community-list">
                  <Typography
                    component="div"
                    variant="h6"
                    style={{ color: "#FFFFFF", fontSize: "14px", fontWeight: 900 }}
                  >
                    {comment.title}
                  </Typography>
                 
                </Box>
                
                <Typography
                  variant="body2"
                  style={{ fontWeight: 100, fontSize: "10px", color: "#FFFFFF" }}
                >
                  {comment.date}
                </Typography>
                <Typography
                  variant="body2"
                  style={{ fontWeight: 100, fontSize: "14px", color: "#FFFFFF" }}
                  sx={{
                    marginTop: "10px",
                  }}
                >
                  {comment.description}
                </Typography>
                <Box className="community-list-buttons">
                  <IconButton sx={{ color: "#FFFFFF" }}>
                    <ThumbUpOffAlt />
                  </IconButton>
                  <Typography
                    sx={{ color: "#FFFFFF", marginRight: "15px", fontSize: "14px" }}
                  >
                    {comment.likes}
                  </Typography>
                  <IconButton sx={{ color: "#FFFFFF" }}>
                    <ChatBubbleOutline />
                  </IconButton>
                  <Typography
                    sx={{ color: "#FFFFFF", marginRight: "15px", fontSize: "14px" }}
                  >
                    {comment.comments}
                  </Typography>
                </Box>
              </Box>
              </Box>
              <Button
                id="basic-button"
                aria-controls={openPopup ? 'basic-menu' : undefined}
                aria-haspopup="true"
                data-test-id="comment-box"
                style={{display:"flex", alignItems:"flex-start", height:"fit-content"}}
                aria-expanded={openPopup ? 'true' : undefined}
                onClick={(e)=>this.handleClick(e, comment.id)}
              >
                <img
                  src={comment.dotsImage}
                  style={{
                    width: "25px",
                    height: "4px",
                  }}
                />
                <Menu
                  id="basic-menu"
                  open={openPopup}
                  anchorEl={this.state.anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  sx={{
                    ".MuiList-root": {
                      padding: 0,
                    },
                    ".MuiPaper-root": {
                      borderRadius: 0,
                    },
                  }}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {this.menuItemsData.map((item, index) => {
                    const state = this.state.activeCommentId === item.activeState ? item.trueState : item.falseState;
                    return (
                      <MenuItem
                        key={index}
                        style={{
                          backgroundColor: state.bgColor,
                          color: "#FFFFFF",
                          border: "1px solid #FFFFFF",
                          fontSize: "14px",
                          width: "233px",
                        }}
                      >
                        <img src={state.img} alt={state.text} />
                        <div style={{ marginLeft: "8px" }}>{state.text}</div>
                      </MenuItem>
                    );
                  })}
                </Menu>
              </Button>
            </CardContent>
            {this.state.activeCommentId === comment.id && !this.state.openPopup && (
              <CardContent
                className="podcast-inner-box-content"
                style={{ padding: `22px 33px 22px ${paddingLeft}px` }}
              >
                <Box sx={{ display: "flex" }}>
                  <img
                    src={podcastImage}
                    alt="Avatar"
                    style={{
                      width: "56px",
                      height: "56px",
                      borderRadius: "50%",
                      marginRight: "10px",
                    }}
                  />
                  <Box sx={{ flex: 1, position: "relative" }}>
                    <Box
                      sx={{
                        backgroundColor: "black",
                        borderRadius: "0px",
                        padding: "10px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <TextField
                        value={this.state.textareaValue}
                        onChange={this.handleTextareaChange}
                        fullWidth
                        multiline
                        placeholder="Leave a comment"
                        rows={4}
                        variant="outlined"
                        sx={{
                          backgroundColor: "black",
                          color: "white",
                          borderRadius: "8px",
                          "& .MuiOutlinedInput-root": {
                            backgroundColor: "black",
                            borderRadius: "8px",
                            padding: "10px",
                          },
                          "& .MuiInputBase-input": {
                            color: "white",
                          },
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                        display: "flex",
                        gap: "10px",
                      }}
                    >
                      <IconButton
                        sx={{
                          color: "white",
                          backgroundColor: "#DADADA",
                          padding: "10px",
                        }}
                        onClick={() => this.handleReply(comment.id)}
                      >
                        <SendRoundedIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            )}
          </Box>
        </Card>
        {comment.subComments && comment.subComments.length > 0 && (
          <Box>{this.renderComments(comment.subComments, paddingLeft + 62, this.state.openPopup)}</Box>
        )}
      </React.Fragment>
    ));
  };

  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <>
        <div style={webStyles.container}>
          <StyledWrapperSetting>
            <Box className="header-section">
              <Box display="flex" flexDirection="row" justifyContent="space-between" gap="20px">
                <Box display="flex" flexDirection="row" gap="20px">
                  <img src={podcastImage} alt="Podcast" className="podacst-image" />
                  <Box className="podcast-header-content">
                    <Typography variant="h5" component="h2" style={{ fontWeight: 900, lineHeight: "40px", fontSize: "30px", fontFamily: "Nexa-Heavy" }}>The True Podcast</Typography>
                    <Typography className="podcast-name">{this.state.podcastName}</Typography>
                    <Box display="flex" alignItems="center">
                      {[...Array(this.state.fullStars)].map((_, index) => (
                        <IconButton key={`full-${index}`} style={{ padding: 0 }}>
                          <Star style={{ color: '#fff' }} />
                        </IconButton>
                      ))}
                      {this.state.hasHalfStar && (
                        <IconButton key="half-star" style={{ padding: 0 }}>
                          <StarHalf style={{ color: '#fff' }} />
                        </IconButton>
                      )}
                      {[...Array(this.state.emptyStars)].map((_, index) => (
                        <IconButton key={`empty-${index}`} style={{ padding: 0 }}>
                          <StarBorder style={{ color: '#fff' }} />
                        </IconButton>
                      ))}
                    </Box>
                    
                    <Box display="flex" gap="8px" marginBottom="10px">
                      <Button variant="outlined" data-test-id="rate-testID" onClick={this.handleOpenRateOrReview} className="left-section-buttons">{configJSON.rateOrReviewText}</Button>
                      <Dialog
                      slotProps={{
                        backdrop: {
                          sx: {
                            backdropFilter: 'blur(5px)',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          },
                        },
                      }}
                      sx={{
                        "& .MuiDialog-paper": {
                          maxWidth: "600px",
                          width: "600px",
                          background: "#44403C",
                          borderRadius: '8px',
                        },
                      }}
                      open={this.state.openRateOrReview}
                      onClose={this.handleCloseRateOrReview}
                      >
                      <Box data-test-id="rate-review-testID" onClick={this.handleCloseRateOrReview} display={"flex"} justifyContent={"flex-end"} paddingTop="20px" paddingRight="30px">
                        <CloseIcon sx={{ color: "white" , cursor:"pointer"}} />
                      </Box>
                      <Box sx={{padding:"0px 40px"}}>
                        <Typography sx={{...webStyles.title, fontSize:"24px", marginBottom:"20px"}}>
                          {configJSON.rateThisPodcast}
                        </Typography>
                        <Box display="flex" alignItems="center">
                          {[...Array(this.state.totalStars)].map((_, index) => (
                            <IconButton key={`empty-${index}`} style={{ padding: 0 }}>
                              <StarBorder style={{ color: '#fff' }} />
                            </IconButton>
                          ))}
                      </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                          <Typography sx={{ ...webStyles.title, fontSize: "14px" , marginTop:"16px"}} component="label">
                          {configJSON.leaveAReview}
                          </Typography>
                          <TextField
                            data-test-id="review"
                            name="review"
                            value={this.state.review}
                            onChange={this.handleInputChange}
                            variant="outlined"
                            multiline
                            rows={4} 
                            sx={textFieldStyles}
                          />
                        </Box>
                      </Box>
                      <Divider sx={{ marginTop:"74px", borderColor: 'white' }} />
                      <Box display={"flex"} margin="30px 24px 30px 24px" gap={2} justifyContent={"flex-end"}>
                      <Button  onClick={this.handleCloseRateOrReview} sx={{
                        ...buttonStyles,
                        border: "none",
                        color: "#fff",
                        background: "black",
                        height:"56px",
                        width:'100px',
                        "&:hover": {
                            background: "#000", 
                        }
                        }}
                        variant="contained"
                        >
                          {configJSON.cancelText}
                        </Button>
                        <Button
                          disabled = {!this.state.review}
                          sx={{
                            ...buttonStyles,
                            border: "none",
                            color: "#FFFFFF",
                            background: "#FF0807" ,
                            height:"56px",
                            width:'100px',
                            "&:hover": {
                                background:  "#FF0807" ,
                            },
                            "&.Mui-disabled": {
                                backgroundColor: "#B2B3B3",
                                color: "#fff",
                            },
                            }}
                          variant="contained"
                        >
                          {configJSON.publishText}
                        </Button>
                      </Box>
                    </Dialog>
                      <Button variant="outlined" data-test-id="share-testID" onClick={this.handleOpenShareChannel} className="left-section-buttons">{configJSON.shareChannelModal}</Button>
                      <Modal
                        open={this.state.open}
                        onClose={this.handleCloseShareChannel}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        style={{background:"rgba(0,0,0,0.5)" ,  backdropFilter: 'blur(5px)',}}
                      >
                        <Box sx={ShareChannelModalstyle}>
                          <Box className="modalBox" >
                            <IconButton  data-test-id="share-close-testID" onClick={this.handleCloseShareChannel}>
                              <CloseIconModal />
                            </IconButton>
                            <ShareChannelModal>
                              <ShareIcon aria-label="share">
                                <Share />
                              </ShareIcon>
                              <ShareChannelText variant="h6" mb={6} mt={4}>{configJSON.shareChannelModal}</ShareChannelText>
                            </ShareChannelModal>
                            <ShareChannelText variant="subtitle1" gutterBottom>{configJSON.channelUrlText}</ShareChannelText>
                            <UrlBox>
                              <ChannelTextFeild>{configJSON.podcastLink}</ChannelTextFeild>
                              <CopyButton variant="contained" color="error" ><ContentCopy />{configJSON.copyText}</CopyButton>
                              <ModalBoxInput>
                                <CheckBoxModal />
                                <ShareChannelText variant="subtitle1" gutterBottom>{configJSON.startAt}</ShareChannelText>
                                <ZeroInput>0</ZeroInput>
                              </ModalBoxInput>
                            </UrlBox>
                            <ShareChannelText variant="subtitle1" mt={4} gutterBottom>{configJSON.embedText}</ShareChannelText>
                            <LinkContainer >
                              <EmbededTextFeild>{configJSON.podcastLink}</EmbededTextFeild>
                              <CopyButton2 variant="contained" color="error" ><ContentCopy />{configJSON.copyText}</CopyButton2>
                            </LinkContainer >
                          </Box>
                        </Box>
                      </Modal>
                    </Box>
                    <Typography className="total-reviews">{this.state.totalReviews}K Reviews</Typography>
                    <Box display="flex" gap="10px" marginTop="10px">
                      <Button variant="contained" className="left-section-bottom-buttons" data-test-id="followButton" style={{ backgroundColor: (this.state.followButtonText === "Follow" ? "#FF5722" : "black") }} onClick={() => this.handleFollowButton()}>{this.state.followButtonText}</Button>
                      <Button variant="contained" className="left-section-bottom-buttons"><AttachMoney style={{ width: "24px", height: "24px" }} />Subscribe</Button>
                      <IconButton className="podcast-header-info-icon"><InfoOutlined /></IconButton>
                    </Box>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="flex-start" style={{ marginTop: '40px' }}>
                  <Box display="flex" flexDirection="column" gap="20px">
                    <Box display="flex" flexDirection="row" gap="20px">
                      <Box display="flex" flexDirection="column" gap="10px">
                        <Button variant="outlined" style={{ backgroundColor: '#FF0807', borderColor: '#FF0807', color: '#fff', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><Laptop style={{ width: "24px", height: "20px" }} />Webinar</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#030303', borderColor: '#030303', color: '#fff', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><img src={tshirtIcon} />Buy Swag</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#fff', borderColor: '#fff', color: '#030303', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><AttachMoney style={{ width: "24px", height: "24px" }} />Support Host</Button>
                      </Box>
                      <Box display="flex" flexDirection="column" gap="10px">
                        <Button variant="outlined" style={{ backgroundColor: '#FF0807', borderColor: '#FF0807', color: '#fff', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><ShoppingCartOutlined style={{ width: "24px", height: "20px" }} />Buy Now</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#030303', borderColor: '#030303', color: '#fff', width: '164px', height: "44px", borderRadius: "8px", textTransform: "capitalize", fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><MenuBook style={{ width: "24px", height: "20px" }} />Learn More</Button>
                        <Button variant="outlined" style={{ backgroundColor: '#fff', borderColor: '#fff', color: '#030303', width: '164px', height: "44px", borderRadius: "8px", textTransform: 'capitalize', fontWeight: "bold", fontFamily: "Nexa-Heavy" }}><LocalOfferOutlined style={{ width: "24px", height: "20px" }} />Get Offer</Button>
                      </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" gap="10px">
                      <Button 
                        onClick={this.onSendHandler}
                        variant="outlined" style={{ color: '#fff', width: '345px', height: "44px", borderRadius: "8px", border: "1px solid white", fontWeight: "bold", textTransform: "capitalize", fontFamily: "Nexa-Heavy" }} startIcon={<Phone />}>
                        Contact Host
                      </Button>
                    </Box>
                    <Dialog
                      slotProps={{
                        backdrop: {
                          sx: {
                            backdropFilter: 'blur(5px)',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          },
                        },
                      }}
                      sx={{
                        "& .MuiDialog-paper": {
                          maxWidth: "600px",
                          width: "600px",
                          background: "#44403C",
                          borderRadius: '8px',
                        },
                      }}
                      open={this.state.isContactHost}
                      onClose={this.onCancelHandler}
                      >
                      <Box onClick={this.onCancelHandler} display={"flex"} justifyContent={"flex-end"} paddingTop="20px" paddingRight="30px">
                        <CloseIcon sx={{ color: "white" , cursor:"pointer"}} />
                      </Box>
                      <Box sx={{padding:"0px 40px"}}>
                        <Typography sx={{...webStyles.title, fontSize:"24px", marginBottom:"20px"}}>
                          Contact Host
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                          <Typography sx={{ ...webStyles.title, fontSize: "14px" }} component="label">
                            Name *
                          </Typography>
                          <TextField
                            data-test-id="name"
                            value={this.state.name}
                            name="name"
                            onChange={this.handleInputChange}
                            variant="outlined"
                            sx={textFieldStyles}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                          <Typography sx={{ ...webStyles.title, fontSize: "14px" , marginTop:"16px"}} component="label">
                            Title *
                          </Typography>
                          <TextField
                            data-test-id="playlistName"
                            name="title"
                            value={this.state.title}
                            onChange={this.handleInputChange}
                            variant="outlined"
                            sx={textFieldStyles}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                          <Typography sx={{ ...webStyles.title, fontSize: "14px" , marginTop:"16px"}} component="label">
                            Description *
                          </Typography>
                          <TextField
                            data-test-id="description"
                            name="description"
                            value={this.state.description}
                            onChange={this.handleInputChange}
                            variant="outlined"
                            multiline
                            rows={4} 
                            sx={textFieldStyles}
                          />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                          <Typography sx={{ ...webStyles.title, fontSize: "14px" , marginTop:"16px"}} component="label">
                            Phone Number (optional)
                          </Typography>
                          <TextField
                            name="phone_num"
                            data-test-id="phoneNum"
                            value={this.state.phone_num}
                            onChange={this.handleInputChange}
                            variant="outlined"
                            inputProps={{ maxLength: 10 }}
                            sx={textFieldStyles}
                          />
                        </Box>
                        <div style={{color : "red", fontFamily : "Nexa-Heavy", marginTop:'8px'}}>{this.state.errorMsg}</div>
                      </Box>
                      <Divider sx={{ marginTop:"74px", borderColor: 'white' }} />
                      <Box display={"flex"} margin="30px 24px 30px 24px" gap={2} justifyContent={"space-between"}>
                      <Button onClick={this.onCancelHandler} sx={{
                        ...buttonStyles,
                        border: "none",
                        color: "white",
                        background: "black",
                        height:"56px",
                        width:'268px',
                        "&:hover": {
                            background: "black", 
                        }
                        }}
                        variant="contained"
                        >
                          {configJSON.cancelText}
                        </Button>
                        <Button
                          onClick={this.saveContactHostData}
                          disabled={!this.state.title || !this.state.description}
                          sx={{
                            ...buttonStyles,
                            border: "none",
                            color: "white",
                            background: "#FF0807" ,
                            height:"56px",
                            width:'268px',
                            "&:hover": {
                                background:  "#FF0807" ,
                            },
                            "&.Mui-disabled": {
                                backgroundColor: "#B2B3B3",
                                color: "white",
                            },
                            }}
                          variant="contained"
                        >
                          {configJSON.saveText}
                        </Button>
                      </Box>
                    </Dialog>
                    <Dialog
                      slotProps={{
                        backdrop: {
                          sx: {
                            backdropFilter: 'blur(5px)',
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                          },
                        },
                      }}
                      sx={{
                        "& .MuiDialog-paper": {
                          maxWidth: "600px",
                          width: "600px",
                          background: "#44403C",
                          borderRadius: '8px 8px 32px 8px',
                        },
                      }}
                      open={this.state.contactSuccess}
                      onClose={this.onCloseHandler}
                      >
                      <Box onClick={this.onCloseHandler} display={"flex"} justifyContent={"flex-end"} paddingTop="20px" paddingRight="30px">
                        <CloseIcon sx={{ color: "white" , cursor:"pointer"}} />
                      </Box>
                      <Box sx={{padding:"0px 113px", textAlign:"center"}}>
                        <img src = {messageLogo} style={{ marginBottom:"10px"}}/>
                        <Typography sx={{...webStyles.title, fontSize:"24px", marginBottom:"10px"}}>
                          Message sent!
                        </Typography>
                        <Typography sx={{...webStyles.titledesc, fontSize:"20px"}}>
                          Your message has been sent to the host. They will get back to you soon!                       
                        </Typography>
                      </Box>
                      <Divider sx={{ marginTop:"24px", borderColor: 'white' }} />
                      <Box display={"flex"} margin="24px 0px 52px 0px" justifyContent={"center"}>
                      <Button onClick={this.onCloseHandler} sx={{
                        ...buttonStyles,
                        border: "none",
                        color: "white",
                        background: "black",
                        height:"56px",
                        width:'187px',
                        "&:hover": {
                            background: "black", 
                        }
                        }}
                        variant="contained"
                        >
                          {configJSON.closeText}
                        </Button>
                      </Box>
                    </Dialog>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="left-body-section-tabs">
              <Box sx={{ display: 'flex', gap: '22px' }}>
                <Button variant="outlined" className="section-tabs" data-test-id="tab-button" onClick={() => this.handleTabChanges('Episodes')} sx={{ borderRadius: (this.state.selectedTab === 'Episodes' ? '9px 9px 0px 0px' : '9px'), height: (this.state.selectedTab === 'Episodes' ? '53px' : '40px') }}>Episodes</Button>
                <Button variant="outlined" className="section-tabs" data-test-id="tab-button" onClick={() => this.handleTabChanges('Community')} sx={{ borderRadius: (this.state.selectedTab === 'Community' ? '9px 9px 0px 0px' : '9px'), height: (this.state.selectedTab === 'Community' ? '53px' : '40px') }}>Community</Button>
                <Button variant="outlined" className="section-tabs" data-test-id="tab-button" onClick={() => this.handleTabChanges('ChannelInfo')} sx={{ borderRadius: (this.state.selectedTab === 'ChannelInfo' ? '9px 9px 0px 0px' : '9px'), height: (this.state.selectedTab === 'ChannelInfo' ? '53px' : '40px') }}>Channel Info</Button>
              </Box>
            </Box>
            <Box sx={{ display: 'grid', gridTemplateColumns: '65% 35%', gap: '24px' }}>
              <div className="scrollableWrapper">
                <Box className="left-body-section" sx={{ overflowY: 'scroll', maxHeight: '64rem' }}>
                  {this.state.selectedTab == "Episodes" && this.renderPodcastCard({
                    title: 'The Cost Of Success',
                    subtitle: 'The True',
                    episode: 'Episode 10',
                    description: 'Sed ut perspiciatis unde omnis iste natus error sit ...',
                    likes: '1.4K',
                    comments: '985',
                    shares: '1.2K',
                  })}
                  {this.state.selectedTab == "Community" &&
                 <>
                    <Card  className="podcast-card" >
                      <Box className="podcast-inner-box">
                      {!this.state.showComments  &&
                        <CardContent className="podcast-inner-box-content">
                          <Box sx={{ display: "flex" }}>
                            <img
                              src={podcastImage}
                              alt="Avatar"
                              style={{
                                width: "56px",
                                height: "56px",
                                borderRadius: "50%",
                                marginRight: "10px",
                              }}
                            />
                            <Box sx={{ flex: 1, position: "relative" }}>
                              <Box
                                sx={{
                                  backgroundColor: "black",
                                  borderRadius: "8px",
                                  padding: "10px",
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "10px",
                                }}
                              >
                                <TextField
                                  value={this.state.textareaValue}
                                  onChange={this.handleTextareaChange}
                                  fullWidth
                                  multiline
                                  placeholder="Create A Post"
                                  rows={4}
                                  variant="outlined"
                                  sx={{
                                    backgroundColor: "black",
                                    color: "white",
                                    borderRadius: "8px",
                                    "& .MuiOutlinedInput-root": {
                                      backgroundColor: "black",
                                      borderRadius: "8px",
                                      padding: "10px",
                                    },
                                    "& .MuiInputBase-input": {
                                      color: "white",
                                    },
                                  }}
                                  InputProps={{
                                    readOnly: !!this.state.selectedFile,
                                  }}
                                />
                                {this.state.selectedFile && (
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "10px",
                                      alignItems: "center",
                                      backgroundColor: "black",
                                      borderRadius: "8px",
                                      padding: "10px",
                                    }}
                                  >
                                    <img
                                      src={URL.createObjectURL(this.state.selectedFile)}
                                      alt="Uploaded Preview"
                                      style={{
                                        width: "100px",
                                        height: "auto",
                                        borderRadius: "8px",
                                      }}
                                    />
                                    <IconButton onClick={this.handleRemoveFile} >
                                      <CloseRoundedIcon style={{ fill: "white" }} />
                                    </IconButton>
                                  </Box>
                                )}
                              </Box>
                              <Box
                                sx={{
                                  position: "absolute",
                                  bottom: "10px",
                                  right: "10px",
                                  display: "flex",
                                  gap: "10px",
                                }}
                              >
                                <input
                                  type="file"
                                  style={{ display: "none" }}
                                  id="file-upload"
                                  onChange={this.handleFileUpload}
                                />
                                <IconButton
                                  sx={{
                                    color: "red",
                                    backgroundColor: "red",
                                    padding: "10px",
                                  }}
                                  data-test-id="file-uploadId"
                                  onClick={() => document.getElementById("file-upload")?.click()}
                                >
                                  <AttachFileIcon style={{fill:"white"}} />
                                </IconButton>
                                <IconButton
                                  sx={{
                                    color: "white",
                                    backgroundColor: "#DADADA",
                                    padding: "10px",
                                  }}
                                  data-test-id="handleSend"
                                  onClick={this.handleSend}
                                >
                                  <SendRoundedIcon />
                                </IconButton>
                              </Box>
                            </Box>
                          </Box>
                        </CardContent>
                      }
                      </Box>
                    </Card>
                    {this.state.communityListData.map((item : Post) => (
                      <Card className="podcast-card">
                        <Box className="podcast-inner-box">
                        <CardContent className="podcast-inner-box-content" style = {{display : "flex"}} onClick={this.showComments}>
                          <Box sx = {{padding : "26px 0px 0px 30px"}}>
                            <img
                                src={item.attributes.photos[0]?.photot_link}
                                alt="Avatar"
                                style={{
                                  width: "56px",
                                  height: "56px",
                                  borderRadius: "50%",
                                  marginRight: "10px",
                                }}
                              />
                          </Box>
                          <Box>
                        <Box sx = {{margin : "20px" }}>
                          <Box className = "community-list">
                            <Typography component="div" variant="h6" color="#fff" className="podcast-inner">{configJSON.channelInfoTitle}</Typography>
                            <Box>
                              <Button data-test-id= "isOpenMenuID" onClick = {this.handleOpenDropDown} sx = {{position : "relative"}}>
                                <img src = {Image_dots} style={{width: "25 px",height: "4px"}}/>
                              </Button>
                             {this.state.openDropDown && 
                             <DropDown>
                                <DropDownItems sx = {{backgroundColor : "FF0807"}}><EditIcon /><Typography sx = {{fontSize : "14px" }}>{configJSON.dropDownEdit}</Typography></DropDownItems>
                                <DropDownItems><BookmarkBorderIcon/><Typography sx = {{fontSize : "14px"}}>{configJSON.dropDownSave}</Typography></DropDownItems>
                                <DropDownItems><NotificationsNoneIcon/><Typography sx = {{fontSize : "14px"}}>{configJSON.TurnOffNotification}</Typography></DropDownItems>
                                <DropDownItems><DeleteIcon/><Typography sx = {{fontSize : "14px"}}>{configJSON.dropDownDelete}</Typography></DropDownItems>
                              </DropDown>
                              }
                            </Box>
                          </Box>
                            <Typography  variant="subtitle1" color="#fff" component="div" className="podcast-inner">{moment(item?.attributes?.created_at).format('DD/MM/YYYY')}</Typography>
                            <Typography variant="body2" color="#fff" sx={{ marginTop: '10px', fontFamily: 'Nexa-Regular' }}>{configJSON.communityText}</Typography>
                            <Box sx = {{padding : "10px"}}>
                              <img src = {ad_1}  />
                            </Box>
                         </Box>
                        <Box sx = {{marginLeft: "26px"}} className = "community-list-buttons" >
                           <Typography sx={{ color: 'white' }} className="podcast-inner">{configJSON.daysAgo}</Typography>
                           <IconButton aria-label="like" sx={{ color: 'white' }}>
                              <ThumbUpOffAlt />
                            </IconButton>
                            <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">1.2K</Typography>
                            <IconButton aria-label="comment" sx={{ color: 'white' }}>
                              <ChatBubbleOutline />
                            </IconButton>
                            <Typography sx={{ color: 'white', marginRight: '15px' }} className="podcast-inner">546</Typography>
                            <IconButton aria-label="share" sx={{ color: 'white' }}>
                              <Share />
                            </IconButton>
                            <Typography sx={{ color: 'white' }} className="podcast-inner">2.2K</Typography>
                          </Box>
                          </Box>
                          <Box>
                        </Box>
                        </CardContent>
                      </Box>
                    </Card>
                    ))}
                    
                    {
                      this.state.showComments &&
                      <>
                          <Typography color="#fff" className="podcast-inner" style={{ fontSize: "30px", marginTop: "25px", lineHeight: "40px" }}>
                            Comments
                          </Typography>
                          <Card className="podcast-card-add-comment" >
                            <Box className="podcast-inner-box">
                              <CardContent className="podcast-inner-box-content" style={{ padding: "22px 33px 22px 43px" }}>
                                <Box sx={{ display: "flex" }}>
                                  <img
                                    src={podcastImage}
                                    alt="Avatar"
                                    style={{
                                      width: "56px",
                                      height: "56px",
                                      borderRadius: "50%",
                                      marginRight: "10px",
                                    }}
                                  />
                                  <Box sx={{ flex: 1, position: "relative" }}>
                                    <Box
                                      sx={{
                                        backgroundColor: "black",
                                        borderRadius: "20px 20px 0px 0px",
                                        padding: "10px",
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "10px",
                                      }}
                                    >
                                      <TextField
                                        value={this.state.textareaValue}
                                        onChange={this.handleTextareaChange}
                                        fullWidth
                                        multiline
                                        placeholder="Leave a comment"
                                        rows={4}
                                        variant="outlined"
                                        sx={{
                                          backgroundColor: "black",
                                          color: "white",
                                          borderRadius: "8px",
                                          "& .MuiOutlinedInput-root": {
                                            backgroundColor: "black",
                                            borderRadius: "8px",
                                            padding: "10px",
                                          },
                                          "& .MuiInputBase-input": {
                                            color: "white",
                                          },
                                        }}
                                        InputProps={{
                                          readOnly: !!this.state.selectedFile,
                                        }}
                                      />
                                      {this.state.selectedFile && (
                                        <Box
                                          sx={{
                                            display: "flex",
                                            gap: "10px",
                                            alignItems: "center",
                                            backgroundColor: "black",
                                            borderRadius: "8px",
                                            padding: "10px",
                                          }}
                                        >
                                          <img
                                            src={URL.createObjectURL(this.state.selectedFile)}
                                            alt="Uploaded Preview"
                                            style={{
                                              width: "100px",
                                              height: "auto",
                                              borderRadius: "8px",
                                            }}
                                          />
                                          <IconButton onClick={this.handleRemoveFile} >
                                            <CloseRoundedIcon style={{ fill: "white" }} />
                                          </IconButton>
                                        </Box>
                                      )}
                                    </Box>
                                    <Box
                                      sx={{
                                        position: "absolute",
                                        bottom: "10px",
                                        right: "10px",
                                        display: "flex",
                                        gap: "10px",
                                      }}
                                    >
                                      <IconButton
                                        sx={{
                                          color: "white",
                                          backgroundColor: "#DADADA",
                                          padding: "10px",
                                        }}
                                        data-test-id="handleSend2"
                                        onClick={() => { this.handleReply(null) }}
                                      >
                                        <SendRoundedIcon />
                                      </IconButton>
                                    </Box>
                                  </Box>
                                </Box>
                              </CardContent>
                            </Box>
                          </Card>
                          {this.renderComments(this.state.comments, 43, this.state.openPopup)}
                      </>
                    }
                    <Card className="podcast-card">
                      <Box className="podcast-inner-box" >
                        <CardContent className="podcast-inner-box-content" style = {{display : "flex"}}>
                          <Box sx = {{padding : "26px 0px 0px 30px"}}>
                            <img src={podcastImage} alt="Avatar" style={{ width: "56px", height: "56px", borderRadius: "50%", marginRight: "10px"}}/>
                          </Box>
                          <Box>
                        <Box sx = {{margin : "20px" }}>
                          <Box className = "community-list">
                            <Typography component="div" variant="h6" color="#fff" className="podcast-inner">{configJSON.communityTitle}</Typography>
                            <Box>
                              <Button data-test-id= "isOpenMenuUser" onClick = {this.handleOpenDropDownUser} sx = {{position : "relative"}}>
                                <img src = {Image_dots} style={{width: "25 px",height: "4px"}}/>
                              </Button>
                             {this.state.openDropDownUser && 
                             <DropDown>
                                <DropDownItems><BookmarkBorderIcon/><Typography sx = {{fontSize : "14px"}}>{configJSON.dropDownSave}</Typography></DropDownItems>
                                <DropDownItems><NotificationsNoneIcon/><Typography sx = {{fontSize : "14px"}}>{configJSON.TurnOffNotification}</Typography></DropDownItems>
                                <DropDownItems><VisibilityOffIcon/><Typography sx = {{fontSize : "14px"}}>{configJSON.hideText}</Typography></DropDownItems>
                                <DropDownItems><BlockIcon/><Typography sx = {{fontSize : "14px"}}>{configJSON.blockUserText}</Typography></DropDownItems>
                              </DropDown>
                              }
                            </Box>
                          </Box>
                            <Typography variant="subtitle1" color="#fff" component="div" className="podcast-inner">{configJSON.randomDate2}</Typography>
                            <Typography variant="body2" color="#fff" sx={{ marginTop: '10px', fontFamily: 'Nexa-Regular' }}>{configJSON.communityText}</Typography>
                         </Box>
                        <Box sx = {{marginLeft: "26px"}} className = "community-list-buttons" >
                           <IconButton aria-label="like" sx={{ color: '#FFF' }}>
                              <ThumbUpOffAlt />
                            </IconButton>
                            <Typography 
                            sx={{ color: '#FFF', marginRight: '15px' }} 
                            className="podcast-inner"
                            >2.7K</Typography>
                            <IconButton aria-label="comment" sx={{ color: '#FFF' }}>
                              <ChatBubbleOutline />
                            </IconButton>
                            <Typography sx={{ color: '#FFF', marginRight: '15px' }} className="podcast-inner">564</Typography>
                            <IconButton aria-label="share" sx={{ color: '#FFF' }}>
                              <Share />
                            </IconButton>
                            <Typography sx={{ color: '#FFF' }} className="podcast-inner">2.9K</Typography>
                          </Box>
                          </Box>
                          <Box>
                        </Box>
                        </CardContent>
                      </Box>
                    </Card>
                  </>
                  }
                  {this.state.selectedTab == "ChannelInfo" && 
                   <>
                   <Card className="podcast-card" >
                      <Box className="podcast-inner-box">
                        <Box sx={{
                            display: "flex",
                            gap: "30px",
                            padding : "25px",
                          }}>
                           <img src = {podcastImage} className="channelInfoImg" />
                          <Box className = "channelInfoHeader">
                            <Typography variant="h5" fontWeight="bold" sx = {{color: '#fff', fontFamily: "Nexa-Heavy"}}>
                              {configJSON.channelInfoTitle}
                            </Typography>
                            <Typography variant="h6"  fontWeight="bold" sx = {{color: '#fff', fontFamily: "Nexa-Heavy"}}>
                            {configJSON.channelInfodescription}
                            </Typography>
                            <Box sx= {{display : "flex" , alignItems : "center" , gap : "5px"}}>
                            <LocationOnIcon fontSize="small"  style = {{ color : "#fff"}}/>
                            <Typography variant="h6" fontWeight="bold" sx = {{color: '#fff', fontFamily: "Nexa-Heavy"}}>
                            {configJSON.channelInfoLocation}
                            </Typography>
                            </Box>
                          </Box>
                        </Box>
                        <CardContent style = {{padding : "25px"}}>
                          <Typography variant="h6" fontWeight="bold" sx={{paddingBottom: 1, mb: 1  , color : "#fff" , fontFamily: "Nexa-Heavy"}}>
                            {configJSON.aboutTitle}
                          </Typography>
                          <Typography variant="body2" sx = {{fontFamily : "Nexa-Regular" , color : "#fff"}} paragraph>
                            {configJSON.aboutText}
                          </Typography>
                          <Typography variant="body2" sx = {{fontFamily : "Nexa-Regular" , color : "#fff"}} paragraph>
                           {configJSON.aboutText}
                          </Typography>
                          <Typography variant="body2" sx = {{fontFamily : "Nexa-Regular" , color : "#fff"}} paragraph>
                            {configJSON.aboutText}
                          </Typography>
                        </CardContent>
                        <Box sx={{padding: 2,borderRadius: 2}}>
                          <Box className = "channelInfoHost">
                            <Avatar
                              src="https://via.placeholder.com/80"
                              alt="Host Avatar"
                              sx={{ width: 80, height: 80 }}
                            />
                               <Box>
                          <Typography variant="h6" sx={{ mb: 1  , color : "#fff" , fontFamily : "Nexa-Heavy"}}>
                            {configJSON.host}
                          </Typography>
                          <Typography variant="body1" fontWeight="bold" sx = {{fontSize : "18px" , color : "fff"}} >
                            {configJSON.hostName}
                          </Typography>
                          <Typography variant="body2" sx = {{marginTop : "10px" , fontFamily : "Nexa-Regular" , color : "#fff"}}>
                           {configJSON.hostDescription}
                          </Typography>
                        </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Card>
                    <Box className="otherSectionContainer">
                      <Typography variant="h4" fontWeight="bold" sx={{ color: '#fff', fontFamily: "Nexa-Heavy" , mb:1}} >
                        {configJSON.otherShows}
                      </Typography>
                      <Card className="otherSectionCardContainer">
                        <Box className="internalContainer">
                          <Box className="otherSectionCard">
                            <img src={podcastImage} className="otherSectionCard-img" />
                            <Button className = "follow-btn" variant="contained" color = "error">Follow</Button>
                            <Box className="otherSectionCard-textContainer">
                              <Typography className="otherSectionCard-text1">{configJSON.hostNameOther}</Typography>
                              <Typography className="otherSectionCard-text2">{configJSON.channelInfoTitle}</Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Card>
                    </Box>
                   </>
                  }
                </Box>
              </div>
               <Box sx={{ minHeight: '100vh' }}>
                <Grid container direction="column" spacing={2} className="right-body-section">
                  <Typography className="sponsored-title">Sponsored Recommendations</Typography>
                  <Grid item>
                    <Card className="recommended-cards">
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent>
                          <Typography variant="h6" sx={{ color: '#fff', fontFamily: "Nexa-Heavy" }}>
                            The True
                          </Typography>
                          <Typography variant="body2" sx={{ color: '#fff', fontFamily: "Nexa-Regular" }}>
                            Cost of Success Ep. 9
                          </Typography>
                        </CardContent>
                      </Box>
                      <CardMedia
                        component="img"
                        image={recommended_episode_1}
                        alt="The True Episode"
                        sx={{
                          width: "20%",
                          height: "100%",
                          marginLeft: 'auto',
                          objectFit: 'cover',
                          marginRight: "-11px"
                        }}
                      />
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card className="recommended-cards">
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent>
                          <Typography variant="h6" sx={{ color: '#fff', fontFamily: 'Nexa-Heavy' }}>
                            All Consuming
                          </Typography>
                          <Typography variant="body2" sx={{ color: '#fff', fontFamily: 'Nexa-Regular' }}>
                            Fantomy Ep. 12
                          </Typography>
                        </CardContent>
                      </Box>
                      <CardMedia
                        component="img"
                        image={recommended_episode_2}
                        alt="The True Episode"
                        sx={{
                          width: "20%",
                          height: "100%",
                          marginLeft: 'auto',
                          objectFit: 'cover',
                          marginRight: "-11px"
                        }}
                      />
                    </Card>
                  </Grid>
                  <Grid item>
                    <Card className="recommended-cards">
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <CardContent>
                          <Typography variant="h6" sx={{ color: '#fff', fontFamily: 'Nexa-Heavy' }}>
                            This American Life
                          </Typography>
                          <Typography variant="body2" sx={{ color: '#fff', fontFamily: 'Nexa-Regular' }}>
                            Love your era Ep. 8
                          </Typography>
                        </CardContent>
                      </Box>
                      <CardMedia
                        component="img"
                        image={recommended_episode_3}
                        alt="The True Episode"
                        sx={{
                          width: "20%",
                          height: "100%",
                          marginLeft: 'auto',
                          objectFit: 'cover',
                          marginRight: "-11px"
                        }}
                      />
                    </Card>
                  </Grid>
                </Grid>
                <Box display="flex" flexDirection="column" gap="25px" >
                  <Typography variant="h6" sx={{ color: '#fff', marginTop: 4, fontFamily: 'Nexa-Heavy' }}>
                    Advertising
                  </Typography>
                  <Box
                    component="img"
                    src={ad_1}
                    alt="Burger Ad"
                    sx={{ height: '20rem', borderRadius: "13px" }}
                  />
                  <Box
                    component="img"
                    src={ad_2}
                    alt="Heinz Ad"
                    sx={{ height: '20rem', borderRadius: "13px" }}
                  />
                </Box>
              </Box>
            </Box>
          </StyledWrapperSetting>
          <Footer navigation={this.props.navigation} id={"1"} />
        </div>
      </>

      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start
const buttonStyles = {
  borderRadius: "8px",
  fontFamily: "Nexa-Heavy",
  fontSize: "16px",
  textTransform: "capitalize",
  height: "34px",
};

const textFieldStyles = {
  '& .MuiInputBase-root': {
    backgroundColor: '#030303',
    borderRadius: '8px',
    color: 'white',
    fontFamily: 'Nexa-Heavy',
    fontWeight: 900,
    fontSize: '16px',
    border: '1px solid #FFFFFF',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};


const StyledWrapperSetting = styled("div")({
  // margin: "0 auto",
  height: "auto",
  // width: "100%",
  padding:"20px",
  background: "black",
  "& *": {
    boxSizing: "border-box",
  },
  "& .header-section": {
    backgroundColor: "#44403C",
    width: "102%",
    minHeight: "20rem",
    borderRadius: "0.5rem",
    padding: "1rem",
    // marginLeft: "90px",
    marginTop: "18px"
  },
  "& .podcast-name": {
    fontWeight: "900",
    fontSize: "40px",
    lineHeight: "56px",
    textTransform: "uppercase",
    fontFamily: "Nexa-Heavy"
  },
  "& .podacst-image": {
    width: "269px",
    height: "259px",
    borderRadius: "5px",
    marginTop: "13px",
    marginLeft: "8px"
  },
  "& .podcast-header-content": {
    color: "white",
    fontWeight: "bold",
    marginTop: "13px"
  },
  "& .left-section-buttons": {
    color: "white",
    border: "1px solid white",
    borderRadius: "8px",
    marginTop: "5px",
    fontWeight: "bold",
    textTransform: 'capitalize',
    fontFamily: "Nexa-Heavy",
    "&:hover": {
      color: "white",
      border: "1px solid white",
    }
  },
  "& .podcast-header-info-icon": {
    width: "44px",
    height: '44px',
    borderRadius: "8px",
    color: "white",
    backgroundColor: "#FF0807",
    marginTop: "5px",
    "&:hover": {
      backgroundColor: "#FF0807",
    }
  },
  "& .total-reviews": {
    fontWeight: "900",
    fontSize: "18px",
    lineHeight: "22px",
    marginTop: "15px",
    fontFamily: "Nexa-Heavy"
  },
  "& .left-section-bottom-buttons": {
    backgroundColor: "#FF5722",
    color: "white",
    borderRadius: "8px",
    width: "164px",
    height: '44px',
    fontWeight: "bold",
    textTransform: 'capitalize',
    borderColor: "#FF5722",
    marginTop: "5px",
    fontFamily: "Nexa-Heavy",
    "&:hover": {
      backgroundColor: "#FF5722",
      borderColor: "#FF5722",
    }
  },
  "& .left-body-section-tabs": {
    // width: "536px",
    height: "52px",
    // marginLeft: "90px",
    marginTop: "22px"
  },
  "& .section-tabs": {
    color: 'white',
    backgroundColor: '#44403C',
    textTransform: "capitalize",
    borderColor: "#44403C",
    fontFamily: "Nexa-Heavy",
    "&:hover": {
      backgroundColor: '#44403C',
      borderColor: "#44403C",
    },
    marginBottom: "0px"
  },
  "& .scrollableWrapper": {
    height: "65rem",
    position: "relative",
    paddingRight: "20px",
    overflow: "hidden",
  },
  "& .left-body-section": {
    height: "100%",
    width: "100%",
    overflowY: "scroll",
    paddingRight: "10px",
    scrollbarWidth: "thin",
    scrollbarColor: "#FF4500 #4D4D4D",

    "&::-webkit-scrollbar": {
      width: "15px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#4D4D4D",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#FF4500",
      borderRadius: "10px",
    },
  },
  "& .podcast-card": {
    display: 'flex',
    backgroundColor: '#44403C',
    gap: "24px",
    // width: "1005px",
    height: "auto",
    "&:first-of-type": {
      marginTop: "0px",
    },
    "&:not(:first-of-type)": {
      marginTop: "20px",
    }
  },
  "& .podcast-card-comment": {
    display: 'flex',
    backgroundColor: '#44403C',
    gap: "24px",
    height: "auto",
    "&:first-of-type": {
      marginTop: "0px",
    }
  },
  "& .podcast-card-add-comment": {
    display: 'flex',
    backgroundColor: '#44403C',
    gap: "24px",
    height: "auto",
    borderRadius:"20px 20px 0px 0px",
    "&:first-of-type": {
      marginTop: "0px",
    }
  },
  "& .podcast-inner-box": {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  "& .podcast-inner-box-content": {
    flex: '1 0 auto',
    paddingBottom: 0
  },
  "& .podcast-action-buttons": {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 2,
    paddingBottom: 10
  },
  "& .podcast-saving-options": {
    display: 'flex',
    flexDirection: 'column',
    gap: "9px"
  },
  "& .podcast-save-button": {
    color: 'white',
    borderColor: '#FF0807',
    marginRight: "0px",
    width: "118px",
    height: "26px",
    backgroundColor: "#FF0807",
    borderRadius: "8px",
    textTransform: "capitalize",
    gap: "8px",
    "&:hover": {
      borderColor: '#FF0807',
      backgroundColor: "#FF0807",
    },
    "&:first-of-type": {
      marginTop: "25px",
    },
    "&:not(:first-of-type)": {
      marginTop: "0px",
    }
  },
  "& .podcast-media": {
    width: "123px",
    height: "118px",
    marginTop: "34px",
    marginLeft: "44px",
    borderRadius: "3px"
  },
  "& .play-icon": {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    height: '10%',
    marginRight: "2%",
    marginTop: "19%"
  },
  "& .sponsored-title": {
    color: "#fff",
    marginLeft: "15px",
    fontFamily: 'Nexa-Heavy'
  },
  "& .right-body-section": {
    marginTop: "-35px",
    // width: "47.8%", 
    // height: "33%"
  },
  "& .recommended-cards": {
    display: 'flex',
    backgroundColor: '#44403C',
    color: '#fff',
    // width: '478px', 
    height: '80px',
    borderRadius: '6px',
    borderLeft: '5px solid red'
  },
  "& .podcast-inner": {
    fontFamily: 'Nexa-Heavy'
  },
  "& .community-list": {
   display : "flex",
   justifyContent : "space-between",
   alignItems: "flex-start"
  },
  
  "& .community-list-buttons": {
    display : "flex",
    alignItems: "center"
   },
  "& .otherSectionContainer":{
   marginTop:"50px",
   width:"100%",
  },
  "& .otherSectionCardContainer":{
    display:"flex",
    alignItems: "center",
    justifyContent :"center",
    backgroundColor :"#312F2D",
  },
  "& .internalContainer":{
    display : "flex",
    justifyContent : "space-around",
    flexWrap : "wrap",
    width : "1000px",
  },
"& .otherSectionCard-img":{
    width : "285px", 
    height : "290px",
 
},
  "& .otherSectionCard":{
    width : "286px", 
    height : "375px",
    backgroundColor :"#000",
    marginTop:"40px",
    marginBottom:"30px",
    borderRadius: "0px 20px 20px" ,
    position : "relative"
  },
  "& .otherSectionCard-text1":{
    fontFamily : "Nexa-Heavy",
    fontSize : "18px",
    color : "#fff",
    width : "250px", 
},
"& .otherSectionCard-text2":{
  fontFamily : "Nexa-Heavy",
  fontSize : "16px",
  color : "#fff",
  width : "250px", 
},
"& .otherSectionCard-textContainer":{
  margin: "20px"
}, 
"& .channelInfoImg":{
  position :"relative",
  width: "100px", 
  height: "100px", 
  borderRadius: "8px"
}, 
"& .channelInfoHeader":{
 display : "flex",
 flexDirection : "column",
 justifyContent : "center"
}, 
"& .channelInfoHost":{
 display : "flex",
 alignItems : "center",
 gap : "30px"
}, 

"& .follow-btn":{
  fontWeight : "bold",
  position : "absolute",
  right : "10px",
  top : "12px"
}, 
"& .modalBox":{
  display : "flex",
  justifyContent : "space-between",
  alignItems:"center",
  gap : "30px",
  position :"relative",
},

});
const webStyles = {
  title : {
    fontWeight: 900,
    letter: "-0.5%",
    color: "#fff",
    fontFamily: "Nexa-Heavy"
  },
  titledesc : {
    fontWeight: 400,
    color: "#fff",
    fontFamily: "Inter"
  },
  container: {
    width: "100%",
    "&::-webkit-scrollbar": {
      width: "12px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "red",
      borderRadius: "10px",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "gray",
    },
    "&::-webkit-scrollbar-button": {
      display: "none",
    },
  } as const
}

const ShareChannelModalstyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  height : 466,
  bgcolor: '#44403C',
  borderRadius:2,
  p: 4,
};


const DropDown = styled(Box)(
  {
    display : "flex",
    flexDirection : "column",
    backgroundColor : "#44403C",
    width: "185px",
    border : "1px solid white",
    position: "absolute",
    right: "94px",
  }
)

const DropDownItems = styled(Box)(
  {
    display : "flex",
    alignItems : "center",
    fontFamily :"Nexa-Regular",
    gap : "8px",
    borderBottom : "1px solid white",
    backgroundColor : "#44403C",
    padding : "10px",
    color: "#FFF"
  }
)



const ModalBoxInput = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "20px", marginLeft: "30px",
    width: "300px"
  })

const CloseIconModal = styled(CloseIcon)({ color: "#fff", position: "absolute", left: "1180px" })

const CheckBoxModal = styled(Checkbox)(
  { color: "#44403c", '&.Mui-checked': { color: "white", }, borderColor: "#fff", width: "40px", height: "40px", border: "1px solid #fff", borderRadius: "10px" }
)
const ShareChannelModal = styled(Box)({
  display: "flex",
  alignItems: "center"
})

const CopyButton = styled(Button)(
  {
    width: "190px",
    marginLeft: "15px",
    borderRadius: "8px"
  }
)

const ShareIcon = styled(IconButton)({
  color: '#fff',
  marginBottom: "11px"
})

const UrlBox = styled(Box)(
  {
    display: "flex",
    width: "100%",
    justifyContent: "space-between"
  }
)


const ContentCopy = styled(ContentCopyIcon)(
  {
    color: "#fff",
    marginRight: "12px"
  }
)

const ChannelTextFeild = styled(Typography)(
  {
    backgroundColor: "#000",
    border: "2px solid #fff",
    borderRadius: "5px",
    width: "80%",
    color: "#fff",
    display: "flex",
    justifyContent: "start",
    padding: "20px"
  }
)

const EmbededTextFeild = styled(Typography)(
  {
    backgroundColor: "#000",
    border: "2px solid #fff",
    borderRadius: "5px",
    width: "61%",
    color: "#fff",
    display: "flex",
    justifyContent: "start",
    padding: "20px"
  }
)
const ShareChannelText = styled(Typography)({
  fontFamily: "Nexa-Heavy",
  color: "#fff"
})

const ZeroInput = styled(Typography)({
  backgroundColor: "#000",
  border: "2px solid #fff",
  borderRadius: "5px",
  width: "46px",
  height: "46px",
  padding: "10px",
  color: "#fff",
  display: "flex",
  justifyContent: "start",
  alignItems: "center"
})

const LinkContainer = styled(Box)({
  display: "flex",
  width: "100%"
})

const CopyButton2 = styled(Button)({
  width: "155px",
  marginLeft: "15px",
  borderRadius: "8px"
}
)
// Customizable Area End
