// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Button,
} from "@mui/material";
import HostMonetizationTemplateController, {
    Props,
} from "./HostMonetizationTemplateController";
import { goBack, template, templateSelected } from "./assets";
import { ResolveCondition, handleNavigation } from "../../utilities/src/CustomHelpers";

export default class HostMonetizationTemplate extends HostMonetizationTemplateController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Box ref={this.scrollRef} style={{ maxHeight: "100%", overflowY: "auto" }}>
                <StyledWrapperSponsoredHostMonetization>
                    <Box style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Box className="mainTitle" id="sponsoredHost" style={{ display: "flex", alignItems: "center",marginTop:"10px",marginBottom : "10px",marginLeft: "60px" }}>
                            <img
                                src={goBack}
                                alt="goBack"
                                style={{ marginRight: "20px", cursor: "pointer", color: "white" }}
                                data-test-id="navigate"
                                onClick={() => { this.props.handleCommonPath("SponsoredContentPartnership") }}
                            />
                            Back
                        </Box>
                        <Box className="upperBtn" id="sponsoredHost" 
                        data-test-id="template"
                        onClick={()=>{this.deleteMonetizationTemplate()}} style={{ marginLeft: "auto", cursor:"pointer",marginRight: "10px"}}>
                            Re-Set Up Monetization
                        </Box>
                        <Box className="upperBtn" id="sponsoredHost" style={{marginRight : "70px"}}>
                            Save For Later
                        </Box>
                    </Box>

                    <Box className="scrollHostMonetization" id="sponsoredHost">
                        <Box className="container">
                            <Box className="subContainer" id="sponsoredHost"> 
                                <Box className="upperContent">
                                    <Box className="title">Sponsored Content Channel</Box>
                                    <Box className="desc">Sponsored Channel Content will appear on the Host Channel. Press continue to confirm that you would like this on your channel.</Box>
                                </Box>
                                <Box className="lowerContent">
                                    <Box style={{ fontFamily: "Nexa-Regular", textAlign: "center", fontWeight: 400, fontSize: "16px", marginBottom: "10px" }}>Host Channel</Box>

                                    <Box
                                        style={{
                                            width: "30%",
                                            height: "auto",

                                        }}
                                    >
                                        <img
                                            src={ResolveCondition(this.state.templateSelected,templateSelected,template)}
                                            onClick={() => {
                                                this.setState(prevState => ({
                                                    templateSelected: !prevState.templateSelected
                                                }))
                                            }}
                                            data-test-id = "toggle"
                                            alt="template"
                                            style={{
                                                display: "block",
                                                width: "100%",
                                                height: "auto",
                                            }}
                                        />
                                    </Box>
                                </Box>
                                <Button
                                    className={this.state.templateSelected === true ? "btn btnColor" : "btn"}
                                    disabled={this.state.templateSelected === false ? true : false}
                                    data-test-id="dataNavigations"
                                    onClick={() => {
                                        this.props.handleCommonPath("HostMonetizationSponsoredPost")
                                    }}
                                >Continue</Button>
                            </Box>
                        </Box>
                    </Box>
                </StyledWrapperSponsoredHostMonetization>
            </Box>

        );
    }

}
const StyledWrapperSponsoredHostMonetization = styled("div")({
    background: "black",
    padding:"20px 50px",
    "& *": {
        boxSizing: "border-box",
    },
    "& .upperBtn": {
        width: "auto",
        borderRadius: "8px",
        height: "26px",
        fontFamily: "Nexa-Heavy",
        color: "white",
        fontWeight: 400,
        fontSize: "16px",
        background: "#312F2D",
        marginRight: "26px"
    },
    "& .btn": {
        margin: "auto",
        height: "56px",
        marginTop: "1%",
        cursor: "pointer",
        marginBottom: "6%",
        color: "white",
        width: "30%",
        background: "#B2B3B3",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        fontWeight: 900,
        fontFamily: "Nexa-Heavy",
        alignItems: "center",
        fontSize: "14px",
        textTransform: "capitalize",
        "&:disabled": {
            color: "white",
        },
        "&:hover": {
            background: "#B2B3B3",
        },
    },
    "& .btnColor": {
        "&:hover": {
            background: "#FF0807",
        },
        background: "#FF0807",
    },
    "& .mainTitle": {
        marginLeft: "2%",
        margin: "auto",
        marginBottom: "2%",
        height: "40px",
        color: "white",
        fontFamily: "Nexa-Heavy",
        fontWeight: 900,
        fontSize: "30px",
        display: "flex",
        alignItems: "center",
    }, 
    "& .lowerContent": {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        marginTop: "2%",
        height: "auto",
        padding: "20px",
    },
    "& .scrollHostMonetization": {
        overflowY: "scroll",
        scrollbarColor: "#FF0807 #4D4D4D",
        position: "relative",
        paddingRight: "40px",
        overflow: "hidden",
        scrollbarWidth: "thin"
    },
    "& .upperContent": {
        width: "100%",
        marginTop: "2%"
    },   
    "& .title": {
        fontFamily: "Nexa-Heavy",
        color: "white",
        fontSize: "30px",
        textAlign: "center",
        textTransform: "capitalize",
        fontWeight: 900,
    },
    "& .desc": {
        margin: "auto",
        marginTop: "1%",
        textAlign: "center",
        fontSize: "14px",
        fontFamily: "Nexa-Heavy",
    },
    "& .subContainer": {
        display: "flex",
        margin: "auto",
        flexDirection: "column",
        height: "auto",
        width: "95%",
        borderRadius: "15px",
        backgroundColor: "#44403C",
    },  
    "& .container": {
        height: "auto",
        margin: "auto",
        color: "white",
        marginBottom: "3%",
    },
})
// Customizable Area End
