import React  from 'react'
import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { business } from "../assets"
import { prev, next, sponsored1, suggested1, suggested2 } from "../../../dashboard/src/assets";
interface ArrowProps {
    onClick: () => void;
  }
// Customizable Area End

export const configJSON = require("../config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  handleCommonPath: (path:string) => void;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  sponsoredPost: any,
  suggestedPodcast: any,
  trendingPodcast: any,
  statesDropdown: Array<string>,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SubCategoriesController extends BlockComponent<Props, S, SS> {
// Customizable Area Start
  getProductApiCallId: any;
// Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];
    // Customizable Area End

    this.state = {
    // Customizable Area Start
    sponsoredPost: [],
    suggestedPodcast: [],
    trendingPodcast: [],
    statesDropdown: [],
    // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    
    this.setState({
     
        trendingPodcast: [
            {id: 1, src: business, alt: "image1", artist: "The Allusionist", name: "BUSINESS PODCAST", followers: "Follow"}, 
            {id: 2, src: business, alt: "image2", artist: "The Allusionist", name: "BUSINESS PODCAST", followers: "Following"},
            {id: 3, src: business, alt: "image1", artist: "The Allusionist", name: "BUSINESS PODCAST", followers: "Follow"}, 
            {id: 4, src: business, alt: "image2", artist: "The Allusionist", name: "BUSINESS PODCAST", followers: "Following"},
            {id: 5, src: business, alt: "image1", artist: "The Allusionist", name: "BUSINESS PODCAST", followers: "Follow"}, 
          ],
        sponsoredPost: [
          {id: 1, src: sponsored1, alt: "image1", episode: "Episode 12", name: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis ."}, 
          {id: 2, src: sponsored1, alt: "image2", episode: "Episode 12", name: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis ."},
          {id: 3, src: sponsored1, alt: "image3", episode: "Episode 12", name: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis ."},
          {id: 4, src: sponsored1, alt: "image4", episode: "Episode 12", name: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis ."},
          {id: 5, src: sponsored1, alt: "image5", episode: "Episode 12", name: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis ."},
          {id: 6, src: sponsored1, alt: "image6", episode: "Episode 12", name: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis ."},
          {id: 7, src: sponsored1, alt: "image7", episode: "Episode 12", name: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis ."},
        ],
      suggestedPodcast: [
        {id: 1, src: suggested1, alt: "image1", artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false}, 
        {id: 2, src: suggested2, alt: "image2", artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false},
        {id: 3, src: suggested2, alt: "image1", artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: true, added: true}, 
        {id: 4, src: suggested1, alt: "image2", artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: false, added: false},
        {id: 5, src: suggested1, alt: "image1", artist: "Michael Hertz", name: "20 THOUSAND HERTZ", selected: true, added: true}, 
      ],

      statesDropdown : [
        "Careers",
        "Entrepreneurship",
        "Investing",
        "Management",
        "Marketing",
        "Non-Profit"
      ]
       
      })
}

// Customizable Area End

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start


  getSponsoredSettings() {
    const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
  
    return {
      dots: true,
      infinite: false,
      speed: 800,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      nextArrow: React.createElement(this.nextSponsoredPostArrow, { onClick: () => {}, innerWidth }),
      prevArrow: React.createElement(this.prevSponsoredPostArrow, { onClick: () => {}, innerWidth }),
    };
  }

 
  prevSponsoredPostArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props
  
    return (
      <img
        src={prev}
        onClick={onClick}
        style={{
          position: 'absolute',
          left: "-6%",
          top: '50%',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 1,
        }}
        alt="Previous Arrow"
      />
    );
  };

  nextSponsoredPostArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return (
      <img
        src={next} 
        onClick={onClick} 
        style={{
          position: 'absolute',
          right: "-6%",
          top: '50%',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 1,
        }} 
        alt="Next Arrow"
      />
    );
  }


  // Customizable Area End
}
