// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { handleNavigation, ResolveCondition } from "../../utilities/src/CustomHelpers";
import { createRef } from "react";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath: (path: string) => void;
}

export interface S {
  isLinkValid: boolean,
  step: number,
  backgroundColor: string,
  secondaryColor: string,
  textColor: string,
  buttonColor: string,

}

export interface SS {
  id: any;
}

export default class HostSetupRssFeedController extends BlockComponent<
  Props,
  S,
  SS
> {
  scrollRef: any = createRef();

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      isLinkValid: true,
      step: 1,
      backgroundColor: '',
      secondaryColor: '',
      textColor: '',
      buttonColor: '',
    };
  }

  async componentDidMount() {
    // Customizable Area Start
    let feedName = await getStorageData('feedName');
    let storageKey = ResolveCondition((feedName === 'rss'), 'rssData', (ResolveCondition((feedName === 'noRss'), 'noRssData', '')))
    let storageData = storageKey ? (await getStorageData(storageKey)) : null;
    let data = storageData !== null ? JSON.parse(storageData) : {};
    let backgroundColor = data['backgroundColor'] || "";
    let secondaryColor = data['secondaryColor'] || "";
    let textColor = data['textColor'] || "";
    let buttonColor = data['buttonColor'] || "";
    this.setState({ backgroundColor, secondaryColor, textColor, buttonColor });
    setTimeout(() => {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
    // Customizable Area End
  }

  handleGoBack = () => {
    if (1 < this.state.step) {
      this.setState({ step: this.state.step - 1 })
    }
    else {
      this.props.navigation.goBack()
    }
  }
  handleColorChange = (color: { hex: string }) => {
    let step = this.state.step;
    if (step === 1) {
      this.setState({ backgroundColor: color.hex })
    }
    else if (step === 2) {
      this.setState({ secondaryColor: color.hex })
    }
    else if (step === 3) {
      this.setState({ textColor: color.hex })
    }
    else if (step === 4) {
      this.setState({ buttonColor: color.hex })
    }
  }
  handleColorManually = (event: { target: { value: string; } } | React.ChangeEvent<HTMLInputElement>) => {
    const color = event.target.value;
    const step = this.state.step;

    switch (step) {
      case 1:
        this.setState({ backgroundColor: color });
        break;
      case 2:
        this.setState({ secondaryColor: color });
        break;
      case 3:
        this.setState({ textColor: color });
        break;
      case 4:
        this.setState({ buttonColor: color });
        break;
      default:
        break;
    }
  };
  handleContinue = async () => {
    let step = this.state.step;
    if (step < 4) {
      this.setState({ step: step + 1 });
    }
    else if (step === 4) {
      let feedName = await getStorageData('feedName');
      if (feedName === 'rss' || feedName === 'noRss') {
        await this.updateFeedData(feedName);
      }
      this.props.handleCommonPath("TemplatePreviewWithLogo")
    }
  }

  updateFeedData = async (feedName: string) => {
    let storageKey = ResolveCondition((feedName === 'rss'), 'rssData', (ResolveCondition((feedName === 'noRss'), 'noRssData', '')))
    let storageData = storageKey ? (await getStorageData(storageKey)) : null;
    let data = storageData !== null ? JSON.parse(storageData) : {};
    data['backgroundColor'] = this.state.backgroundColor;
    data['secondaryColor'] = this.state.secondaryColor;
    data['textColor'] = this.state.textColor;
    data['buttonColor'] = this.state.buttonColor;
    storageKey && setStorageData(storageKey, JSON.stringify(data));
  }

  isButtonDisabled = () => {
    if (this.state.step === 1 && !this.state.backgroundColor) return true;
    if (this.state.step === 2 && !this.state.secondaryColor) return true;
    if (this.state.step === 3 && !this.state.textColor) return true;
    if (this.state.step === 4 && !this.state.buttonColor) return true;
  }

  resolveStepColor = (step: number, state: { 
    backgroundColor: string; 
    secondaryColor: string; 
    textColor: string; 
    buttonColor: string; 
  }): string => {
    switch (step) {
      case 1:
        return ResolveCondition(
          state.backgroundColor !== "",
          state.backgroundColor,
          "#D9D9D9"
        ) || "#D9D9D9";
      case 2:
        return ResolveCondition(
          state.secondaryColor !== "",
          state.secondaryColor,
          "#D9D9D9"
        ) || "#D9D9D9";
      case 3:
        return ResolveCondition(
          state.textColor !== "",
          state.textColor,
          "#D9D9D9"
        ) || "#D9D9D9";
      case 4:
        return ResolveCondition(
          state.buttonColor !== "",
          state.buttonColor,
          "#D9D9D9"
        ) || "#D9D9D9";
      default:
        return "#D9D9D9";
    }
  };
  
  


}
// Customizable Area End
