// Customizable Area Start
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { handleNavigation, ResolveCondition } from "../../utilities/src/CustomHelpers";
import { createRef } from "react";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath: (path:string) => void;
}

export interface S {
  isLinkValid: boolean,
  selectedTemplate: string
}

export interface SS {
  id: any;
}
export default class ChooseTemplateController extends BlockComponent<
  Props,
  S,
  SS
> {
  fetchRssFeedCallId: string = "";
  scrollRef: any = createRef();
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      isLinkValid: true,
      selectedTemplate: ''
    };
  }

  async componentDidMount() {
    // Customizable Area Start
    let feedName = await getStorageData('feedName');
    let storageKey = ResolveCondition((feedName === 'rss'), 'rssData', (ResolveCondition((feedName === 'noRss'), 'noRssData', '')))
    let storageData =  storageKey ? (await getStorageData(storageKey)) : null;
    let data = storageData !== null ? JSON.parse(storageData) : {};
    let choosedTemplate = data['selectedTemplate'];
    this.setState({selectedTemplate: choosedTemplate});
    setTimeout(() => {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
    // Customizable Area End
  }

  handleGoBack = () => {
    this.props.navigation.goBack()
  }
  handleTemplateClick = (template: string) => {
    this.setState({ selectedTemplate: template });
  };
  handleContinue = async () => {
    let feedName = await getStorageData('feedName');
    if (feedName === 'rss' || feedName === 'noRss') {
      await this.updateStorageData(feedName);
    }
    this.props.handleCommonPath("TemplateColorScheme")
  }
  
  updateStorageData = async (feedName:string) => {
    let storageKey = ResolveCondition((feedName === 'rss'), 'rssData', (ResolveCondition((feedName === 'noRss'), 'noRssData', '')))
    let storageData =  storageKey ? (await getStorageData(storageKey)) : null;
    let data = storageData !== null ? JSON.parse(storageData) : {};
    
    data['selectedTemplate'] = this.state.selectedTemplate;
  
    storageKey && (setStorageData(storageKey, JSON.stringify(data)));
  }

}
// Customizable Area End
