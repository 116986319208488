import React from "react";
// Customizable Area Start
import {
    Typography,
    IconButton,
    Box,
    Grid,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Footer from "../../../navigationmenu/src/Footer.web";

import TrendingPodcastController, {
    Props,
    TrendingPodcastDetail,
} from "./TrendingPodcastController.web";
// Customizable Area End

export default class TrendingPodcast extends TrendingPodcastController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
        return (
            <div style={webStyles.container}  ref={this.scrollReff}>
                <Box sx={{ display: "flex" }} >
                    <IconButton
                        sx={{
                            width: "40px",
                            height: "40px",
                            paddingLeft: "15px",
                            borderRadius: "8px",
                            color: "#F5F5F5",
                            background: "#44403C",
                            "&:hover": {
                                background: "#44403C",
                            },
                            marginRight: "-30px"
                        }}
                        data-test-id="backBtn"
                        onClick={() => this.props.handleCommonPath("SubCategories")}
                    >
                        <ArrowBackIosIcon />
                    </IconButton>
                    <Box sx={webStyles.containerTitle}>Discover</Box>
                </Box>

                <Box sx={webStyles.tredingEpisodesBox} data-test-id="trendingPodcast">
                    <Typography style={webStyles.title}>Trending Podcast</Typography>
                    <Grid container spacing={3}>
                        {this.state.trendingPodcast.map((item: TrendingPodcastDetail) => (
                            <Grid item lg={2.4} md={2.4} sm={4} xs={12} key={item.id}>
                                <div style={webStyles.suggestedImgBody}>
                                    <Box sx={{ ...webStyles.image, height: "223px" }}>
                                        <Box
                                            sx={{
                                                ...webStyles.followingText,
                                                backgroundColor: item.is_follow ? "#030303" : "#FF0807", cursor: "pointer"
                                            }}>
                                            <Typography style={{ justifySelf: "flex-end", fontWeight: "bold" }}>{item.is_follow ? "Following" : "Follow"}</Typography>
                                        </Box>
                                        <img style={{ ...webStyles.image, height: "223px" }} src={item.src} alt={item.name} />
                                    </Box>
                                    <div style={webStyles.description}>
                                        <Box sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            height: "76px",
                                            padding: "5px 16px",
                                        }}>
                                            <Box>
                                                <Typography style={{ ...webStyles.episode, fontWeight: 900 }}>
                                                    {item.name.toUpperCase()}
                                                </Typography>
                                                <Typography style={{ ...webStyles.episode, fontWeight: 900 }}>
                                                    {item.artist}
                                                </Typography>
                                            </Box>
                                            <Typography style={{ ...webStyles.episode, fontWeight: 900, fontSize: "24px" }}>
                                                #<span style={{ fontSize: "32px" }}>{item.id}</span>
                                            </Typography>
                                        </Box>
                                    </div>
                                </div>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Footer navigation={this.props.navigation} id={"1"} />
            </div>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
const webStyles = {
    container: {
        maxHeight: "100%",
        overflowX: "hidden" as const,
        padding: "20px 50px",
        overFlowY: "auto",
    },
    img: {
        height: "40%",
        width: "40%",
    },
    tredingEpisodesBox: {
        position: 'relative',
        paddingTop: "40px",
    },
    containerTitle: {
        fontWeight: 900,
        fontSize: "30px",
        lineHeight: "40px",
        letter: "-0.5%",
        color: "#fff",
        marginBottom: "24px",
        fontFamily: "Nexa-Heavy",
        marginLeft: "50px",
    },
    title: {
        fontWeight: 900,
        fontSize: "30px",
        color: '#fff',
        marginBottom: "10px",
        fontFamily: "Nexa-Heavy",
    },
    image: {
        objectFit: "cover" as const,
        borderRadius: "8px 8px 0 0",
        width: '100%',
        height: typeof window !== 'undefined' && window.innerWidth > 1440 ? "397px" : "370px",
        display: "flex",
        margin: "0 auto",
    },
    episode: {
        fontSize: '14px',
        color: "#fff",
        lineHeight: '22px',
        fontWeight: 600,
        fontFamily: "Nexa-Heavy",
    },
    suggestedImgBody: {
        width: '270px',
        position: 'relative' as const,
    },
    description: {
        backgroundColor: "#44403c",
        borderBottomLeftRadius: "16px",
        borderBottomRightRadius: "16px",
    },
    followingText: {
        top: "15px",
        right: "15px",
        position: "absolute",
        color: "white",
        borderRadius: "8px",
        padding: "3px 8px",
    }
};
// Customizable Area End
