// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from '../../../framework/src/Message';
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { handleNavigation } from "../../utilities/src/CustomHelpers";
import { createRef } from "react";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath: (path:string) => void;
}

export interface Data
  {
    attributes: {
        name: string,
        image: any
    }
}
export interface S {
  data : any;
  monetizationCategories : string[];  
  categoryId:any;
  catflag:boolean;
  monetizationData:any;
  selectedMonetizationIds: any,
  monetization_id : string
}

export interface SS {
  id: any;
}

export default class HostMonetizationSetupController extends BlockComponent<
  Props,
  S,
  SS
> {
  
  memoizationCategoriesApiCallId = "";
  createMonitizationCallId="";
  getmemoizationDataApiCallId = "";
  updateMonitizationCallId="";
  scrollRef: any = createRef();
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
   data : [],
   monetizationCategories : [],
   categoryId:[],
   catflag:true,
   monetizationData:{},
   selectedMonetizationIds: [],
   monetization_id : ""
    };
  }

  handleItemClick = async (item:any) => {
    const { selectedMonetizationIds, categoryId } = this.state;
    const itemId = String(item?.id);

    if (selectedMonetizationIds.map(String).includes(itemId)) {       
        this.setState({          
            selectedMonetizationIds: selectedMonetizationIds.filter(
                (id:any) => String(id) !== itemId
            ),
            categoryId: categoryId.filter((id:any) => String(id) !== itemId),
        });
    } else {
        this.setState({
            selectedMonetizationIds: [...selectedMonetizationIds, itemId],
            categoryId: [...categoryId, itemId],
        });
    }
};

  async receive(from: string, message: Message) {
    if (this.memoizationCategoriesApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForFetchSubsApi(message);
    }
   else if (this.getmemoizationDataApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForMonetizationData(message);
    }
   else if (this.createMonitizationCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {    
    this.handleResForCreateMonetizationData(message);
    }
    else if (this.updateMonitizationCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {    
      this.handleResForUpdateMonetizationData(message);
      }
    
  }

  async componentDidMount() {
    let catagory = await getStorageData("monetizationIds")
    if (JSON.parse(catagory)?.length > 0) {
      this.setState({ catflag: false })
    }
   
    this.memoizationCategories()
    await this.getmemoizationData()
    setTimeout(() => {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
  }

  memoizationCategories =async () => {     
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };
       
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.memoizationCategoriesApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.monetizationCategories
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getmemoizationData =async () => {     
    let token = await getStorageData("token");
    let prodcastId = await getStorageData("podcast_id")
    const header = {
      token: JSON.parse(token),
    };
       
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getmemoizationDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getmonetizationData}?podcast_id=${prodcastId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );   
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  createMonitization = async () => {
  let token = await getStorageData("token");
  let prodcastId = await getStorageData("podcast_id")
    
  const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: JSON.parse(token),
  };
  const body = {
    "monetization": {
      "podcast_id": prodcastId,
      "monetization_category_ids": this.state.selectedMonetizationIds
    }
  };

  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.createMonitizationCallId = requestMessage.messageId;
  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createMonitization
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
  );
  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateMonitization = async () => {
  let token = await getStorageData("token");
  let prodcastId = await getStorageData("podcast_id")

  const header = {
    "Content-Type": configJSON.validationApiContentType,
    token: JSON.parse(token),
  };
  const body = {
  "monetization": {
    "id" : this.state.monetization_id,
    "podcast_id": prodcastId,
    "monetization_category_ids": this.state.selectedMonetizationIds
  }
  };

  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.updateMonitizationCallId = requestMessage.messageId;
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.updateMonitization}/${this.state.monetization_id}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.patchAPiMethod
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResForFetchSubsApi = (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const categoryIds = responseJson?.data.map((item:any) => item.id);
    if (responseJson.data) {
      this.setState({ data: responseJson.data , categoryId:categoryIds});
    }
  };

  handleResForUpdateMonetizationData = (message: Message) =>{
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));  
    if (responseJson.data) {
     this.handleDataNavigations();
    }
  }
  
  handleResForMonetizationData = (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));  
    if (responseJson.data) {
      this.setState({ monetizationData: responseJson.data.attributes,
        selectedMonetizationIds :  responseJson.data.attributes.monetization_category_ids || [],
        monetization_id : responseJson.data.id
       });              
    }
  }; 
  
  handleResForCreateMonetizationData = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage)); 
     
    if (responseJson.data) {        
     await setStorageData("monetization_id",responseJson.data.id)  
     await this.handleDataNavigations();
    }
  };

  handleContinue = async() =>{
    if(this.state.catflag){
      await this.createMonitization()
     }
     if(!this.state.catflag){
      await  this.updateMonitization()
     }
  }

  handleDataNavigations = async() => {
    const routeData = this.state.data.filter((item:any) => this.state.selectedMonetizationIds.map(String).includes(item.id)).map((item:any)=> item.attributes.name)
    await setStorageData("monetizationCategories", JSON.stringify(routeData));
    await setStorageData("monetizationIds", JSON.stringify(this.state.selectedMonetizationIds));
    
    const categories = routeData;
    const navigationMap : { [key: string]: string } = {
        "Sponsored Content and Partnership": "SponsoredContentPartnership",
        "Membership/Subscription Model": "HostMonetizationChooseTemplate",
        "Selling Digital Products": "HostMonetizationSellingDigitalProducts",
        "Offering Consulting or Coaching Services": "HostMonetizationOfferingConsultancy",
        "Affiliate Marketing": "HostMonetizationAffiliateMarketing",
        "Hosting Paid Webinars or Workshops": "HostMonetizationPaidWebinar",
        "Merchandise Sales": "HostMonetizationMerchandise",
        "Online Advertising": "HostMonetizationOnlineAdvertising",
        "Crowdfunding or Resident Support": "HostCrowfundingResidentSupport"
    };   
    
    if (categories.length > 0) {
      const keys = Object.keys(navigationMap);
      const sortedCategories = categories.slice().sort((a:any, b:any) => keys.indexOf(a) - keys.indexOf(b));
  
  let navigated = false;
  for (let category of sortedCategories) {
    const navigationRoute = navigationMap[category];
    if (navigationRoute && !navigated) {
      navigated = true;
      // handleNavigation(navigationRoute, this.props);
      this.props.handleCommonPath(navigationRoute)
    }
  }
    }}
    

}
// Customizable Area End
