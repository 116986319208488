import React from 'react';
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import BuildController, { Props } from "./BuildController.web";
import {
    Box,
    styled,
    Button,
    Typography,
} from "@mui/material";
import { handImg, womenImg } from './assets';

const MIN_WINDOW_HEIGHT = 900;

export default class ChannelManagement extends BuildController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { channelActiveImg } = this.state;

        return (
            <>
                <Box style={{ maxHeight: "100%", overflowY: "auto" }} ref={this.scrollRef}>
                    <StyledWrapper>
                        <Typography 
                            data-test-id="title"
                            fontFamily="Nexa-Heavy" 
                            paddingBottom={2} 
                            fontSize="30px" 
                            color="white"
                        >
                            Channel Management
                        </Typography>
                        <Box>
                            <Box
                                height="60px"
                                bgcolor="red"
                                sx={{
                                    borderTopLeftRadius: "10px",
                                    borderTopRightRadius: "10px",
                                }}
                            />
                            <Box sx={webStyles.containerBox}>
                                <Box display="flex" gap={3}>
                                    {[handImg, womenImg].map((img, index) => {
                                        const imageId = `image${index + 1}`;

                                        return (
                                            <Box
                                                key={imageId}
                                                style={{
                                                    width: "320px",
                                                    borderRadius: "5px",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <img 
                                                    src={img} 
                                                    style={{
                                                        ...webStyles.imgContainer, 
                                                        border: channelActiveImg === imageId ? "5px solid #FF0807" : "",
                                                        backgroundColor: channelActiveImg === imageId ? "#FF0807" : "",
                                                    }} 
                                                    onClick={() => this.handleChannelClick(imageId)} 
                                                />
                                                <Box p={2}>
                                                    <Button 
                                                        data-test-id="image-button"
                                                        variant="contained" 
                                                        style={webStyles.button} 
                                                        size="small"
                                                    >
                                                        {index === 0 ? "Manage my tiers" : "Channel Template/Colors"}
                                                    </Button>
                                                </Box>
                                            </Box>
                                        );
                                    })}
                                </Box>
                                <Button 
                                    data-test-id="continue-button"
                                    variant="contained" 
                                    style={{
                                        ...webStyles.continueBtn, 
                                        backgroundColor: channelActiveImg ? "#FF0807" : "#B2B3B3" 
                                    }} 
                                    size="small"
                                >
                                    Continue
                                </Button>
                            </Box>
                        </Box>
                    </StyledWrapper>
                    <Footer navigation={this.props.navigation} id="1" />   
                </Box>
            </>
        );
    }
}

const StyledWrapper = styled("div")({
    background: "black",
    padding:"20px 50px",
    "& *": {
        boxSizing: "border-box",
    },
});

const webStyles = {
    container: {
        height: typeof window !== 'undefined' && window.innerHeight < MIN_WINDOW_HEIGHT ? "550px" : "830px",
        overflowY: "scroll" as const,
        scrollbarWidth: "thin",
        scrollbarColor: "red gray",
        "&::-webkit-scrollbar": {
            width: "12px",
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: "red",
            borderRadius: "10px",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "gray",
        },
        "&::-webkit-scrollbar-button": {
            display: "none",
        },
    } as const,
    containerBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "#44403C",
        color: "white",
        padding: "72px 48px 80px 48px",
    },
    imgContainer: {
        width: "320px", 
        height: "220px", 
        objectFit: "contain",
        borderTopLeftRadius: "16px",
        borderTopRightRadius: "16px",
    } as const,
    button: {
        backgroundColor: "#FF0807",
        padding: "6px 60px",
        textTransform: "none",
        fontFamily: "Nexa-Heavy",
        lineHeight: "23.9px",
        fontSize: "1rem",
        width: "268px",
        height: "36px",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        borderRadius: "8px"
    } as const,
    continueBtn: {
        textTransform: "none",
        padding: "8px 16.5rem",
        borderRadius: "8px",
        fontFamily: "Nexa-Heavy",
        lineHeight: "26px",
        fontSize: "0.75rem",
        paragraph: "12px",
        marginTop:"24px"
    } as const,
};
