// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from '../../../framework/src/Message';
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { createRef } from "react";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath:(path:string) => void;
  handleBuild:(path:string) => void;
}

export interface S {
  isModalOpen: boolean;
  getApiData: any;
  editFlags: any;
  editablePrice: any;
  dummy_data: any;
}

export interface SS {
  id: any;
}

export default class HostCrowfundingResidentSupportController extends BlockComponent<
  Props,
  S,
  SS
> {

  getmemoizationDataCrowfundingApiCallId = "";
  updateMonitizationCrowFundingResidentCallId = "";
  deleteMonitizationResidentSupportCallId = "";
  scrollRef: any = createRef();
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      isModalOpen: false,
      getApiData: [],
      editFlags: [false, false, false],
      editablePrice: ["", "", ""],
      dummy_data: [
        {
          id: null,
          amount: "10"
        },
        {
          id: null,
          amount: "20"
        },
        {
          id: null,
          amount: "30"
        }
      ]
    };
  }


  async receive(from: string, message: Message) {
    if (this.getmemoizationDataCrowfundingApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForMonetizationData(message);
    }
    if (this.updateMonitizationCrowFundingResidentCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForUpdateMonetizationData(message);
    }
    if (this.deleteMonitizationResidentSupportCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForDeleteMonetizationResidentSupport(message);
    }

  }

  async componentDidMount() {
    this.getmemoizationCrowFundingData()
    setTimeout(() => {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
  }

  getmemoizationCrowFundingData = async () => {
    let token = await getStorageData("token");
    let prodcastId = await getStorageData("podcast_id")
    const header = {
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getmemoizationDataCrowfundingApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getmonetizationData}?podcast_id=${prodcastId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateCrowFundingMonitization = async (tipsAttributes: any) => {
    try {
      const token = await getStorageData("token");
      const monetizationId = await getStorageData("monetization_id");

      const headers = {
        "Content-Type": configJSON.validationApiContentType,
        token: JSON.parse(token),
      };

      const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
      this.updateMonitizationCrowFundingResidentCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.updateMonitization}/${monetizationId}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(tipsAttributes)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.patchAPiMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);

      console.log("Monetization data submitted:", tipsAttributes);
    } catch (error) {
      console.log("Error updating monetization data:", error);
    }
  };

  handlePriceChange = (index: number, value: string) => {
    const editablePrice = [...this.state.editablePrice];
    editablePrice[index] = value.replace("$", "");
    this.setState({ editablePrice });
  };

  getCombinedData = () => {
    const apiData = this.state.getApiData.slice(0, 3);
    const remainingSlots = 3 - apiData.length;
    const dummyData = this.state.dummy_data.slice(0, remainingSlots);

    return [...apiData, ...dummyData];
  };


  handleEdit = (id: any, index: number) => {
    const editFlags = [...this.state.editFlags];
    const editablePrice = [...this.state.editablePrice];
    editFlags[index] = !editFlags[index];
    if (editFlags[index]) {
      const data = this.getCombinedData()[index];
      editablePrice[index] = data?.amount || "";
    }
    this.setState({ editFlags, editablePrice });
  };


  handleSave = async () => {
    let prodcastId = await getStorageData("podcast_id")
    const monetizationId = await getStorageData("monetization_id");

    const editedTipIndex = this.state.editFlags.findIndex((flag: any) => flag === true);

    if (editedTipIndex !== -1) {
      const updatedTip = {
        amount: this.state.editablePrice[editedTipIndex],
        id: this.state.getApiData[editedTipIndex]?.id,
        podcast_id: prodcastId
      };

      const requestData = {
        monetization: {
          id: monetizationId,
          podcast_id: prodcastId,
          tips_attributes: [updatedTip],
        },
      };

      this.updateCrowFundingMonitization(requestData);
    }
  };

  handleChange = (e: any, index: number) => {
    const { value } = e.target;

    const updatedData = [...this.state.getApiData];
    updatedData[index].amount = value.replace(/^\$/, "");

    const updatedPrices = [...this.state.editablePrice];
    updatedPrices[index] = value.replace(/^\$/, "");

    this.setState({
      getApiData: updatedData,
      editablePrice: updatedPrices,
    });
  };


  handleResForMonetizationData = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson.data) {
      this.setState({
        getApiData: responseJson.data.attributes.tips,
        editFlags: Array(responseJson.data.attributes.tips.length).fill(false),
        editablePrice: responseJson.data.attributes.tips[0].amount
      })
    }
  };

  handleResForUpdateMonetizationData = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson.data) {
      this.getmemoizationCrowFundingData();
    }
  };

  deleteMonetizationResidentSupport = async () => {
    let token = await getStorageData("token");
    let monetization_id = await getStorageData("monetization_id")
    const header = {
      token: JSON.parse(token),
    };

    const getMonetizationrequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteMonitizationResidentSupportCallId = getMonetizationrequest.messageId;
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteMonetize}/${monetization_id}`
    );
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );
    runEngine.sendMessage(getMonetizationrequest.id, getMonetizationrequest);
  };

  handleResForDeleteMonetizationResidentSupport = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));  
    if (responseJson.message) {
      removeStorageData("monetization_id")
      removeStorageData("monetizationIds")
      removeStorageData("monetizationCategories")
     this.props.handleCommonPath("HostMonetizationSetup")          
    }
  };
  handleRoutesResident = (route: string, type: string) => {
    const onlineAdvertising = "Online Advertising";
    const merchandiseSales = "Merchandise Sales";
    const paidWebinars = "Hosting Paid Webinars or Workshops";
    const affiliateMarketing = "Affiliate Marketing";
    const consultingServices = "Offering Consulting or Coaching Services";
    const digitalProducts = "Selling Digital Products";
    const membershipModel = "Membership/Subscription Model";
    const sponsoredContent = "Sponsored Content and Partnerships";

    if (route.includes(onlineAdvertising)) {
      this.props.handleCommonPath("HostMonetizationOnlineAdvertising");
    } else if (route.includes(merchandiseSales)) {
      this.props.handleCommonPath("HostMonetizationMerchandise");
    } else if (route.includes(paidWebinars)) {
      this.props.handleCommonPath("HostMonetizationPaidWebinar");
    } else if (route.includes(affiliateMarketing)) {
      this.props.handleCommonPath("HostMonetizationAffiliateMarketing");
    } else if (route.includes(consultingServices)) {
      this.props.handleCommonPath("HostMonetizationOfferingConsultancy");
    } else if (route.includes(digitalProducts)) {
      this.props.handleCommonPath("HostMonetizationSellingDigitalProducts");
    } else if (route.includes(membershipModel)) {
      this.props.handleCommonPath("HostMonetizationChooseTemplate");
    } else if (route.includes(sponsoredContent)) {
      this.props.handleCommonPath("SponsoredContentPartnership");
    } else {
      this.props.handleCommonPath("HostMonetizationSetup");
    }
  };

  handleCloseModal = () => {
    this.setState({ isModalOpen: false });
  };

}
// Customizable Area End