// Customizable Area Start
import React from "react";
import {
  Box,
  styled,
} from "@mui/material";
import Slider from "react-slick";
import SearchController, { Props } from "./SearchController";
import { goBack, image_building1 } from "../../email-account-login/src/assets";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import {  search2, search5 } from "./assets";
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined';
import ShareIcon from '@mui/icons-material/Share';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import ImageCard from "../../audiolibrary/src/components/ImageCard.web";
import Footer from "../../navigationmenu/src/Footer.web";
import { getStorageData } from "../../../framework/src/Utilities";
import { ResolveCondition } from "../../utilities/src/CustomHelpers";
import { ConditionJsx } from "../../utilities/src/ConditionJsx";

export default class AllSearchList extends SearchController {
  constructor(props: Props) {
    super(props);
  }
  render() {

    return (
      <>
        <StyledWrapperAllSearchList>
          <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box className="mainTitle" id="sponsoredContent" style={{ color: "white", display: "flex", alignItems: "center", marginTop: "10px", marginBottom: "10px", marginLeft: "3%", fontFamily: "Nexa-Heavy", fontSize: "30px", fontWeight: 900 }}>
              <img
                src={goBack}
                alt="goBack"
                style={{ marginRight: "20px", cursor: "pointer", color: "white" }}
                data-test-id="navigate"
                onClick={async () => {
                  let prevPath = await getStorageData("previousAllSearchResult")
                  this.props.handleCommonPath(prevPath)
                }
                }
              />
              All Search Results
            </Box>
            <Box style={{ display: "flex", alignItems: "center", color: "white", marginRight: "3%", width: "25%" }}>
              <div style={{ paddingRight: "3%" }}>Filter by:</div>
              <Stack direction="row" spacing={1} >
                <Chip label="Channel"
                  className={ResolveCondition(this.state.filterCategory.length == 7, "filterCategory filteredCategories", "filterCategory")}
                  onClick={() => { this.setState({ filterCategory: "channel" }) }} />
                <Chip label="Episode"
                  className={ResolveCondition(this.state.filterCategory.length == 8, "filterCategory filteredCategories", "filterCategory")}
                  onClick={() => { this.setState({ filterCategory: "episodes" }) }} />
                <Chip label="Post"
                  className={ResolveCondition(this.state.filterCategory.length == 5, "filterCategory filteredCategories", "filterCategory")}
                  onClick={() => { this.setState({ filterCategory: "posts" }) }} />
              </Stack>
            </Box>
          </Box>
          {ConditionJsx(this.state.filterCategory.length == 0, <>
            <Box style={{ color: "white", width: "90%", margin: "auto", marginTop: "36px", fontFamily: "Nexa-Heavy", fontWeight: 900, fontSize: "30px" }}>
              <div>Channel</div>
            </Box>
            <Box style={{ width: "90%", margin: "auto", marginTop: "3%", height: "379px" }}>
              <StyledSlider {...this.getSuggestedSlickSettings()}>

                <div key={1}>
                  <div style={webStyles.suggestedImgBody}>
                    <img style={{ ...webStyles.image, height: "223px" }} src={image_building1} />
                    <div style={webStyles.description}>
                      <Box style={{ ...webStyles.episode, fontWeight: 900 }}>
                        Episode1
                      </Box>
                    </div>
                  </div>
                </div>           
              </StyledSlider>
            </Box>

            <Box style={{ color: "white", width: "90%", margin: "auto", fontFamily: "Nexa-Heavy", fontWeight: 900, fontSize: "30px" }}>
              <div>Episodes</div>
            </Box>
            <Box style={{ width: "90%", margin: "auto", marginTop: "3%" }}>
              <StyledSlider {...this.getSuggestedSlickSettings()}>
                <div key={1} id="episode">
                  <div 
                  style={webStyles.suggestedImgBody}>
                    <img style={{ ...webStyles.image, height: "223px" }} src={image_building1} />
                    <div id="episode" style={webStyles.description}>
                      <Box style={{ ...webStyles.episode, fontWeight: 900 }}>
                        Episodes
                      </Box>
                    </div>
                  </div>
                </div>           
              </StyledSlider>
            </Box>


            <Box style={{ color: "white", width: "90%", margin: "auto", fontFamily: "Nexa-Heavy", fontWeight: 900, fontSize: "30px" }}>
              <div>Posts</div>
            </Box>
            <Box style={{ width: "90%", margin: "auto", marginTop: "3%" }}>
              <StyledSlider {...this.getSuggestedSlickSettings1()}>
                <div key={1} id = "posts" style={{ display: "flex" }}>
                  <div id = "posts" style={{ display: "flex", background: "#312F2D", borderRadius: "20px", justifyContent: "center", marginBottom: "30px", flexDirection: "column" }}>
                    <Box id = "posts" style={{ height: "auto", width: "90%", marginTop: "41px", margin: "auto" }}>
                      <div style={{ marginTop: "50px", display: "flex", justifyContent: "space-between" }}>
                        <div id = "posts" style={{ height: "56px", width: "59px" }}>
                          <img src={search5} alt="search" />
                        </div>
                        <div id = "posts" style={{ color: "white", height: "20%", width: "92%" }}>
                          <div style={{ fontFamily: "Nexa-Heavy", fontWeight: 900, fontSize: "14px" }}>Laura Zuluaga</div>
                          <div style={{ marginTop: "20px", fontFamily: "Nexa-Regular", fontSize: "14px", fontWeight: 100 }}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis .</div>
                        </div>
                      </div>
                    </Box>
                    <div id = "posts" style={{ margin: "auto" }}>
                      <img src={image_building1} alt="post" style={{ width: "766px", height: "308px", borderRadius: "43px", marginTop: "30px" }} />
                    </div>
                    <div id = "posts" style={{ display: "flex", marginTop: "50px", color: "white", width: "30%", marginBottom: "50px", marginLeft: "11%", justifyContent: "space-around" }}>
                      <div style={{ fontFamily: "Nexa-Heavy", fontSize: "14px" }}>9/10/24</div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <ThumbUpAltOutlinedIcon
                          style={{ color: 'white', fontSize: 25, cursor: "pointer", marginRight: "10px" }}

                        />
                        <span style={{ fontFamily: "Nexa-Heavy", fontSize: "14px" }}>1.3k</span>
                      </div>
                      <div id = "posts" style={{ display: "flex", alignItems: "center" }}>
                        <ChatBubbleOutlineIcon
                          style={{ color: 'white', fontSize: 25, cursor: "pointer", marginRight: "10px" }}
                        />
                        <span style={{ fontFamily: "Nexa-Heavy", fontSize: "14px" }}>984</span>
                      </div>
                      <div id = "posts" style={{ display: "flex", alignItems: "center" }}>
                        <ShareIcon
                          style={{ color: 'white', fontSize: 25, cursor: "pointer", marginRight: "10px" }}
                        />
                        <span style={{ fontFamily: "Nexa-Heavy", fontSize: "14px" }}>984</span>
                      </div>
                    </div>
                  </div>
                </div>
              </StyledSlider>
            </Box>
          </>, <></>)
          }

          {ConditionJsx(this.state.filterCategory.length == 7, (
            <Box style={{ margin: "auto", width: "95%" }}>

              <Box style={{ color: "white", marginTop: "36px", marginBottom: "30px", fontFamily: "Nexa-Heavy", fontWeight: 900, fontSize: "30px" }}>
                <div>Channel</div>
              </Box>
              <Box display={"flex"} gap={3} flexWrap={"wrap"} justifyContent={"flex-start"} alignItems={"center"}>
                {
                  this.state.filteredChannels.map((data: any, index: number) => (
                    <ImageCard
                      key={index}
                      imageUrl={data.imageUrl}
                      songName={data.songName}
                      artist={data.artist}
                      following={data.following}
                    />
                  ))
                }
              </Box>
            </Box>
          ), <></>)

          }

          {ConditionJsx(this.state.filterCategory.length == 8, (
            <Box style={{ margin: "auto", width: "95%" }}>

              <Box style={{ color: "white", marginTop: "36px", marginBottom: "30px", fontFamily: "Nexa-Heavy", fontWeight: 900, fontSize: "30px" }}>
                <div>Channel</div>
              </Box>
              <Box display={"flex"} gap={3} flexWrap={"wrap"} justifyContent={"flex-start"} alignItems={"center"}>
                {
                  this.state.filteredChannels.map((data: any, index: number) => (
                    <ImageCard
                      key={index}
                      imageUrl={search2}
                      songName={data.songName}
                      artist={data.artist}
                      following={data.following}
                    />
                  ))
                }
              </Box>
            </Box>
          ), <></>)
          }
          {
            ConditionJsx(this.state.filterCategory.length == 5, (
              <>
                <Box style={{ color: "white", width: "90%", margin: "auto", marginTop: "36px", marginBottom: "30px", fontFamily: "Nexa-Heavy", fontWeight: 900, fontSize: "30px" }}>
                  <div>Posts</div>
                </Box>
                <div key={1} style={{ margin: "auto", width: "90%" }}>
                  <div style={{ display: "flex", background: "#312F2D", borderRadius: "20px", justifyContent: "center", marginBottom: "30px", flexDirection: "column" }}>
                    <Box style={{ height: "auto", width: "90%", marginTop: "41px", margin: "auto" }}>
                      <div style={{ marginTop: "50px", display: "flex", justifyContent: "space-between" }}>
                        <div style={{ height: "56px", width: "59px" }}>
                          <img src={search5} alt="search" />
                        </div>
                        <div style={{ color: "white", height: "20%", width: "92%" }}>
                          <div style={{ fontFamily: "Nexa-Heavy", fontWeight: 900, fontSize: "14px" }}>Laura Zuluaga</div>
                          <div style={{ marginTop: "20px", fontFamily: "Nexa-Regular", fontSize: "14px", fontWeight: 100 }}>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis .</div>
                        </div>
                      </div>
                    </Box>
                    <div style={{ margin: "auto" }}>
                      <img src={image_building1} alt="post" style={{ width: "766px", height: "308px", borderRadius: "43px", marginTop: "30px" }} />
                    </div>
                    <div style={{ display: "flex", marginTop: "50px", color: "white", width: "30%", marginBottom: "50px", marginLeft: "11%", justifyContent: "space-around" }}>
                      <div style={{ fontFamily: "Nexa-Heavy", fontSize: "14px" }}>9/10/24</div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <ThumbUpAltOutlinedIcon
                          style={{ color: 'white', fontSize: 25, cursor: "pointer", marginRight: "10px" }}

                        />
                        <span style={{ fontFamily: "Nexa-Heavy", fontSize: "14px" }}>1.3k</span>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <ChatBubbleOutlineIcon
                          style={{ color: 'white', fontSize: 25, cursor: "pointer", marginRight: "10px" }}
                        />
                        <span style={{ fontFamily: "Nexa-Heavy", fontSize: "14px" }}>984</span>
                      </div>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <ShareIcon
                          style={{ color: 'white', fontSize: 25, cursor: "pointer", marginRight: "10px" }}
                        />
                        <span style={{ fontFamily: "Nexa-Heavy", fontSize: "14px" }}>984</span>
                      </div>

                    </div>
                  </div>
                </div>
              </>
            ), <></>)

          }
        </StyledWrapperAllSearchList>
        <Footer navigation={this.props.navigation} id={"1"} />
      </>
    )
  }
}

const StyledWrapperAllSearchList = styled('div')({
  "& *": {
    boxSizing: "border-box",
  },
  "& .filterCategory": {
    background: "white",
    color: "black",
    fontWeight: 900,
    fontSize: "14px",
    fontFamily: "Nexa-Heavy",
    height: "32px",
    width: "91px",
    "&:hover": {
      background: "white",
      color: "black",
    }
  },
  "& .filteredCategories": {
    background: "#FF0807",
    color: "white",
    "&:hover": {
      background: "#FF0807",
      color: "white",
    }
  }
})

const webStyles = {
  container: {
    padding: "20px 50px"
  } as const,
  image: {
    objectFit: "cover" as const,
    borderRadius: "8px",
    width: '100%',
    height: "307px",
    display: "flex",
    margin: "0 auto",
  },
  imgBody: {
    position: 'relative' as const,
  },
  sponsoredButton: {
    color: "#fff",
    backgroundColor: "#FF0807",
    padding: '10px 16px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '8px',
    fontWeight: 900,
    fontSize: "14px"
  },
  episode: {
    color: "#fff",
    fontSize: '14px',
    lineHeight: '22px',
    fontWeight: 600
  },
  sponsoredImgBody: {
    height: "370px",
    width: '100%',
    position: 'relative' as const
  },
  title: {
    fontWeight: 900,
    fontSize: "30px",
    color: '#fff',
    marginBottom: "10px"
  },

  suggestedImgBody: {
    width: '227px',
    height: "223px",
    position: 'relative' as const
  },
  postBody: {
    width: "100%",
    height: "25%",
    backgroundColor: "#312F2D",
    position: 'relative' as const
  },
  description: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    height: "76px",
    backgroundColor: "#44403c",
    borderBottomLeftRadius: "16px",
    borderBottomRightRadius: "16px",
    padding: "16px"
  } as React.CSSProperties,

};
const StyledSlider = styled(Slider)`
  .slick-dots li {
    margin: 0px !important;
  }
  .slick-dots ul {
    margin: 140px !important;
  }
  
`;
// Customizable Area End