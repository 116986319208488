// Customizable Area Start
import { createRef } from 'react';
import { BlockComponent } from '../../../../framework/src/BlockComponent';
import { IBlock } from '../../../../framework/src/IBlock';
import MessageEnum, { getName } from '../../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../../framework/src/RunEngine';
import { business } from '../assets';
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    handleCommonPath: (path: string) => void;
    // Customizable Area End
};

// Customizable Area Start
export interface TrendingPodcastDetail {
    id: number;
    src: any;
    artist: string;
    name: string;
    is_follow: boolean;
};
interface S {
    trendingPodcast: TrendingPodcastDetail[],
};
// Customizable Area End

interface SS {
    id: any;
};

export default class TrendingPodcastController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getTrendingPodcastApiCallId: any;
    scrollReff: any = createRef();
    // Customizable Area End
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        // Customizable Area End

        this.state = {
            // Customizable Area Start
            trendingPodcast: [],
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        super.componentDidMount();
        this.fetchTrendingPodcast();
        this.setState({
            trendingPodcast: [
                { id: 1, src: business, artist: "The Allusionist", name: "BUSINESS PODCAST", is_follow: false },
                { id: 2, src: business, artist: "The Allusionist", name: "BUSINESS PODCAST", is_follow: true },
                { id: 3, src: business, artist: "The Allusionist", name: "BUSINESS PODCAST", is_follow: false },
                { id: 4, src: business, artist: "The Allusionist", name: "BUSINESS PODCAST", is_follow: true },
                { id: 5, src: business, artist: "The Allusionist", name: "BUSINESS PODCAST", is_follow: false },
                { id: 6, src: business, artist: "The Allusionist", name: "BUSINESS PODCAST", is_follow: false },
                { id: 7, src: business, artist: "The Allusionist", name: "BUSINESS PODCAST", is_follow: true },
                { id: 8, src: business, artist: "The Allusionist", name: "BUSINESS PODCAST", is_follow: false },
                { id: 9, src: business, artist: "The Allusionist", name: "BUSINESS PODCAST", is_follow: true },
                { id: 10, src: business, artist: "The Allusionist", name: "BUSINESS PODCAST", is_follow: false },
                { id: 11, src: business, artist: "The Allusionist", name: "BUSINESS PODCAST", is_follow: false },
                { id: 12, src: business, artist: "The Allusionist", name: "BUSINESS PODCAST", is_follow: true },
                { id: 13, src: business, artist: "The Allusionist", name: "BUSINESS PODCAST", is_follow: false },
                { id: 14, src: business, artist: "The Allusionist", name: "BUSINESS PODCAST", is_follow: true },
                { id: 15, src: business, artist: "The Allusionist", name: "BUSINESS PODCAST", is_follow: false },
                { id: 16, src: business, artist: "The Allusionist", name: "BUSINESS PODCAST", is_follow: false },
                { id: 17, src: business, artist: "The Allusionist", name: "BUSINESS PODCAST", is_follow: true },
                { id: 18, src: business, artist: "The Allusionist", name: "BUSINESS PODCAST", is_follow: false },
                { id: 19, src: business, artist: "The Allusionist", name: "BUSINESS PODCAST", is_follow: true },
                { id: 20, src: business, artist: "The Allusionist", name: "BUSINESS PODCAST", is_follow: false },
            ],
        });
        setTimeout(() => {
            if (this.scrollReff.current) {
              this.scrollReff.current.scrollTo({ top: 0, behavior: "smooth" });
            }
        }, 0);
    }

    fetchTrendingPodcast = () => { };
    // Customizable Area End
}
