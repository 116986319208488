// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { createRef } from "react";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath: (path:string) => void;
}

export interface S {
  checkTick : boolean
}

export interface SS {
  id: any;
}

export default class HostSetupMethodController extends BlockComponent<
  Props,
  S,
  SS
> {
  scrollReff: any = createRef();

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      checkTick :false
    };
  }

  openLink = (url: string) => {
    if (typeof window !== "undefined") {
      window.open(url, "_blank");
    }
  }; 

  handleTickMark = () => {
    
    this.setState(prevState => ({
      checkTick: !prevState.checkTick
  }))
  }

  async componentDidMount() {
    super.componentDidMount();
    setTimeout(() => {
      if (this.scrollReff.current) {
        this.scrollReff.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
  }
}
// Customizable Area End
