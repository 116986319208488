// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from '../../../framework/src/Message';
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { createRef } from "react";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath: (path:string) => void;
}

export interface S {
  photos: boolean,
  photosChannel: boolean,
  photosEpisode: boolean,
  photosEpisodeFullScreen: boolean,
  videos: boolean,
  videosChannel: boolean,
  videosEpisode: boolean,
  videosFullScreen: boolean,
  audioAdWithinAudioPodcast: boolean,
  audioPreRolls: boolean,
  audioPostRolls: boolean,
  videoAdWithinVideoPodcast: boolean,
  videoPreRoll: boolean,
  videoPostRoll: boolean,
  audioTextField: string,
  videoTextField: string,
}

export interface SS {
  id: any;
}

export default class HostMonetizationOnlineAdvertisingController extends BlockComponent<
  Props,
  S,
  SS
> {
  getmemoizationOnlineAdvertisingDataApiCallId = "";
  updateMonitizationOnlineAdvertisingCallId = "";
  deleteMonitizationOnlineAdvertisingCallId = "";
  scrollRef: any = createRef();
  async receive(from: string, message: Message) {
    if (this.updateMonitizationOnlineAdvertisingCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForUpdateSponsoredApi(message);
    }
    if (this.getmemoizationOnlineAdvertisingDataApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForMonetizationData(message);
    }
    if (this.deleteMonitizationOnlineAdvertisingCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForDeleteMonetizationOnlineAdvertising(message);
    }
  }

  async componentDidMount() {
    this.getmemoizationOnlineAdvertisingData()
    setTimeout(() => {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
  }

  getmemoizationOnlineAdvertisingData = async () => {
    let token = await getStorageData("token");
    let prodcastId = await getStorageData("podcast_id");
    const header = {
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getmemoizationOnlineAdvertisingDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getmonetizationData}?podcast_id=${prodcastId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),

      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateMonitizationOnlineAdvertising = async (values: any) => {

    let token = await getStorageData("token");
    let monetization_id = await getStorageData("monetization_id")
    let prodcastId = await getStorageData("podcast_id");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: JSON.parse(token),
    };
    const body: any = {
      "monetization": {
        "id": monetization_id,
        "advertisement_setting_attributes": {
          "episode_photo": values.photosEpisode,
          "full_screen_photo": values.photosEpisodeFullScreen,
          "channel_photo": values.photosChannel,
          "channel_video": values.videosChannel,
          "episode_video": values.videosEpisode,
          "full_screen_video": values.videosFullScreen,
          "audio_preroll": values.audioPreRolls,
          "video_preroll": values.videoPreRoll,
          "audio_postroll": values.audioPostRolls,
          "video_postroll": values.videoPostRoll,
          "audio_length": values.audioTextField,
          "video_length": values.videoTextField,
          "podcast_id": prodcastId
      },
      }
    };   

    const requestMessageOnlineAdvertising = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateMonitizationOnlineAdvertisingCallId = requestMessageOnlineAdvertising.messageId;
    requestMessageOnlineAdvertising.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateMonitization}/${monetization_id}`
    );
    requestMessageOnlineAdvertising.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessageOnlineAdvertising.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    requestMessageOnlineAdvertising.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiMethod
    );
    runEngine.sendMessage(requestMessageOnlineAdvertising.id, requestMessageOnlineAdvertising);
  };
  handleResForMonetizationData = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson.data) {
      this.setState({
        photosChannel: responseJson.data.attributes.advertisement_setting.channel_photo,
        photosEpisode: responseJson.data.attributes.advertisement_setting.episode_photo,
        photosEpisodeFullScreen: responseJson.data.attributes.advertisement_setting.full_screen_photo,       
        videosChannel: responseJson.data.attributes.advertisement_setting.channel_video,
        videosEpisode: responseJson.data.attributes.advertisement_setting.episode_video,
        videosFullScreen: responseJson.data.attributes.advertisement_setting.full_screen_video,
        audioPreRolls: responseJson.data.attributes.advertisement_setting.audio_preroll,
        audioPostRolls: responseJson.data.attributes.advertisement_setting.audio_postroll,
        videoPreRoll: responseJson.data.attributes.advertisement_setting.video_preroll,
        videoPostRoll: responseJson.data.attributes.advertisement_setting.video_postroll,
        audioTextField: responseJson.data.attributes.advertisement_setting.audio_length,
        videoTextField: responseJson.data.attributes.advertisement_setting.video_length,
      })
    }
  };
  handleResForUpdateSponsoredApi = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson.data) {
      this.props.handleCommonPath("HostCrowfundingResidentSupport")
    }
  }

  deleteMonetizationOnlineAdvertising = async () => {
    let token = await getStorageData("token");
    let monetization_id = await getStorageData("monetization_id")
    const header = {
      token: JSON.parse(token),
    };

    const getMonetizationrequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteMonitizationOnlineAdvertisingCallId = getMonetizationrequest.messageId;
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteMonetize}/${monetization_id}`
    );
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );
    runEngine.sendMessage(getMonetizationrequest.id, getMonetizationrequest);
  };

  handleResForDeleteMonetizationOnlineAdvertising = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));  
    if (responseJson.message) {
      removeStorageData("monetization_id")
      removeStorageData("monetizationIds")
      removeStorageData("monetizationCategories")
     this.props.handleCommonPath("HostMonetizationSetup")          
    }
  };
constructor(props: Props) {
  super(props);
  this.subScribedMessages = [
    getName(MessageEnum.RestAPIResponceMessage),
    getName(MessageEnum.NavigationPayLoadMessage),
    getName(MessageEnum.CountryCodeMessage)
  ];
  this.receive = this.receive.bind(this);

  runEngine.attachBuildingBlock(this, this.subScribedMessages);

  this.state = {
    photos: false,
    photosChannel: false,
    photosEpisode: false,
    photosEpisodeFullScreen: false,
    videos: false,
    videosChannel: false,
    videosEpisode: false,
    videosFullScreen: false,
    audioAdWithinAudioPodcast: false,
    audioPreRolls: false,
    audioPostRolls: false,
    videoAdWithinVideoPodcast: false,
    videoPreRoll: false,
    videoPostRoll: false,
    audioTextField: "",
    videoTextField: "",
  };
}
handleOnlineAdvertisingRoute = (path: string, from: string) => {
  const paidWebinars = "Hosting Paid Webinars or Workshops";
  const merchandiseSales = "Merchandise Sales";
  const consultingServices = "Offering Consulting or Coaching Services";
  const affiliateMarketing = "Affiliate Marketing";
  const digitalProducts = "Selling Digital Products";
  const sponsoredContent = "Sponsored Content and Partnerships";
  const onlineAdvertising = "onlineAdvertising";
  const membershipModel = "Membership/Subscription Model";

  if (path.includes(merchandiseSales) && from.includes(onlineAdvertising)) {
    this.props.handleCommonPath("HostMonetizationMerchandise");
  } else if (path.includes(paidWebinars) && from.includes(onlineAdvertising)) {
    this.props.handleCommonPath("HostMonetizationPaidWebinar");
  } else if (path.includes(affiliateMarketing) && from.includes(onlineAdvertising)) {
    this.props.handleCommonPath("HostMonetizationAffiliateMarketing");
  } else if (path.includes(consultingServices) && from.includes(onlineAdvertising)) {
    this.props.handleCommonPath("HostMonetizationOfferingConsultancy");
  } else if (path.includes(digitalProducts) && from.includes(onlineAdvertising)) {
    this.props.handleCommonPath("HostMonetizationSellingDigitalProducts");
  } else if (path.includes(membershipModel) && from.includes(onlineAdvertising)) {
    this.props.handleCommonPath("HostMonetizationChooseTemplate");
  } else if (path.includes(sponsoredContent) && from.includes(onlineAdvertising)) {
    this.props.handleCommonPath("SponsoredContentPartnership");
  } else {
    this.props.handleCommonPath("HostMonetizationSetup");
  }
};

}
// Customizable Area End