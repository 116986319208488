// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from '../../../framework/src/Message';
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { createRef } from "react";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath:(path:string) => void;
}

export interface S {
  offeringConsultancyWebsite : string,
  affiliateMarketing : string,
  temp : string,
  paidWebinar : string,
  hostMerchandise : string
}

export interface SS {
  id: any;
}

export default class HostMonetizationOfferingConsultancyController extends BlockComponent<
  Props,
  S,
  SS
> {

  getmemoizationOfferingConsultancyDataApiCallId = "";
  updateMonitizationOfferingConsultancyCallId = "";
  deleteMonetizationOfferingConsultancyCallId = "";
  ScrollRef: any = createRef();

  async receive(from: string, message: Message) {   
    if (this.updateMonitizationOfferingConsultancyCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForUpdateSponsoredApi(message);
    }
    if (this.getmemoizationOfferingConsultancyDataApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForOfferingConsultancyMonetizationData(message);
    }
    if (this.deleteMonetizationOfferingConsultancyCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForDeleteMonetizationOfferingConsultancy(message);
    }
    
  }

  async componentDidMount() {
    this.getmemoizationOfferingConsultancyData()
    setTimeout(() => {
      if (this.ScrollRef.current) {
        this.ScrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
  }

  getmemoizationOfferingConsultancyData = async () => {
    let token = await getStorageData("token");
    let prodcastId = await getStorageData("podcast_id");
    const header = {
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getmemoizationOfferingConsultancyDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getmonetizationData}?podcast_id=${prodcastId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  constructor(props: Props) {

    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.receive = this.receive.bind(this);


    this.state = {
      offeringConsultancyWebsite : "",
      affiliateMarketing : "",
      temp : "",
      paidWebinar : "",
      hostMerchandise : ""
    };
  }

  updateOfferingConsultancyMonitization = async (values:any) => {
    
    let token = await getStorageData("token");
    let prodcastId = await getStorageData("podcast_id");

    let monetization_id = await getStorageData("monetization_id")
   
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: JSON.parse(token),
    };
    const body :any = {
      "monetization": {
        "id": monetization_id,
        "podcast_id": prodcastId,           
      }
    };
    if (this.state.temp == "affiliate") {
      body.monetization.marketing_url = values.affiliateMarketing;
    }
    if(this.state.temp == "coaching_url"){
      body.monetization.coaching_url = values.offeringConsultancyWebsite;
    }
    if(this.state.temp ==  "paidWebinar"){
      body.monetization.workshop_url = values.paidWebinar;
    }
    if(this.state.temp ==  "hostMerchandise"){
      body.monetization.merchandise_url = values.hostMerchandise;
    }

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateMonitizationOfferingConsultancyCallId = requestMessage.messageId;
    requestMessage.addData(

      getName(MessageEnum.RestAPIResponceEndPointMessage),

      `${configJSON.updateMonitization}/${monetization_id}`
    );
    requestMessage.addData(

      getName(MessageEnum.RestAPIRequestHeaderMessage),

      JSON.stringify(header)
    );
    requestMessage.addData(

      getName(MessageEnum.RestAPIRequestBodyMessage),

      JSON.stringify(body)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  deleteMonetizationOfferingConsultancy = async () => {
    let token = await getStorageData("token");
    let monetization_id = await getStorageData("monetization_id")
    const header = {
      token: JSON.parse(token),
    };

    const getMonetizationrequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteMonetizationOfferingConsultancyCallId = getMonetizationrequest.messageId;
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteMonetize}/${monetization_id}`
    );
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );
    runEngine.sendMessage(getMonetizationrequest.id, getMonetizationrequest);
  };
  
  handleResForOfferingConsultancyMonetizationData = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));  
    if (responseJson.data) {
     this.setState({
      offeringConsultancyWebsite : responseJson.data.attributes.coaching_url,
      affiliateMarketing : responseJson.data.attributes.marketing_url,
      paidWebinar : responseJson.data.attributes.workshop_url,
      hostMerchandise : responseJson.data.attributes.merchandise_url
     })
    }
  };  
  handleResForUpdateSponsoredApi = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson.data) {
      console.log(responseJson.data,"temp",this.state.temp);
      
      if(this.state.temp == "coaching_url"){
        this.props.handleCommonPath("HostMonetizationAffiliateMarketing")
      }
      if(this.state.temp == "affiliate"){
      this.props.handleCommonPath("HostMonetizationPaidWebinar")
      }
      if(this.state.temp == "paidWebinar"){
        this.props.handleCommonPath("HostMonetizationMerchandise")
      }
      if(this.state.temp == "hostMerchandise"){
        this.props.handleCommonPath("HostMonetizationOnlineAdvertising")
      }
      
    }
  };

  handleResForDeleteMonetizationOfferingConsultancy = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));  

    if (responseJson.message) {
      removeStorageData("monetizationIds")
      removeStorageData("monetizationCategories")
      removeStorageData("monetization_id")
     this.props.handleCommonPath("HostMonetizationSetup")          
    }
  };

 

  handleMechandiseRoute = (route: any) => {

    if (route.includes("Hosting Paid Webinars or Workshops")) {
      this.props.handleCommonPath("HostMonetizationPaidWebinar")
    }
    else if (route.includes("Affiliate Marketing")) {
      this.props.handleCommonPath("HostMonetizationAffiliateMarketing")
    }
    else if (route.includes("Offering Consulting or Coaching Services")) {
      this.props.handleCommonPath("HostMonetizationOfferingConsultancy")
    }
    else if (route.includes("Selling Digital Products")) {
      this.props.handleCommonPath("HostMonetizationSellingDigitalProducts")
    }
    else if (route.includes("Membership/Subscription Model")) {
      this.props.handleCommonPath("HostMonetizationChooseTemplate")
    }
    else if (route.includes("Sponsored Content and Partnerships")) {
      this.props.handleCommonPath("SponsoredContentPartnership")
    }
    else {
      this.props.handleCommonPath("HostMonetizationSetup")
    }
  }

  handleRoutesAffiliateMarketing = (route: any) => {
    if (route.includes("Offering Consulting or Coaching Services")) {
      this.props.handleCommonPath("HostMonetizationOfferingConsultancy")
    }
    else if (route.includes("Selling Digital Products")) {
      this.props.handleCommonPath("HostMonetizationSellingDigitalProducts")
    }
    else if (route.includes("Membership/Subscription Model")) {
      this.props.handleCommonPath("HostMonetizationChooseTemplate")
    }
    else if (route.includes("Sponsored Content and Partnerships")) {
      this.props.handleCommonPath("SponsoredContentPartnership")
    }
    else {
      this.props.handleCommonPath("HostMonetizationSetup")
    }
  }
  handlePaidWebinarRoute = (route: any) => {
    if (route.includes("Selling Digital Products")) {
      this.props.handleCommonPath("HostMonetizationSellingDigitalProducts")
    }
    else if (route.includes("Membership/Subscription Model")) {
      this.props.handleCommonPath("HostMonetizationChooseTemplate")
    }
    else if (route.includes("Sponsored Content and Partnerships")) {
      this.props.handleCommonPath("SponsoredContentPartnership")
    }
    else {
      this.props.handleCommonPath("HostMonetizationSetup")
    }

  }
  handleOfferingConsultancyRoute = (route : any) => {
    if(route.includes("Selling Digital Products")){
              this.props.handleCommonPath("HostMonetizationSellingDigitalProducts")
          }        
          else if(route.includes("Membership/Subscription Model")){
              this.props.handleCommonPath("HostMonetizationChooseTemplate")
          } 
          else if(route.includes("Sponsored Content and Partnerships")){
              this.props.handleCommonPath("SponsoredContentPartnership")
          } 
          else{                                        
              this.props.handleCommonPath("HostMonetizationSetup")
          }
  }

}
// Customizable Area End