// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from '../../../framework/src/Message';
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { handleNavigation } from "../../utilities/src/CustomHelpers";
import { createRef } from "react";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath: (path:string) => void;
}

export interface S {
    subscriptionTiers : string[]
}

export interface SS {
  id: any;
}

export default class HostMonetizationChooseTemplateController extends BlockComponent<
  Props,
  S,
  SS
> {
  deleteMonetizeChooseTemplateCallId = "";
  scrollRef: any = createRef();
  
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
        subscriptionTiers : ["Access to Premium Episodes","Access to the Community","Ad-Free Experience"]
    };
  }

  async receive(from: string, message: Message) {     
    if (this.deleteMonetizeChooseTemplateCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForDeleteMonetizationChooseTemplateData(message);
    }
  }

  deleteMonetizationChooseTemplate = async () => {
    let token = await getStorageData("token");
    let monetization_id = await getStorageData("monetization_id")
    const header = {
      token: JSON.parse(token),
    };

    const getMonetizationrequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteMonetizeChooseTemplateCallId = getMonetizationrequest.messageId;
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteMonetize}/${monetization_id}`
    );
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );
    runEngine.sendMessage(getMonetizationrequest.id, getMonetizationrequest);
  };
  handleResForDeleteMonetizationChooseTemplateData = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));  
    if (responseJson.message) {
      removeStorageData("monetization_id")
      removeStorageData("monetizationIds")
      removeStorageData("monetizationCategories")
     handleNavigation("HostMonetizationSetup",this.props)          
    }
  };
  handleItemClick = (item:any) => {
    const { subscriptionTiers } = this.state;
    const itemName = item;
  
    if (Array.isArray(subscriptionTiers) && subscriptionTiers.includes(itemName)) {
        this.setState({
            subscriptionTiers: subscriptionTiers.filter(
                (category) => category !== itemName
            ),
        });
    } else {
        this.setState({
            subscriptionTiers: Array.isArray(subscriptionTiers)
                ? [...subscriptionTiers, itemName]
                : [itemName],
        });
    }
  };

  async componentDidMount() {
    super.componentDidMount();
    setTimeout(() => {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
  }
}
// Customizable Area End
