// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import { createRef } from "react";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath: (path:string) => void;
}

interface Chapter {
  newChapterImage: string;
  startTime: string;
  endTime: string;
}
interface PodcastFormValues {
  podcastIMGCover: string;
  additionalPhotos: boolean;
  chapters: Chapter[];
}
export interface S {
  files: unknown[],
  imageUrl: string,
  invalidFileMessage: string,
  invalidChapterMessage: string[],
  additionalImages: string[]
  formikInitialValue: PodcastFormValues,
  openFirstDialog: boolean,
  coverImageFile: File
}

export interface SS {
  id: any;
}

export default class MediaManagementNewContentController extends BlockComponent<
  Props,
  S,
  SS
> {
  podcastCategoryApiCallId: string = "";
  podcastSubCategoryApiCallId: string = "";
  countryApiCallId: string = "";
  stateApiCallId: string = "";
  newcontentApiCallId: string = "";
  scrollref: any = createRef();
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
    this.state = {
      files: [],
      imageUrl: "",
      invalidFileMessage: "",
      invalidChapterMessage: Array(20).fill(""),
      additionalImages: [],
      formikInitialValue: {
        podcastIMGCover: '',
        additionalPhotos: false,
        chapters: [{ newChapterImage: "", startTime: "", endTime: "" }]
      },
      openFirstDialog: false,
      coverImageFile: new File([""], "coverImage.jpg")
    };
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    const responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (
      apiRequestCallId != null &&
      apiRequestCallId === this.newcontentApiCallId &&
      !responseJson.errors
    ) {
      this.setState({ openFirstDialog: true });

    }
  }

  handleGoBack = () => {
    this.props.navigation.goBack()
  }

  handleDrop = (event: React.DragEvent<HTMLDivElement>, setFieldValue: any, index: number | null = null) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    if (files.length) {
      this.handleFile(files[0], setFieldValue, index);
    }
  };

  handleFileSelect = (event: React.ChangeEvent<HTMLInputElement>, setFieldValue: any, index: number | null = null) => {
    const files = event.target.files ? Array.from(event.target.files) : [];
    if (files.length) {
      this.handleFile(files[0], setFieldValue, index);
    }
  };

    handleFile = (file: File, setFieldValue: any, index: number | null = null) => {
      if (file) {
        this.setState({ coverImageFile: file });

        const img = new Image();
        img.onload = () => {
          if (img.width !== 3000 || img.height !== 3000) {
            if (index === null) {
              this.setState({ invalidFileMessage: "Photo is not 3000x3000, please upload new photo of that size to move forward" });
            } else {
              this.setState((prevState) => {
                const updatedInvalidMessages = [...prevState.invalidChapterMessage];
                updatedInvalidMessages[index] = "Photo is not 3000x3000, please upload new photo of that size to move forward";
                return { invalidChapterMessage: updatedInvalidMessages };
            });
            }
          } else {
            if (index === null) {
              this.setState({ invalidFileMessage: "" }, () => {
                this.setState({ imageUrl: img.src });
                setFieldValue("podcastIMGCover", img.src);
              });
            } else {
              this.setState((prevState) => {
                const updatedInvalidMessages = [...prevState.invalidChapterMessage];
                updatedInvalidMessages[index] = "";
                return { invalidChapterMessage: updatedInvalidMessages };
            }, () => {
                const updatedImages = [...this.state.additionalImages];
                updatedImages[index] = img.src;
                this.setState({ additionalImages: updatedImages });
                setFieldValue(`chapters[${index}].newChapterImage`, img.src);
            });
            }
          }
        };
    
        const reader = new FileReader();
        reader.onloadend = () => {
          img.src = reader.result as string;
        };
  
      reader.readAsDataURL(file);
    }
  };
  

  isImageValid = () => {
    return this.state.imageUrl !== "" && this.state.invalidFileMessage === "";
  }

  handleSubmit = async (values: PodcastFormValues) => {
    try {
      let data = await getStorageData("NewContentData", true);
      let parsedData = data;
      const mergedData = { ...parsedData, ...values };
      await setStorageData("NewContentData", JSON.stringify(mergedData));
      this.setState({ imageUrl: "" });
      this.setState({ additionalImages: [] })
      this.submitNewContent();
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    }
  };

  handleCloseFirstDialog = () => {
    this.setState({ openFirstDialog: false });
  };

  submitNewContent = async () => {
    let token = await getStorageData("token", true);
    let data = await getStorageData("NewContentData", true);
    let prodcastId = await getStorageData("podcast_id");
    let prodcastUrl = await getStorageData("ProdcastUrl");

    const header = {
      token: token,
    };

    let formdata = new FormData();
    formdata.append("episode[name]", data.episodeTitle || "");
    formdata.append("episode[description]", data.description || "");
    formdata.append("episode[episode_number]", data?.episodeNumber || "");
    formdata.append("episode[adult]", data?.explicitContent || false);
    formdata.append("episode[podcast_season]", data?.seasonNumber || "");
    formdata.append("episode[premium]", data?.premiumEpisode || false);
    formdata.append("episode[episode_type]", data.episodeType || "");


    formdata.append("episode[publish_date]", "25/1/2026");
    formdata.append("episode[expiry_date]", "25/2/2026");

    formdata.append("episode[explicit_content]", data.explicitContent || false);

    formdata.append("podcast_id", prodcastId);
    formdata.append("episode[episode_content]", prodcastUrl);
    formdata.append("episode[cover]", this.state.coverImageFile);


    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.newcontentApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.submitNewContent
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async componentDidMount() {
    super.componentDidMount();
    setTimeout(() => {
      if (this.scrollref.current) {
        this.scrollref.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
  }
}
// Customizable Area End
