Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.postCommunityEndPoint= "bx_block_communityforum/posts"
exports.getCommunityEndPoint= "bx_block_communityforum/posts?podcast_id="
exports.getEpisodeDataEndPoint = "bx_block_settings/settings/channel";
exports.postContactHost= "bx_block_contact_us/messages";
exports.exampleAPiMethod="POST"
exports.aboutText = "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Integer Vulputate, Nulla Lacinia Accumsan Placerat, Ex Augue Hendrerit Ligula, Id Cursus Massa Lorem Sit Amet Ante. Ut Lobortis Tempor Semper. Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit. Integer Vulputate, Nulla Lacinia Accumsan Placerat, Ex Augue Hendrerit Ligula, Id Cursus Massa Lorem Sit Amet Ante. Ut Lobortis Tempor Semper."
exports.aboutTitle = "About"
exports.channelInfoTitle = "The True Podcast"
exports.communityTitle = "The True Podcast"
exports.channelInfodescription = "COST OF WINNING"
exports.channelInfoLocation = "London, UK"
exports.otherShows= "Others Shows From This Host"
exports.host= "Main Host :"
exports.hostNameOther= "THE ATLAS OBSCURA"
exports.hostName = "Ana James"
exports.cancelText= "Cancel"
exports.closeText = "Close"
exports.publishText = "Publish"
exports.saveText= "Send"
exports.hostDescription = "Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit.Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit."
exports.shareChannelModal = "Share Channel"
exports.channelUrlText = "Channel URL"
exports.embedText = "Embed"
exports.startAt = "Star At"
exports.copyText = "Copy"
exports.podcastLink = "https://www.podcasttown.net/episode/26ec9c9c-c49a-4706-"
exports.rateOrReviewText = "Rate Or Review"
exports.rateThisPodcast = "Rate This Podcast"
exports.leaveAReview = "Leave a Review"
exports.randomDate= "07/30/24"
exports.randomDate2= "07/25/24"
exports.daysAgo = "5 days ago"
exports.dropDownEdit = "Edit"
exports.dropDownSave = "Save"
exports.TurnOffNotification = "Turn off Notification"
exports.dropDownDelete = "Delete"
exports.blockUserText = "Block User"
exports.hideText = "Hide";
exports.communityText = "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis."
// Customizable Area End