// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { Message } from '../../../framework/src/Message';
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import { createRef } from "react";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  handleCommonPath:(path:string) => void
}

export interface S {
  data: any,
  apiValue: any,
  flag: boolean,
  editablePrice: any,
  editFlags: any,
  editableFeatures: any,
  checkedFeatures: any,
  editableIndex: any,
  selectedFeatures: any
}

export interface SS {
  id: any;
}

export default class ChooseFeatureSubscriptionTierController extends BlockComponent<
  Props,
  S,
  SS
> {

  subscriptionTierApiCallId = "";
  getmemoizationDataSubscriptionApiCallId = "";
  updateMonitizationSubscriptionCallId = "";
  deleteMonetizeChooseFeatureCallId = "";
  scrollRef: any = createRef();
  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      data: [],
      apiValue: [],
      flag: false,
      editablePrice: "",
      editFlags: [],
      editableFeatures: [],
      checkedFeatures: {},
      editableIndex: null,
      selectedFeatures: []
    };
  }

  async receive(from: string, message: Message) {
    if (this.getmemoizationDataSubscriptionApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForMonetizationData(message);
    }
    if (this.subscriptionTierApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResSubsTiers(message);
    }
    if (this.updateMonitizationSubscriptionCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForUpdateMonetizationData(message);
    }
    if (this.deleteMonetizeChooseFeatureCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      this.handleResForDeleteMonetizationChooseFeatureData(message);
    }
    

  }

  async componentDidMount() {
    await this.subcriptionTiers();   
    setTimeout(() => {
      if (this.scrollRef.current) {
        this.scrollRef.current.scrollTo({ top: 0, behavior: "smooth" });
      }
    }, 0);
  }

  getmemoizationSubscriptionData = async () => {
    let token = await getStorageData("token");
    let prodcastId = await getStorageData("podcast_id")
    const header = {
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getmemoizationDataSubscriptionApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getmonetizationData}?podcast_id=${prodcastId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateMonitizationSubcription = async (values: any) => {
    let token = await getStorageData("token");
    let prodcastId = await getStorageData("podcast_id");
    let monetization_id = await getStorageData("monetization_id")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: JSON.parse(token),
    };
    const body = {
      "monetization": {
        "id": monetization_id,
        "podcast_id": prodcastId,
        "podcast_subscriptions_attributes": values.podcast_subscriptions_attributes,
      }
    };

    const featureSubscriptionsMessageRequest = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateMonitizationSubscriptionCallId = featureSubscriptionsMessageRequest.messageId;
    featureSubscriptionsMessageRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateMonitization}/${monetization_id}`
    );
    featureSubscriptionsMessageRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    featureSubscriptionsMessageRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    featureSubscriptionsMessageRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAPiMethod
    );
    runEngine.sendMessage(featureSubscriptionsMessageRequest.id, featureSubscriptionsMessageRequest);
  };
  handleResForMonetizationData = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson.data && Array.isArray(responseJson.data.attributes.podcast_subscriptions)) {
      const modifiedArray = await this.state.data.map((item: any) => {
        const matchingSubscription = responseJson.data.attributes.podcast_subscriptions.find(
          (subscription: any) => subscription.name === item.attributes.name
        );

        return matchingSubscription || item;
      });

      this.setState({
        apiValue: modifiedArray
      })
    }
  };

  handleResForUpdateMonetizationData = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson.data) {
      this.getmemoizationSubscriptionData();
      const editFlags = Array(this.state.apiValue.length).fill(false);
      const editablePriceReset = Array(this.state.apiValue.length).fill("");
      const editableFeaturesReset = {};

      this.setState({
        editableIndex: null,
        editFlags,
        editablePrice: editablePriceReset,
        editableFeatures: editableFeaturesReset,
      });

    }
  }; 

  toggleEditMode = (index: number) => {
    const isCurrentlyEditing = this.state.editableIndex === index;
    const editablePrice = [...this.state.editablePrice];
    const editableFeatures = { ...this.state.editableFeatures };
    
    if (!isCurrentlyEditing) {
      this.state.data.map((item: any) => {
        if (item.id == index + 1) {
          editableFeatures[index] = item.features;
        }
      })
      
      editablePrice[index] = `$${typeof this.state?.apiValue[index]?.id === 'number' ?this.state?.apiValue[index]?.price :this.state?.apiValue[index]?.attributes.price || ""}`.replace(/^\$+/, "");
      editableFeatures[index] = this.state.data;
    } else {
      editablePrice[index] = "";
    }

    this.setState({
      editFlags: Array(this.state.apiValue.length)
        .fill(false)
        .map((_, i) => i === index && !isCurrentlyEditing),
      editablePrice,
      editableFeatures,
      editableIndex: isCurrentlyEditing ? null : index,
    });
  };

  deleteMonetizationChooseFeature = async () => {
    let token = await getStorageData("token");
    let monetization_id = await getStorageData("monetization_id")
    const header = {
      token: JSON.parse(token),
    };

    const getMonetizationrequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteMonetizeChooseFeatureCallId = getMonetizationrequest.messageId;
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.deleteMonetize}/${monetization_id}`
    );
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    getMonetizationrequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.deleteAPiMethod
    );
    runEngine.sendMessage(getMonetizationrequest.id, getMonetizationrequest);
  };

  handleResForDeleteMonetizationChooseFeatureData = async (message: Message) => {
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));  
    if (responseJson.message) {
      removeStorageData("monetization_id")
      removeStorageData("monetizationIds")
      removeStorageData("monetizationCategories")
     this.props.handleCommonPath("HostMonetizationSetup")          
    }
  };

  toggleCheckBox = (index: number, checkBoxItem: string) => {
    const updatedEditableFeatures = { ...this.state.editableFeatures };
    const featuresForCurrentItem = updatedEditableFeatures[index] || [];
    const isAlreadyChecked = featuresForCurrentItem.includes(checkBoxItem);
    if (isAlreadyChecked) {
      updatedEditableFeatures[index] = featuresForCurrentItem.filter(
        (item: string) => item !== checkBoxItem
      );
    } else {
      updatedEditableFeatures[index] = [...featuresForCurrentItem, checkBoxItem];
    }
    this.setState({
      editableFeatures: updatedEditableFeatures
    });
  };


  handleSave = async (index: number) => {
    const { data, editablePrice, editableFeatures, apiValue } = this.state;
    let podcastId = await getStorageData("podcast_id");
    const selectedFeatures = (editableFeatures[index] || []).filter(
      (feature:any) => typeof feature === "string"
  );
    const updatedObject = {
      podcast_subscriptions_attributes: [
        {
          id: apiValue.length > 0 && typeof apiValue[index].id === 'number' ? apiValue[index].id : null,
          name: data[index].attributes.name,
          price: parseFloat(editablePrice[index]) || null,
          podcast_id: data[index].attributes.podcast_id || podcastId,
          features: selectedFeatures,
        },
      ],
    };

    this.updateMonitizationSubcription(updatedObject);
  };


  handlePriceChange = (index: number, value: string) => {
    const numericValue = value.replace(/^\$+/, "");
    const editablePrice = [...this.state.editablePrice];
    editablePrice[index] = numericValue;
    this.setState({ editablePrice });
  };

  subcriptionTiers = async () => {
    let token = await getStorageData("token");
    const header = {
      token: JSON.parse(token),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.subscriptionTierApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.subscription_tiers
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleResSubsTiers = async (message: Message) => {
    const responseJson = await message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (responseJson.data) {
      const editFlags = await responseJson.data.map(() => false);
      let responseJson1 = await responseJson.data
      setStorageData("subscriptionTiers", JSON.stringify(responseJson.data));
      this.setState({ data: responseJson1, editFlags });
    }
    await this.getmemoizationSubscriptionData();
  };

}
// Customizable Area End
