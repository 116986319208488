// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import { handleNavigation } from "../../utilities/src/CustomHelpers";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}

interface S {
    speed: number;
    isPlaying: boolean;
    currentTime: number;
    duration: number;
    currentTrack: number;
    audioTracks: any;
    volume: number;
    tracks: any
}

interface SS {
    id: any;
}

export default class AudioPlayerComponentController extends BlockComponent<Props, S, SS> {
    addlikeCallId: string = "";
    audioPlayerApiCallId: string = "";
    audioLastPlayedApiCallId: string = "";
    audioRef = React.createRef<any>();

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.CountryCodeMessage)
        ];
        this.audioRef = React.createRef();
        this.state = {
            speed: 1,
            isPlaying: false,
            currentTime: 0,
            duration: 0,
            currentTrack: 0,
            audioTracks: {
                podcast_id: "",
                title: "",
                artist: "",
                src: "",
                episode_number: "",
                liked_count: "",
                liked: true,
                cover_photo: "",
                liked_id: "",
                episode_id: ""
            },
            volume: 0.5,
            tracks: []
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
        this.handleResForaddLikeApi(from, message);
        this.handleResForAudioLastPlayedApi(from, message);

    }
    audioPlayerLastPlayed = async () => {
        let token = await getStorageData("token");
        const header = {
            token: JSON.parse(token),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.audioLastPlayedApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.lastPlayed
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleResForAudioLastPlayedApi = async (from: string, message: Message) => {
        if (this.audioLastPlayedApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            if (responseJson.data) {

                let data = {
                    "Playable_type": responseJson.data[0].attributes.playable.data.type,
                    "Playable_id": responseJson.data[0].attributes.playable.data.id,
                    "runTime": responseJson.data[0].attributes.run_time,
                    "song_id": responseJson.data[0].attributes.current_episode.data.id,
                    "episodes": responseJson.data[0].attributes.playable.data.attributes,
                }
                
                let tracks = {
                    podcast_id: responseJson.data[0].attributes.playable.data.id,
                    title: data.episodes.name,
                    artist: data.episodes.description,
                    src: data.episodes.episode_content,
                    episode_number: data.episodes.episode_number,
                    liked_count: data.episodes.liked_episode.count,
                    liked: data.episodes.liked_episode.liked,
                    cover_photo: data.episodes.cover_photo,
                    liked_id: data.episodes.liked_episode.liked_id,
                    episode_id: responseJson.data[0].id
                }

                let podcastDetails = {
                    "episodes": responseJson.data[0].attributes.playable.data.attributes,
                }
                
                
                this.setState({ audioTracks: tracks });
                setStorageData("lastPlayed", JSON.stringify(tracks))
                setStorageData("podcastDetails", JSON.stringify(podcastDetails))
            }
        }
    };
   
    handleResForaddLikeApi = async (from: string, message: Message) => {
        const responseId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        if (this.addlikeCallId === responseId) {
            this.audioPlayerLastPlayed()
        }
    };

    async componentDidMount() {
      
        this.audioPlayerLastPlayed();

        const setInitialPlaybackTime = async () => {
            let lastPlayed = await getStorageData("lastPlayed", true);
            const savedRunTime = lastPlayed?.runTime || 0;

            if (this.audioRef.current) {
                this.audioRef.current.currentTime = savedRunTime;
                this.setState({
                    currentTime: savedRunTime
                })
            }
        };
        setInitialPlaybackTime();
        if (this.audioRef.current && this.state.audioTracks.length > 0) {
            this.audioRef.current.pause();
            this.audioRef.current.playbackRate = this.state.speed;
            if (this.state.isPlaying) {
                this.audioRef.current.play();
            }
        }
        if (this.audioRef.current) {
            this.audioRef.current.volume = this.state.volume;
        }
    }

    componentDidUpdate(prevProps:any, prevState:any) {
        if (prevState.currentTrack !== this.state.currentTrack) {
            this.audioRef.current.pause();
            this.audioRef.current.load();
            if (this.state.isPlaying) {
                this.audioRef.current.play();
            }
        }
    }

    handleTrackChange = async (direction: number) => {
        const { audioTracks, currentTrack } = this.state;
        let newTrackIndex = (currentTrack + direction) % audioTracks.length;
        if (newTrackIndex < 0) newTrackIndex = audioTracks.length - 1;
    
        let lastPlayed = await getStorageData("lastPlayed", true);
        if (lastPlayed) {
            lastPlayed.runTime = 0;
            lastPlayed.song_id = audioTracks[newTrackIndex].episode_number;
            await setStorageData("lastPlayed", JSON.stringify(lastPlayed));
        }
    
        this.setState({ currentTrack: newTrackIndex, currentTime: 0 }, async () => {
            if (this.audioRef.current) {
                this.audioRef.current.src = audioTracks[newTrackIndex]?.src;
                this.audioRef.current.currentTime = 0;
    
                await this.audioRef.current.play();
                this.setState({ isPlaying: true });                
            }
        });
    };
    
    handlePlayPause = async () => {
        if (this.audioRef.current) {
            let lastPlayed = await getStorageData("lastPlayed", true);
            let runtime = await getStorageData("runtime", true);
    
            if (this.state.isPlaying) {
                const runTime = this.audioRef.current.currentTime;
                this.audioRef.current.pause();
                this.audioPlayer(runTime);
                if (lastPlayed) {
                    lastPlayed.runTime = runTime;
                    await setStorageData("runtime", JSON.stringify(runTime));
                }
            } else {
                const savedRunTime = runtime || 0;
                this.audioRef.current.currentTime = savedRunTime;
                this.setState({ currentTime: savedRunTime });
                await this.audioRef.current.play();
                this.audioPlayer(savedRunTime);
            }
            
            this.setState((prevState) => ({ isPlaying: !prevState.isPlaying }));
        }
    };
    
    rewind10Seconds = () => {
        if (this.audioRef.current) {
            this.audioRef.current.currentTime = Math.max(
                this.audioRef.current.currentTime - 10, 0
            );
        }
    };

    skip10Seconds = () => {
        if (this.audioRef.current) {
            this.audioRef.current.currentTime = Math.min(
                this.audioRef.current.currentTime + 10, this.state.duration
            );
        }
    };

    handleTimeUpdate = () => {
        if (this.audioRef.current) {
            this.setState({ currentTime: this.audioRef.current.currentTime });
        }
    };

    handleLoadedMetadata = () => {
        if (this.audioRef.current) {
            this.setState({ duration: this.audioRef.current.duration });
        }

    };

    handleLike = async (id: number, liked: boolean) => {
            let token = await getStorageData("token");
            const header = {
                "content-type": "application/json",
                token: JSON.parse(token),
            };

            const httpBody = {
                data: {
                    likeable_type: "BxBlockLogin::Episode",
                    likeable_id: id
                }
            };

            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.addlikeCallId = requestMessage.messageId;
            if (liked) {
                requestMessage.addData(
                    getName(MessageEnum.RestAPIResponceEndPointMessage),
                    `${configJSON.addLike}/${id}`
                );
                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestMethodMessage),
                    configJSON.deleteApiMethod
                );
            } else {
                requestMessage.addData(
                    getName(MessageEnum.RestAPIResponceEndPointMessage),
                    configJSON.addLike
                );
                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestMethodMessage),
                    configJSON.exampleAPiMethod
                );
                requestMessage.addData(
                    getName(MessageEnum.RestAPIRequestBodyMessage),
                    JSON.stringify(httpBody)
                );
            }
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
    };



    handleSpeedChange = (e: any) => {
        const selectedSpeed = parseFloat(e.target.value);
        this.setState({ speed: selectedSpeed }, () => {
            if (this.audioRef.current) {
                this.audioRef.current.playbackRate = selectedSpeed;
            }
        });
    };

    formatTime = (time: number) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60).toString().padStart(2, '0');
        return `${minutes}:${seconds}`;
    };

    handleProgressClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const progressBar = event.currentTarget;
        const clickPosition = event.nativeEvent.offsetX;
        const barWidth = progressBar.offsetWidth;
        const newTime = (clickPosition / barWidth) * this.state.duration;
        if (this.audioRef.current) {
            this.audioRef.current.currentTime = newTime;
        }
    };

    handleVolumeClick = (event: React.MouseEvent<HTMLDivElement>) => {
        const volumeBar = event.currentTarget;
        const clickPosition = event.nativeEvent.offsetX;
        const barWidth = volumeBar.offsetWidth;
        const newVolume = clickPosition / barWidth;
        if (this.audioRef.current) {
            this.audioRef.current.volume = newVolume;
        }
        this.setState({ volume: newVolume });
    };

    audioPlayer = async (runTime: number) => {
            let token = await getStorageData("token");

            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: JSON.parse(token),
            };
            const body = {
                data: {
                    playable_type: "BxBlockLogin::Podcast",
                    playable_id: this.state.audioTracks[this.state.currentTrack].podcast_id,
                    run_time: runTime,
                    song_id: this.state.audioTracks[this.state.currentTrack].episode_id
                },
            };

            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.audioPlayerApiCallId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.addAudio
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                JSON.stringify(body)
            );
            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.exampleAPiMethod
            );
            runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleFullScreenClick = () => {
        if(window.location.pathname.includes("/AudioPlayerFullScreen")){
            this.props.navigation.goBack();
            console.log("AudioPlyerFullScreen");            
        }
        else{
            handleNavigation("AudioPlayerFull", this.props);
        }
    };  
    
    // Customizable Area End
}
