import React from "react";
// Customizable Area Start
import { styled } from "@mui/system";
import {
  createTheme,
} from "@mui/material/styles";
import { search } from '../../navigationmenu/src/assets'
import {search1, search4, search6} from './assets';
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";

// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
// Customizable Area End

import SearchController, { Props } from "./SearchController";

export default class Search extends SearchController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start
    console.log(this.state.searchValue.length,"this.state.searchValue.length");
    
    return (
      <>
        <StyledSearchWrapper>
          <div className="searchWrapper">
            <img
              src={search}
              style={{ position: "absolute", left: "7px", top: "8px" }}
              alt="Search Icon"
            />
            <input
              data-test-id="search"              
              value={this.state.searchValue}
              onChange={(event) => this.handleSearchChange(event)}
              className="searchInput"
              type="search"
              placeholder="Search"
            />
            {
              this.state.searchValue.length > 0 &&
              <ul className="searchDropdown">
                <div className="searchResultContain">
                  <div className="leftResult">Search Results</div>
                  <div className="rightResult" 
                  data-test-id="search-result"
                  onClick={async() => {
                    this.setState({ searchValue: "" })
                    let lastPath = await getStorageData("isShowInMeddile")
                    setStorageData("previousAllSearchResult",lastPath)
                    this.props.handleCommonPath("AllSearchList")
                  }}>See All Results</div>
                </div>
                {
                  this.state.searchPodcast.slice(0, 1).map((item: any) => {
                    console.log(item, "item", item.attributes.name, item.attributes.cover_image);
                    return (
                      <div className="dropdownItem" style={{ display: "flex" }}>
                        <img src={search1} alt="search1" style={{ width: "69px", height: "76px" }} />
                        <div style={{ width: "80%", marginLeft: "30px", alignContent: "center", paddingRight: "0px" }}>
                          <li>{item.attributes.name}</li>
                        </div>
                      </div>)
                  })
                }

                {
                  this.state.searchEpisode.slice(0, 1).map((item: any) => {
                    console.log(item, "item", item.attributes.name, item.attributes.cover_image);
                    return (
                      <div className="dropdownItem" style={{ display: "flex" }}>
                        <img src={search4} alt="search1" style={{ width: "69px", height: "76px" }} />
                        <div style={{ width: "80%", marginLeft: "30px", alignContent: "center", paddingRight: "0px" }}>

                          <li>{item.attributes.name}</li>
                        </div>
                      </div>)
                  })
                }

                {
                  this.state.searchPost.slice(0, 1).map((item: any) => {
                    console.log(item, "item", item.attributes.name, item.attributes.cover_image);
                    return (
                      <div className="dropdownItem" style={{ display: "flex" }}>
                        <img src={search6} alt="search1" style={{ width: "69px", height: "76px" }} />
                        <div style={{ width: "80%", marginLeft: "30px", alignContent: "center", paddingRight: "0px" }}>

                          <li>{item.attributes.name}</li>
                        </div>
                      </div>)
                  })
                }
              </ul>
            }
          </div>

        </StyledSearchWrapper>
  </>
                
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const StyledSearchWrapper = styled("div")({
  position: "relative",
  width: "100%",

  "& .leftResult":{
    color : "#F5F5F5",
    fontSize : "14px",
    fontWeight : 100,
    fontFamily : "Nexa-Regular"
  },
  "& .rightResult":{
    color : "white",
    fontSize : "14px",
    fontWeight : 900,
    fontFamily : "Nexa-Heavy"
  },
  "& .searchResultContain":{
    padding : "12px 16px 4px 16px",
    height : "38px",
    backgroundColor : "#44403C",
    display:"flex",
    justifyContent : "space-between",
    borderTopLeftRadius : "8px",
    borderTopRightRadius : "8px",    
  },
  "& .searchInput": {
    padding: "10px 8px 10px 35px",
    border: "1px solid white",
    borderRadius: "98px",
    backgroundColor: "#030303",
    color: "white",
    outline: "none",
    width: "100%",
  },

  "& .searchDropdown": {
    position: "absolute",
    top: "100%",
    left: 0,
    right: 0,    
    backgroundColor: "#44403C",
    borderRadius: "8px",
    marginTop: "4px",
    zIndex: 10,
    listStyleType: "none",
    padding: 0,
    margin: 0,
  },

  "& .dropdownItem": {
    height : "80px",    
    cursor: "pointer",
    borderBottom: "1px solid #F5F5F5",
    color : "#F5F5F5",
    fontSize : "14px",
    fontWeight : 400,
    fontFamily : "Nexa-Regular",
    "&:hover": {
      backgroundColor: "#312F2D",
    },
  },
});
// Customizable Area End
