import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React, { createRef } from 'react'
import { getStorageData } from "framework/src/Utilities";
import { micImg } from "./assets";
import { next, prev } from "../../dashboard/src/assets";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    handleCommonPath:(path:string) => void;
}

interface ArrowProps {
    onClick: () => void;
  }

interface SS {
    id: any;
}

interface AddedAttributes {
    song_added: boolean;
  }

  interface IPodcastEpisode {
    song_id: string | number;
    name: string;
    description: string;
    endTime: string;
    songAdded: boolean;
    coverImage: string;
  }

  interface IplaylistInfo {
    title: string;
    image: string;
  }

  interface ISelectedPlaylist {
    id: string;
    cover_image: string ; 
    name: string;
    description: string;
    episode_time: string | null; 
  }

  interface IMainPlaylist {
    id: string;
    cover_image: string ; 
    name: string;
    description: string;
    episode_time: string | null; 
    songAdded: boolean;
  }
  
  
interface S {
    mainPlaylistInfo: IMainPlaylist[];
    selectedPlaylist: number | null;
    playlistInfo: IplaylistInfo | null;
    selectedPlaylistInfo: ISelectedPlaylist[];
    addSongId: null | number
}

export default class NewPlayListEditController extends BlockComponent<Props, S, SS> {
    getMainPlaylistApiCallId = ""
    getAddSongsApiCallId = ""
    getShowPlaylistApiCallId = ""
    scrollBox: any = createRef();

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.state = {
            mainPlaylistInfo: [],
            selectedPlaylist: null,
            playlistInfo: {
              title:"",
              image:""
            },
            selectedPlaylistInfo: [],
            addSongId: null,
        };
    
        this.subScribedMessages = [
          getName(MessageEnum.RestAPIResponceMessage),
        ];
        
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);


    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );

        if(this.getMainPlaylistApiCallId === apiRequestCallId) {
          this.handleMainPlaylistResponse(from, message)
        }

        if(this.getAddSongsApiCallId === apiRequestCallId) {
          this.handleAddsongResponse(from, message)
        }

        if(this.getShowPlaylistApiCallId === apiRequestCallId) {
          this.handleShowPlaylistResponse(from, message)
        }
    }

    }

    async componentDidMount(): Promise<void> {

        this.getMainPlaylist();
        this.showPlaylist();
        setTimeout(() => {
          if (this.scrollBox.current) {
            this.scrollBox.current.scrollTo({ top: 0, behavior: "smooth" });
          }
        }, 0);
    }

    handlePlaylistRowClick = (index: number) => {
    this.setState({ selectedPlaylist: index });
    }

    getSearchSlickSettings(){
        const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
    
        return(
           {
            dots: false,
            infinite: true,
            speed: 800,
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 0,
            nextArrow: React.createElement(this.nextSearchArrow, { onClick: () => {}, innerWidth }),
            // prevArrow: React.createElement(this.prevSearchArrow, { onClick: () => {}, innerWidth }),
            responsive: [
              {
                breakpoint: 1700, 
                settings: {
                  slidesToShow: 1, 
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 1920,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                }
              },
              {
                breakpoint: 1440,
                settings: {
                  slidesToScroll: 1,
                  slidesToShow: 1,
                }
              },
            ]
           }
        )
    }
    
    prevSearchArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
        <img
        src={prev} 
        onClick={onClick} 
        style={{
            position: 'absolute',
            left: innerWidth > 1440 ? "-21.5%" : "-0.5%",
            top: innerWidth > 1440 ? "55%" : "40%",
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            zIndex: 1,
        }} 
        alt="Previous Arrow"
        />
    )
    }
    
    nextSearchArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
        <img 
        src={next} 
        onClick={onClick}
        style={{
            position: 'absolute',
            right: innerWidth > 1440 ? "-11.5%" : "-5.5%",
            top: innerWidth > 1440 ? "55%" : "40%",
            transform: 'translateY(-50%)',
            cursor: 'pointer',
            zIndex: 1
        }} 
        alt="Next Arrow"
        />
    )
    }

    getMainPlaylist = async() => {

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
      
        this.getMainPlaylistApiCallId = requestMessage?.messageId

        const mainPlaylistId  = await getStorageData('main_playlist_id')  

        const token = await getStorageData('token')
    
        let cleanedToken = token.slice(1, -1);
        
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.songListApiEndPoint}/${mainPlaylistId}`
        );
      
        requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
            "Content-Type": configJSON.validationApiContentType,
            token: cleanedToken
        })
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
          );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleAddBtnClick = async(song_id: string | number, index: number) => {

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );


      const token = await getStorageData('token')
      
      let cleanedToken = token.slice(1, -1);

      this.getAddSongsApiCallId = requestMessage?.messageId

      const getPlaylistId  = await getStorageData('playlist_id')      

      let formdata = new FormData();
      formdata.append("id", getPlaylistId);
      formdata.append("song_id", song_id.toString())
    

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.addSong}`
      );
        
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
            token: cleanedToken
        })
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiPostMethod
    );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );

      this.setState({addSongId: index})
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleMainPlaylistResponse = async(from: string, message: Message) => {

      let apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiResponse?.playlist?.data?.id) {
        const filteredPlaylist: IMainPlaylist[] = 
        apiResponse?.playlist?.data?.attributes?.songs?.data?.map((item: any) => ({
          id: item.id,
          cover_image: item.attributes.cover_image || null,
          name: item.attributes.name || '',
          description: item.attributes.description || '',
          episode_time: item.attributes.episode_time || null,
          songAdded: item.attributes.added.song_added
        })) || [];

        this.setState({mainPlaylistInfo: filteredPlaylist})
        this.getMainPlaylistApiCallId = ""
      } 

    }

    handleAddsongResponse = async(from: string, message: Message) => {
      let apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiResponse?.message === "Song added successfully") {
        this.showAlert("Successs", apiResponse?.message);
        this.setState({addSongId: null})
        this.getMainPlaylist();
        this.showPlaylist();
      }
      else {
        this.setState({addSongId: null})
        this.showAlert("Error", "Song Not Added")
      }
    }

    showPlaylist = async() => {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.getShowPlaylistApiCallId = requestMessage?.messageId


      const getPlaylistId  = await getStorageData('playlist_id')     

      let playlistId = getPlaylistId;

      const token = await getStorageData('token')
  
      let cleanedToken = token.slice(1, -1);

      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.songListApiEndPoint}/${playlistId}`
      );
    
      requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
          "Content-Type": configJSON.validationApiContentType,
          token: cleanedToken
      })
      );

      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleShowPlaylistResponse = async(from: string, message: Message) => {
      let apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

        if(apiResponse.playlist.data.id) {

          this.setState({
            playlistInfo: {
              title: apiResponse?.playlist?.data?.attributes?.title || '',
              image: apiResponse?.playlist?.data?.attributes?.image || '',
            },
          });
          
          const filteredPlaylist: ISelectedPlaylist[] = 
            apiResponse?.playlist?.data?.attributes?.songs?.data?.map((item: any) => ({
              id: item.id,
              cover_image: item.attributes.cover_image || null,
              name: item.attributes.name || '',
              description: item.attributes.description || '',
              episode_time: item.attributes.episode_time || null,
            })) || [];
          
          this.setState({ selectedPlaylistInfo: filteredPlaylist })
        }
    }

    handleAllPlaylist = () => {
      this.props.handleCommonPath("Playlist")
    }
}
