// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Typography,
    Grid, Button, Switch, IconButton, TextField, Dialog, DialogContent, Divider, DialogActions,
} from "@mui/material";
import { Close } from '@mui/icons-material';
import MediaManagementNewContentController, {
    Props,
} from "./MediaManagementNewContentController";
import * as Yup from "yup";
import { Formik, Form, FieldArray } from "formik";
import { podcastCover, upload, goBack, tickIcon } from "./assets";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { handleNavigation, ResolveCondition } from "../../utilities/src/CustomHelpers";
import Footer from "../../navigationmenu/src/Footer.web";
import { ConditionJsx } from "../../utilities/src/ConditionJsx";
const MIN_WINDOW_HEIGHT = 900


export default class MediaManagementNewContent extends MediaManagementNewContentController {
    constructor(props: Props) {
        super(props);
    }
    validationSchema = Yup.object({
        additionalPhotos: Yup.boolean(),
    });


    render() {
        return (
            <>
                <Box ref={this.scrollref} sx={{ maxHeight: "100%", overflowY: "auto" }}>
                    <StyleWrapperSetupPodcastProfile>
                        <Box display="flex" alignItems="center" mb={3}>
                            <img onClick={this.handleGoBack} src={goBack} alt="Go Back" style={{ width: "2%", marginTop: "1%" }} />
                            <Typography variant="h4" sx={{ color: '#fff', marginBottom: '-10px', marginLeft: "20px", fontFamily: "Nexa-Heavy", fontSize: "30px", lineHeight: "40px" }}>
                                Media Management
                            </Typography>
                        </Box>
                        <Box >
                            <Box className="redContainer"></Box>
                            <Grid className="container">
                                <Formik
                                    initialValues={{
                                        ...this.state.formikInitialValue
                                    }}
                                    validationSchema={this.validationSchema}
                                    validateOnMount={true}
                                    enableReinitialize
                                    onSubmit={(values, { resetForm }) => {
                                        this.handleSubmit(values);
                                        resetForm()
                                    }}
                                >
                                    {({ handleChange, handleSubmit, values, errors, setFieldValue }) => (
                                        <Form onSubmit={handleSubmit}>
                                            <Box style={{ backgroundColor: "#44403C", width: "94%", marginLeft: "3%", marginTop: "2%", borderRadius: "8px", paddingBottom: "1%", boxSizing: "border-box" }}>
                                                <Box style={{ display: "flex", gap: "8%", marginBottom: "-1%" }}>
                                                    <Box style={{ marginTop: "4%" }}>
                                                        <label className="label" style={{ fontSize: "28px", lineHeight: "40px", marginLeft: "19%", whiteSpace: "nowrap", marginTop: "3%", fontFamily: "Nexa-Heavy" }}>Current Episode Cover:</label>
                                                    </Box>
                                                    <Box style={{ marginTop: "4%" }}>
                                                        <label className="label" style={{ fontSize: "28px", lineHeight: "40px", marginLeft: ResolveCondition((this.state.invalidFileMessage !== ""), "77%", ResolveCondition((this.state.imageUrl !== ""), "125%", "134%")), whiteSpace: "nowrap", fontFamily: "Nexa-Heavy" }}>New Cover Photo Upload?</label>
                                                        {ConditionJsx((this.state.invalidFileMessage !== ""), <label className="label" style={{ fontSize: "16px", lineHeight: "22px", marginLeft: "86%", whiteSpace: "nowrap", fontFamily: "Nexa-Regular", color: "#FF0807" }}>(Image size must be 3000x3000)</label>, <></>)}
                                                    </Box>
                                                </Box>
                                                <Box style={{ display: "flex", justifyContent: "space-between", gap: "15%" }}>
                                                    <Box sx={{
                                                        marginBottom: "2%",
                                                        height: "229px",
                                                        padding: '20px',
                                                        backgroundColor: 'transparent',
                                                        marginLeft: "3%"
                                                    }}>
                                                        <Box style={{ display: "flex" }}>
                                                            <img src={podcastCover} style={{ width: "240px", height: "227px" }} />
                                                            <Box style={{ marginTop: "13%", marginLeft: "5%", whiteSpace: "nowrap" }}>
                                                                <Typography style={{ color: "#fff", fontSize: "30px", lineHeight: "40px", fontFamily: "Nexa-Heavy" }}>The True Podcast</Typography>
                                                                <Typography style={{ color: "#fff", fontSize: "40px", lineHeight: "58px", fontFamily: "Nexa-Heavy" }}>COST OF WINNING</Typography>
                                                            </Box>
                                                        </Box>
                                                        <Typography style={{ color: "#fff", fontSize: "12px", lineHeight: "22px", fontFamily: "Nexa-Regular" }}>Podcast Runtime: 58:25</Typography>
                                                    </Box>
                                                    <Box style={{ display: "flex", marginRight: ResolveCondition((this.isImageValid()), "26%", "12%"), alignItems: "center", marginLeft: ResolveCondition((this.isImageValid()), "-10%", "0"), maxWidth: "70%" }}>
                                                        {ConditionJsx((this.state.invalidFileMessage === "" && this.state.imageUrl !== ""), <Box sx={{ position: 'relative', width: '206px', border: "1px solid white", height: "206px", borderRadius: "8px" }}>
                                                            <img src={this.state.imageUrl} alt="Preview" style={{ width: '190px', height: '190px', borderRadius: '5px', padding: "17px", margin: "4%" }} />
                                                            <IconButton
                                                                onClick={() => this.setState({ imageUrl: "" })}
                                                                sx={{ position: 'absolute', top: "11%", right: "10%", color: '#FFFFFF' }}
                                                                data-test-id="logo-url"
                                                            >
                                                                <Close />
                                                            </IconButton>
                                                        </Box>, <></>
                                                        )}
                                                        <Box
                                                            data-test-id="drop"
                                                            onDrop={(e) => { this.handleDrop(e, setFieldValue) }}
                                                            onDragOver={(e: any) => { e.preventDefault() }}
                                                            sx={{
                                                                margin: "auto",
                                                                marginLeft: ResolveCondition((this.isImageValid()), "4rem", "0%"),
                                                                marginTop: ResolveCondition((this.isImageValid()), "2%", "7%"),
                                                                marginBottom: "2%",
                                                                width: ResolveCondition((this.isImageValid()), "338px", "300px"),
                                                                height: ResolveCondition((this.isImageValid()), "205px", "300px"),
                                                                border: '2px dashed',
                                                                borderColor: ResolveCondition((this.state.invalidFileMessage !== ""), "#FF0807", "#F5F5F5"),
                                                                borderRadius: '10px',
                                                                padding: '20px',
                                                                textAlign: 'center',
                                                                cursor: 'pointer',
                                                                backgroundColor: '#312F2D',
                                                                backgroundSize: 'cover',
                                                                backgroundPosition: 'center',
                                                            }}
                                                        >
                                                            <Box style={{ marginTop: ResolveCondition((this.isImageValid()), "-21%", "0") }}><input
                                                                type="file"
                                                                accept="image/*"
                                                                multiple
                                                                style={{ display: 'none' }}
                                                                id="file-input"
                                                                data-test-id="selectFile"
                                                                onChange={(e) => { this.handleFileSelect(e, setFieldValue) }}
                                                            />
                                                                <label htmlFor="file-input">
                                                                    <Box sx={{ marginTop: "30%", lineHeight: 2 }}>
                                                                        <img src={upload} alt="upload" />
                                                                        <Box className="title">
                                                                            Drag & Drop your Photo <br />OR
                                                                        </Box>
                                                                        <Button className="btnBrowse" component="span">
                                                                            Browse file
                                                                        </Button>
                                                                    </Box>
                                                                </label>
                                                            </Box>

                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Typography style={{ color: "#fff", fontSize: "12px", lineHeight: "22px", fontFamily: "Nexa-Regular", marginLeft: ResolveCondition((this.isImageValid()), "61%", "62%"), maxWidth: "30%", marginBottom: "2%", textTransform: 'capitalize', marginTop: "1%", textAlign: "center" }}>If You Do Not Upload a New Podcast Episode Cover Photo, the Current Episode Cover Photo will be the default Episode Cover Photo</Typography>
                                            </Box>

                                            <Box style={{ marginTop: "3%" }}>
                                                <Typography style={{ color: "#fff", fontSize: "30px", lineHeight: "40px", fontFamily: "Nexa-Heavy", marginLeft: "3%" }}>Chapters:</Typography>
                                                <Box style={{ display: "flex" }}>
                                                    <Typography style={{ color: "#fff", fontSize: "16px", lineHeight: "22px", fontFamily: "Nexa-Heavy", marginLeft: "7%", maxWidth: "23.5%", marginTop: "1%", textTransform: 'capitalize' }}>Do you want to insert any additional photos into your podcast at certain times?
                                                        (Maximum of 20)</Typography>
                                                    <Switch
                                                        checked={values.additionalPhotos}
                                                        data-test-id="additionalPhotos"
                                                        onChange={() => setFieldValue('additionalPhotos', !values.additionalPhotos)}
                                                        value={values.additionalPhotos}
                                                        name="additionalPhotos"
                                                        color="default"
                                                        sx={{
                                                            marginLeft: "2%",
                                                            width: "80px",
                                                            height: "50px",
                                                            marginTop: "2%",
                                                            "& .MuiSwitch-switchBase.Mui-checked": {
                                                                transform: "translateX(16px)",
                                                                color: "#fff",
                                                            },
                                                            "& .MuiSwitch-switchBase": {
                                                                color: "#fff",
                                                                position: "absolute",
                                                                top: "6px",
                                                                left: ResolveCondition(values.additionalPhotos, "18px", "8px"),
                                                            },
                                                            "& .MuiSwitch-thumb": {
                                                                width: "20px",
                                                                height: "20px",
                                                                backgroundColor: "#fff",
                                                            },
                                                            "& .MuiSwitch-track": {
                                                                backgroundColor: ResolveCondition(values.additionalPhotos, "rgb(255, 8, 7)", "#ccc"),
                                                                opacity: ResolveCondition(!values.additionalPhotos, "0.5 ", "1 !important"),
                                                                borderRadius: "16px",
                                                            },
                                                        }}
                                                    />
                                                </Box>
                                            </Box>

                                            {values.additionalPhotos && (
                                                <>
                                                    <Typography style={{ color: "#fff", fontSize: "30px", lineHeight: "40px", fontFamily: "Nexa-Heavy", marginLeft: "3%", textTransform: "capitalize", marginTop: '7%', marginBottom: "5%" }}>Additional photos at certain times:</Typography>
                                                    <FieldArray name="chapters" data-test-id="chapters-field">
                                                        {({ push, remove }) => (
                                                            <>
                                                                {values.chapters.map((chapter, index) => (
                                                                    <Box key={index} mb={5} className={ResolveCondition(index >= 1, "newHostBg", "")}
                                                                        sx={{ position: 'relative', padding: '20px', width: ResolveCondition(index >= 1, "94%", "65%"), borderRadius: ResolveCondition(index >= 1, "8px", ""), marginLeft: ResolveCondition(index >= 1, "3%", "") }}>

                                                                        <Box style={{ width: "100%", marginTop: ResolveCondition([0].includes(index), "-8%", "auto") }}>

                                                                            {index > 0 && (
                                                                                <IconButton
                                                                                    data-test-id="iconButton"
                                                                                    onClick={() => remove(index)}
                                                                                    sx={{
                                                                                        position: 'absolute',
                                                                                        top: 0,
                                                                                        right: 0,
                                                                                        color: 'white',
                                                                                    }}
                                                                                >
                                                                                    <Close />
                                                                                </IconButton>
                                                                            )}

                                                                            <Box>
                                                                                <Box>
                                                                                    <Typography style={{ color: "#fff", fontSize: "14px", lineHeight: "22px", fontFamily: "Nexa-Heavy", marginLeft: ResolveCondition(index >= 1, "3%", "8%"), textTransform: "capitalize", marginTop: ResolveCondition(index >= 1, "1%", "4%") }}>Add Another Chapter</Typography>
                                                                                    {ConditionJsx((this.state.invalidChapterMessage[index] !== ""), <label className="label" style={{ fontSize: "16px", lineHeight: "22px", whiteSpace: "nowrap", fontFamily: "Nexa-Regular", color: "#FF0807", marginLeft: ResolveCondition(index > 0, "3%", "8%") }}>(Image size must be 3000x3000)</label>, <></>)}
                                                                                    <Box style={{ display: "flex" }}>
                                                                                        <Box
                                                                                            data-test-id="drop"
                                                                                            onDrop={(e) => { this.handleDrop(e, setFieldValue, index) }}
                                                                                            onDragOver={(e: any) => { e.preventDefault() }}
                                                                                            sx={{
                                                                                                margin: "auto",
                                                                                                marginTop: ResolveCondition(index >= 1, "1%", "2%"),
                                                                                                marginBottom: "2%",
                                                                                                marginLeft: ResolveCondition(index >= 1, "3%", "8%"),
                                                                                                width: ResolveCondition(index >= 1, "23%", "35%"),
                                                                                                height: "205px",
                                                                                                border: '2px dashed',
                                                                                                borderColor: ResolveCondition(this.state.invalidChapterMessage[index] === "", "#F5F5F5", "#FF0807"),
                                                                                                borderRadius: '10px',
                                                                                                padding: '20px',
                                                                                                textAlign: 'center',
                                                                                                cursor: 'pointer',
                                                                                                backgroundColor: '#312F2D',
                                                                                                backgroundPosition: 'center',
                                                                                                backgroundImage: ResolveCondition(this.state.additionalImages[index] != "", `url(${this.state.additionalImages[index]})`, "none"),
                                                                                                backgroundSize: '50% auto',
                                                                                                backgroundRepeat: 'no-repeat'
                                                                                            }}
                                                                                        >
                                                                                            {(this.state.additionalImages[index]) && (
                                                                                                <IconButton data-test-id="icon-btn" style={{ marginLeft: "90%", marginTop: "-4%" }} onClick={() => {
                                                                                                    setFieldValue(`chapters[${index}].newChapterImage`, "");
                                                                                                    this.setState((prevState) => {
                                                                                                        const updatedImages = [...prevState.additionalImages];
                                                                                                        updatedImages[index] = "";
                                                                                                        return { additionalImages: updatedImages };
                                                                                                    });
                                                                                                }}><Close style={{ color: "#fff" }} /></IconButton>
                                                                                            )}
                                                                                            {ConditionJsx((!this.state.additionalImages[index]), <Box style={{ marginLeft: "2%" }}><input
                                                                                                type="file"
                                                                                                accept="image/*"
                                                                                                multiple
                                                                                                style={{ display: 'none' }}
                                                                                                id={`file-input-new-${index}`}
                                                                                                data-test-id="selectFile"
                                                                                                onChange={(e) => { this.handleFileSelect(e, setFieldValue, index) }}
                                                                                            />
                                                                                                <label htmlFor={`file-input-new-${index}`}>
                                                                                                    <Box sx={{ marginTop: "10%", lineHeight: 2 }}>
                                                                                                        <img src={upload} alt="upload" />
                                                                                                        <Box className="title">
                                                                                                            Drag & Drop your file here <br />OR
                                                                                                        </Box>
                                                                                                        <Button className="btnBrowse" component="span">
                                                                                                            Browse file
                                                                                                        </Button>
                                                                                                    </Box>
                                                                                                </label>
                                                                                            </Box>, <></>)}
                                                                                        </Box>
                                                                                        <Box style={{ width: "40%", marginRight: ResolveCondition(index >= 1, "20%", "") }}>
                                                                                            <Typography style={{ color: "#fff", fontSize: "14px", lineHeight: "22px", fontFamily: "Nexa-Heavy", marginLeft: "-28%", textTransform: "capitalize", marginTop: ResolveCondition(index >= 1, "2%", "5%") }}>Start Time:</Typography>
                                                                                            <TextField
                                                                                                name={`chapters[${index}]startTime`}
                                                                                                id="outlined-basic"
                                                                                                type="time"
                                                                                                onChange={handleChange}
                                                                                                value={values.chapters[index].startTime || "00:00:00"}
                                                                                                sx={{
                                                                                                    marginTop: "2%",
                                                                                                    opacity: "0.5",
                                                                                                    width: ResolveCondition(index >= 1, "80%", "114%"),
                                                                                                    borderRadius: "8px",
                                                                                                    marginLeft: "-28%",

                                                                                                    "& .MuiInputBase-input": {

                                                                                                        border: "1px solid rgb(203, 213, 225)",
                                                                                                        borderRadius: "4px",
                                                                                                        color: "#CBD5E1",
                                                                                                        backgroundColor: "black",
                                                                                                        "&:-webkit-autofill": {
                                                                                                            backgroundColor: "black !important",
                                                                                                            WebkitBoxShadow: "0 0 0 1000px black inset",
                                                                                                            WebkitTextFillColor: "#CBD5E1 !important",
                                                                                                        },
                                                                                                    },
                                                                                                    "& .MuiInputLabel-root": {
                                                                                                        color: "#CBD5E1",
                                                                                                    },

                                                                                                    "& .MuiOutlinedInput-root": {

                                                                                                        "&.Mui-focused fieldset": {
                                                                                                            borderColor: "#CBD5E1",
                                                                                                        },

                                                                                                        height: "56px",
                                                                                                    },
                                                                                                }}
                                                                                            />
                                                                                            <Typography style={{ color: "#fff", fontSize: "14px", lineHeight: "22px", fontFamily: "Nexa-Heavy", marginLeft: "-28%", textTransform: "capitalize", marginTop: ResolveCondition(index >= 1, "4%", "7%") }}>End Time:</Typography>
                                                                                            <TextField
                                                                                                id="outlined-basic"
                                                                                                name={`chapters[${index}]endTime`}
                                                                                                type="time"
                                                                                                onChange={handleChange}
                                                                                                value={values.chapters[index].endTime || "00:00:00"}
                                                                                                sx={{
                                                                                                    opacity: "0.5",
                                                                                                    marginTop: "2%",
                                                                                                    width: ResolveCondition(index >= 1, "80%", "114%"),
                                                                                                    marginLeft: "-28%",
                                                                                                    borderRadius: "8px",
                                                                                                    "& .MuiInputLabel-root": {
                                                                                                        color: "#CBD5E1",
                                                                                                    },
                                                                                                    "& .MuiInputBase-input": {

                                                                                                        border: "1px solid rgb(203, 213, 225)",
                                                                                                        borderRadius: "4px",
                                                                                                        color: "#CBD5E1",
                                                                                                        backgroundColor: "black",
                                                                                                        "&:-webkit-autofill": {
                                                                                                            backgroundColor: "black !important",
                                                                                                            WebkitBoxShadow: "0 0 0 1000px black inset",
                                                                                                            WebkitTextFillColor: "#CBD5E1 !important",
                                                                                                        },
                                                                                                    },

                                                                                                    "& .MuiOutlinedInput-root": {

                                                                                                        "&.Mui-focused fieldset": {
                                                                                                            borderColor: "#CBD5E1",
                                                                                                        },

                                                                                                        height: "56px",
                                                                                                    },
                                                                                                }}
                                                                                            />
                                                                                        </Box>
                                                                                    </Box>
                                                                                </Box>
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                ))}

                                                                <Grid className="fields">
                                                                    <Grid item xs={6} style={{ marginTop: "9%" }}>
                                                                        <Button
                                                                            style={{ color: "white", textTransform: "capitalize", marginBottom: ResolveCondition((!values.additionalPhotos), "7%", "3%") }}
                                                                            data-test-id="submit-btn"
                                                                            className={ResolveCondition(values.chapters.some(
                                                                                (chapter, index) =>
                                                                                    !chapter.newChapterImage ||
                                                                                    !chapter.startTime ||
                                                                                    !chapter.endTime ||
                                                                                    (this.state.invalidChapterMessage[index] !== "")
                                                                            ), "btnSignUp", "btnSelectedSignUp")}
                                                                            disabled={
                                                                                values.chapters.some(
                                                                                    (chapter, index) =>
                                                                                        !chapter.newChapterImage ||
                                                                                        !chapter.startTime ||
                                                                                        !chapter.endTime ||
                                                                                        (this.state.invalidChapterMessage[index] !== "")
                                                                                )
                                                                            }
                                                                            type="submit"
                                                                        >
                                                                            Upload
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                                <Button
                                                                    style={{ color: "white", textTransform: "capitalize" }}
                                                                    data-test-id="add-chapter-btn"
                                                                    className={ResolveCondition((values.chapters.length == 20), "addAnotherHost", "addAnotherHostBtn")}
                                                                    disabled={values.chapters.length == 20}
                                                                    fullWidth
                                                                    onClick={() => {
                                                                        push({ newChapterImage: "", startTime: "", endTime: "" });
                                                                    }}
                                                                >
                                                                    Add Another Chapter
                                                                </Button>
                                                            </>
                                                        )}
                                                    </FieldArray>
                                                </>
                                            )}
                                            {ConditionJsx((!values.additionalPhotos), <Grid className="fields">
                                                <Grid item xs={6} style={{ marginTop: "9%" }}>
                                                    <Button
                                                        style={{ color: "white", textTransform: "capitalize", marginBottom: ResolveCondition((!values.additionalPhotos), "7%", "3%") }}
                                                        data-test-id="submit-btn"
                                                        className={ResolveCondition(this.state.invalidFileMessage !== "" || this.state.imageUrl === "", "btnSignUp", "btnSelectedSignUp")}
                                                        disabled={this.state.invalidFileMessage !== "" || this.state.imageUrl === ""}
                                                        type="submit"
                                                    >
                                                        Upload
                                                    </Button>
                                                </Grid>
                                            </Grid>, <></>
                                            )}

                                        </Form>
                                    )}
                                </Formik>
                            </Grid>
                        </Box>
                    </StyleWrapperSetupPodcastProfile>
                    <Footer navigation={this.props.navigation} id={"1"} />
                </Box>
                    <Dialog open={this.state.openFirstDialog} onClose={this.handleCloseFirstDialog} PaperProps={{
                        style: {
                            width: '27%',
                            margin: '0 auto',
                            borderBottomRightRadius: '30px',
                            height: '36%'
                        },
                    }}
                        componentsProps={{
                            backdrop: {
                                style: {
                                    backgroundColor: 'rgba(0, 0, 0, 0.3)',
                                    backdropFilter: 'blur(4px)',
                                },
                            },
                        }}>
                        <DialogContent sx={{ textAlign: "center", backgroundColor: "#44403C" }}>
                            <img src={tickIcon} style={{ marginTop: "5%" }} />
                            <Typography variant="h6" align="center" style={{ color: "#fff", fontFamily: 'Nexa-Heavy', maxWidth: "100%" }} gutterBottom>
                                Your episode has been scheduled to publish to your channel!
                            </Typography>
                            <Typography variant="h6" align="center" style={{ color: "#fff", fontFamily: 'Nexa-Heavy' }} gutterBottom>
                                What would you like to do next?
                            </Typography>
                        </DialogContent>
                        <Divider />
                        <DialogActions sx={{ justifyContent: 'center', backgroundColor: "#44403C", height: '25%' }}>
                            <Button onClick={() => { }} variant="contained" style={{ backgroundColor: "#000000", textTransform: "capitalize", fontFamily: 'Nexa-Heavy', width: "150px", height: "45px", borderRadius: "5px", whiteSpace: "nowrap" }} data-test-id="copy-link">
                                Copy Episode Link
                            </Button>
                            <Button onClick={() => { this.props.handleCommonPath('MediaManagement') }} variant="contained" style={{ backgroundColor: "#FF0807", textTransform: "capitalize", fontFamily: 'Nexa-Heavy', width: "155px", height: "45px", borderRadius: "5px", whiteSpace: "nowrap" }} data-test-id="media-management">
                                Media Management
                            </Button>
                        </DialogActions>
                    </Dialog>
            </>

        );
    }

}
const StyleWrapperSetupPodcastProfile = styled('div')({
    height: "auto",
    padding:"20px 50px",
    background: "#1C1917",
    paddingBottom: "7%",
    "& .subContain": {
        position: "relative",
        paddingRight: "40px",
        height: "47rem",
        overflow: "hidden",
        scrollbarWidth: "thin",
        overflowY: "scroll",
        scrollbarColor: "#FF0807 #4D4D4D",
    },
    "& .btnSignUp": {
        cursor: "pointer",
        fontFamily: "Nexa-Heavy",
        height: "56px",
        background: "#B2B3B3",
        color: "white",
        margin: "auto",
        display: "flex",
        borderRadius: "8px",
        marginTop: "8%",
        fontWeight: 900,
        width: "578px",
        fontSize: "14px",
        marginLeft: "32%"
    },
    "& .addAnotherHost": {
        cursor: "pointer",
        fontFamily: "Nexa-Heavy",
        height: "56px",
        background: "#B2B3B3",
        color: "white",
        margin: "auto",
        display: "flex",
        borderRadius: "8px",
        marginTop: "2%",
        marginBottom: "7%",
        fontWeight: 900,
        width: "578px",
        fontSize: "14px",
        marginLeft: "32%"
    },
    "& *": {
        boxSizing: "border-box",
    },
    "& .field": {
        width: "954px",
        height: "56px",
    },

    "& .btnSelectedSignUp": {
        cursor: "pointer",
        fontFamily: "Nexa-Heavy",
        height: "56px",
        color: "white",
        margin: "auto",
        display: "flex",
        borderRadius: "8px",
        marginTop: "8%",
        fontWeight: 900,
        width: "578px",
        fontSize: "14px",
        background: "#FF0807",
        marginLeft: "32%",
        "&:hover": {
            backgroundColor: "#FF0807",
        }
    },
    "& .addAnotherHostBtn": {
        cursor: "pointer",
        fontFamily: "Nexa-Heavy",
        height: "56px",
        color: "white",
        margin: "auto",
        display: "flex",
        borderRadius: "8px",
        marginTop: "2%",
        marginBottom: "7%",
        fontWeight: 900,
        width: "578px",
        fontSize: "14px",
        background: "#030303",
        marginLeft: "32%",
        "&:hover": {
            backgroundColor: "#030303",
        }
    },
    "& .title": {
        color: "white",
        fontFamily: "Nexa-Heavy",
        textTransform: "capitalize",
        paddingTop: "1%"
    },
    "& .btnBrowse": {
        height: "26px",
        width: "110px",
        fontFamily: "Nexa-Heavy",
        background: "#FF0807",
        color: "white",
        textTransform: "capitalize",
        borderRadius: "8px",
        fontSize: "10px"
    },
    "& .redContainer": {
        height: "80px",
        margin: 0,
        borderRadius: "16px 16px 0px 0px",
        backgroundColor: "#FF0807",
    },
    "& .label": {
        fontFamily: "Nexa-Heavy",
        color: "white",
        fontSize: "14px",
        fontWeight: 700,
    },
    "& .mainTitle": {
        margin: "auto",
        height: "40px",
        color: "white",
        fontFamily: "Nexa-Heavy",
        fontWeight: 900,
        fontSize: "30px",
        marginBottom: "21px"
    },
    "& .container": {
        height: "auto",
        backgroundColor: "#312F2D",
        display: "flex",
        flexDirection: "column",
        margin: "auto",
        borderRadius: "0px 0px 8px 8px"
    },
    "& .boxSelected": {
        background: "#FF0807 !important"
    },
    "& .boxContain": {
        background: "black",
        height: "289px",
        width: "320px",
        borderRadius: "16px",
        margin: "8px"
    },
    "& .boxContainer": {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        marginTop: "6%",
        marginBottom: "8%"
    },
    "& .label1": {
        fontFamily: "Nexa-Heavy",
        fontWeight: 900,
        fontSize: "18px",
        color: "white",
        textAlign: "center",
        padding: "6% 14%"
    },
    "& .boxInside": {
        background: "#B2B3B3",
        height: "223px",
        width: "320px",
        borderRadius: "15px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer"
    },
    "& .newHostBg": {
        backgroundColor: "#44403C"
    },
    "& .heading": {
        fontFamily: "Nexa-Heavy",
        color: "white",
        fontSize: "24px",
        marginTop: "2%",
        marginBottom: "4%"
    },
    "& .hostBtn": {
        height: "56px",
        width: "392px",
        background: "black",
        color: "white",
        textTransform: "capitalize",
        fontFamily: "Nexa-Heavy",
        fontWeight: 900,
        margin: "auto",
        fontSize: "14px",
        display: " flex",
        marginTop: "15%",
        marginBottom: "3%",
        borderRadius: "8px",
        "&:hover": {
            background: "black",
            color: "white"
        },
    },
    "& .hostBtnDisabled": {
        backgroundColor: "#B2B3B3 !important",
        color: "white !important",
        height: "56px",
        width: "392px",
        margin: "auto",
        fontSize: "14px",
        display: " flex",
        marginTop: "15%",
        marginBottom: "3%",
        textTransform: "capitalize",
        fontFamily: "Nexa-Heavy",
        fontWeight: 900,
        borderRadius: "8px"
    },
})    
// Customizable Area End
