// Customizable Area Start
import React from "react";
import {
  Box,
  styled,
  Button,
  Grid,
  MenuItem, FormControl, Typography, Select
} from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import SetUpProfileSecondScreenControllerNoRSS, {
  Props,
} from "./SetUpProfileSecondScreenControllerNoRss";
import { goBack, image_group, image_group_1 } from "./assets";
import { ResolveCondition, handleNavigation } from "../../utilities/src/CustomHelpers";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
const MIN_WINDOW_HEIGHT = 900

export default class SetUpProfileSecondScreenNoRSS extends SetUpProfileSecondScreenControllerNoRSS {
  constructor(props: Props) {
    super(props);
  }

  validationSchema = Yup.object().shape({
    formattedShow: Yup.string().required("Required"),
    explicitLanguage: Yup.string().required("Required"),
    restriction: Yup.string().required("Required"),
    timezone: Yup.string().required("Required"),
    publishTime: Yup.string().required("Required"),
    selectedId: Yup.number().required("Required"),
  });
  render() {
    return (
      <>
      <Box ref={this.boxScrollRef} sx={{ maxHeight: "100%", overflowY: "auto" }}>
       <StyleWrapperSetupPodcastProfile>
        <Box className="mainTitle"><img src={goBack} alt="goBack" style={{ marginRight: "20px" }} data-test-id="navigate" onClick={() => { handleNavigation("SetupPodcastProfileNoRssFeed", this.props) }} />Setup Podcast Profile</Box>
        <Box  id="secScreen">
        <Box className="redContainer"></Box>
        <Grid className="container">
          <Grid className="subContainer">

                <Formik
                  initialValues={{
                    formattedShow: '',
                    explicitLanguage: '',
                    restriction: '',
                    timezone: '',
                    publishTime: '',
                  }}
                  data-test-id="FormikWrapper"
                  validationSchema={this.validationSchema}
                  validateOnMount={true}
                  onSubmit={(values) => {
                    this.handleSubmitSecondScreen(values);
                  }}
                >
                  {({ handleSubmit, setFieldValue, errors, touched }) => (
                    <Form onSubmit={handleSubmit} >
                      <Grid className="fields">

                        <Grid className="fields" style={{ width: "954px", }}>
                          <Grid container spacing={2} sx={{ marginTop: "4%" }}>
                            <Grid item xs={6}>
                              <Box sx={{ marginBottom: "8px" }}>
                                <Typography variant="body2" className="label">How is your show formatted?</Typography>
                              </Box>
                              
                              <FormControl fullWidth variant="outlined"
                                id="show formatted"
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "#FFFFFF",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#FFFFFF",
                                    },
                                    "& .MuiSelect-select": {
                                      color: "#FFFFFF",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#FFFFFF",
                                    },
                                  },
                                  "& .MuiSvgIcon-root": {
                                    color: "#FFFFFF",
                                  },
                                  "& .MuiInputLabel-root": {
                                    color: "transparent",
                                  },
                                }}>
                                <Select
                                  id="show formatted"
                                  name="formattedShow"
                                  onChange={(e) => { setFieldValue('formattedShow', e.target.value) }}
                                  displayEmpty
                                  defaultValue=""
                                  data-test-id="formattedShow"
                                  IconComponent={(props) => (
                                    <KeyboardArrowDownIcon {...props} sx={{ color: "#FFFFFF" }} />
                                  )}
                                  MenuProps={{
                                    PaperProps: {
                                      sx: {
                                        maxHeight: 250,
                                        overflowY: 'auto',
                                        backgroundColor: 'black',
                                        "& .MuiMenuItem-root": {
                                          color: "#FFFFFF",
                                        },
                                        "& .Mui-selected": {
                                          backgroundColor: "#333333 !important",
                                        },
                                      },
                                    },
                                  }}
                                  sx={{ color: "#FFFFFF", backgroundColor: 'black' }}
                                >

                                  <MenuItem value="episodic">Episodic</MenuItem>
                                  <MenuItem value="episodic w/seasons">Episodic w/seasons</MenuItem>
                                  <MenuItem value="serial">Serial</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                              <Box sx={{ marginBottom: "8px" }}>
                                <Typography variant="body2" className="label">Does Your Channel Have Explicit Language?</Typography>
                              </Box>
                              <FormControl fullWidth variant="outlined"
                                id="Explicit Language"
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "#FFFFFF",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#FFFFFF",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#FFFFFF",
                                    },
                                    "& .MuiSelect-select": {
                                      color: "#FFFFFF",
                                    },
                                  },
                                  "& .MuiSvgIcon-root": {
                                    color: "#FFFFFF",
                                  },
                                  "& .MuiInputLabel-root": {
                                    color: "transparent",
                                  },
                                }}>
                                <Select
                                  id="Explicit Language"
                                  name="explicitLanguage"
                                  onChange={(e) => { setFieldValue('explicitLanguage', e.target.value) }}
                                  displayEmpty
                                  defaultValue=""
                                  data-test-id="explicitLanguage"
                                  IconComponent={(props) => (
                                    <KeyboardArrowDownIcon {...props} sx={{ color: "#FFFFFF" }} id="explicitLanguage" />
                                  )}
                                  MenuProps={{
                                    PaperProps: {
                                      sx: {
                                        maxHeight: 250,
                                        overflowY: 'auto',
                                        "& .MuiMenuItem-root": {
                                          color: "#FFFFFF",
                                        },
                                        backgroundColor: 'black',
                                        "& .Mui-selected": {
                                          backgroundColor: "#333333 !important",
                                        },
                                      },
                                    },
                                  }}
                                  sx={{
                                    color: "#FFFFFF",
                                    backgroundColor: 'black',
                                  }}
                                >
                                  <MenuItem value="yes">Yes</MenuItem>
                                  <MenuItem value="no">No</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>

                          </Grid>
                        </Grid>
                        <Grid className="fields" id="restriction">
                          <Grid container spacing={2}
                            sx={{ marginTop: "4%" }}>
                            <Grid item xs={6}>
                              <Box sx={{ marginBottom: "8px" }}>
                                <Typography variant="body2" className="label">Do you want to add an 18+ age restriction to your channel?</Typography>
                              </Box>
                              <FormControl fullWidth variant="outlined"
                                id="restriction"
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "#FFFFFF",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#FFFFFF",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#FFFFFF",
                                    },
                                    "& .MuiSelect-select": {
                                      color: "#FFFFFF",
                                    },
                                  },
                                  "& .MuiInputLabel-root": {
                                    color: "transparent",
                                  },
                                  "& .MuiSvgIcon-root": {
                                    color: "#FFFFFF",
                                  },
                                }}>
                                <Select
                                  name="restriction"
                                  onChange={(e) => { setFieldValue('restriction', e.target.value) }}
                                  displayEmpty
                                  defaultValue=""
                                  data-test-id="restriction"
                                  IconComponent={(props) => (
                                    <KeyboardArrowDownIcon {...props} sx={{ color: "#FFFFFF" }} />
                                  )}
                                  id="restriction"
                                  MenuProps={{
                                    PaperProps: {
                                      sx: {
                                        overflowY: 'auto',
                                        maxHeight: 250,
                                        backgroundColor: 'black',
                                        "& .MuiMenuItem-root": {
                                          color: "#FFFFFF",
                                        },
                                        "& .Mui-selected": {
                                          backgroundColor: "#333333 !important",
                                        },
                                      },
                                    },
                                  }}
                                  sx={{ color: "#FFFFFF", backgroundColor: 'black' }}
                                >
                                  <MenuItem value="yes">Yes</MenuItem>
                                  <MenuItem value="no">No</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                              <Box sx={{ marginBottom: "8px" }}>
                                <Typography variant="body2" className="label">What timezone is you podcast in?</Typography>
                              </Box>
                              <FormControl fullWidth variant="outlined"
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "#FFFFFF",
                                    },
                                    "& .MuiSvgIcon-root": {
                                      color: "#FFFFFF",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#FFFFFF",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#FFFFFF",
                                    },
                                    "& .MuiSelect-select": {
                                      color: "#FFFFFF",
                                    },
                                  },
                                  "& .MuiInputLabel-root": {
                                    color: "transparent",
                                  },

                                }}>
                                <Select
                                  name="timezone"
                                  onChange={(e) => { setFieldValue('timezone', e.target.value) }}
                                  displayEmpty
                                  defaultValue=""
                                  data-test-id="timezone"
                                  IconComponent={(props) => (
                                    <KeyboardArrowDownIcon {...props} sx={{ color: "#FFFFFF" }} />
                                  )}
                                  id="timezone"
                                  MenuProps={{
                                    PaperProps: {
                                      sx: {
                                        overflowY: 'auto',
                                        maxHeight: 250,
                                        backgroundColor: 'black',
                                        "& .MuiMenuItem-root": {
                                          color: "#FFFFFF",
                                        },
                                        "& .Mui-selected": {
                                          backgroundColor: "#333333 !important",
                                        },
                                      },
                                    },
                                  }}
                                  sx={{ color: "#FFFFFF", backgroundColor: 'black' }}>
                                  {this.abbreviationsArray.map((abbr,index) => (
                                  <MenuItem value={abbr}>{abbr}</MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>

                          </Grid>
                        </Grid>


                        <Grid className="fields">
                          <Grid container spacing={2} sx={{ marginTop: "4%" }}>

                            <Grid item xs={6}>
                              <Box sx={{ marginBottom: "8px" }}>
                                <Typography variant="body2" className="label">Default publish time</Typography>
                              </Box>
                              <FormControl
                                fullWidth
                                variant="outlined"
                                sx={{
                                  "& .MuiOutlinedInput-root": {
                                    "& fieldset": {
                                      borderColor: "#FFFFFF",
                                    },
                                    "&:hover fieldset": {
                                      borderColor: "#FFFFFF",
                                    },
                                    "&.Mui-focused fieldset": {
                                      borderColor: "#FFFFFF",
                                    },
                                    "& .MuiSelect-select": {
                                      color: "#FFFFFF",
                                    },
                                  },
                                  "& .MuiSvgIcon-root": {
                                    color: "#FFFFFF",
                                  },
                                  "& .MuiInputLabel-root": {
                                    color: "transparent",
                                  },
                                }}>
                                <Select
                                  name="publishTime"
                                  onChange={(e) => { setFieldValue('publishTime', e.target.value) }}
                                  displayEmpty
                                  defaultValue=""
                                  data-test-id="publishTime"
                                  IconComponent={(props) => (
                                    <KeyboardArrowDownIcon {...props} sx={{ color: "#FFFFFF" }} />
                                  )}
                                  MenuProps={{
                                    PaperProps: {
                                      sx: {
                                        maxHeight: 250,
                                        overflowY: 'auto',
                                        backgroundColor: 'black',
                                        "& .MuiMenuItem-root": {
                                          color: "#FFFFFF",
                                        },
                                        "& .Mui-selected": {
                                          backgroundColor: "#333333 !important",
                                        },
                                      },
                                    },
                                  }}
                                  sx={{
                                    color: "#FFFFFF",
                                    backgroundColor: 'black'
                                  }}
                                >
                                  <MenuItem value="12am">12:00 AM</MenuItem>
                                  <MenuItem value="1am">1:00 AM</MenuItem>
                                  <MenuItem value="2am">2:00 AM</MenuItem>
                                  <MenuItem value="3am">3:00 AM</MenuItem>
                                  <MenuItem value="4am">4:00 AM</MenuItem>
                                  <MenuItem value="5am">5:00 AM</MenuItem>
                                  <MenuItem value="6am">6:00 AM</MenuItem>
                                  <MenuItem value="7am">7:00 AM</MenuItem>
                                  <MenuItem value="8am">8:00 AM</MenuItem>
                                  <MenuItem value="9am">9:00 AM</MenuItem>
                                  <MenuItem value="10am">10:00 AM</MenuItem>
                                  <MenuItem value="11am">11:00 AM</MenuItem>
                                  <MenuItem value="12pm">12:00 PM</MenuItem>
                                  <MenuItem value="1pm">1:00 PM</MenuItem>
                                  <MenuItem value="2pm">2:00 PM</MenuItem>
                                  <MenuItem value="3pm">3:00 PM</MenuItem>
                                  <MenuItem value="4pm">4:00 PM</MenuItem>
                                  <MenuItem value="5pm">5:00 PM</MenuItem>
                                  <MenuItem value="6pm">6:00 PM</MenuItem>
                                  <MenuItem value="7pm">7:00 PM</MenuItem>
                                  <MenuItem value="8pm">8:00 PM</MenuItem>
                                  <MenuItem value="9pm">9:00 PM</MenuItem>
                                  <MenuItem value="10pm">10:00 PM</MenuItem>
                                  <MenuItem value="11pm">11:00 PM</MenuItem>
                                </Select>
                              </FormControl>
                            </Grid>

                          </Grid>

                          <Box sx={{ marginTop: "5%" }}>
                            <Typography variant="body2" className="label">Is your show hosted solo or co-hosted?</Typography>
                          </Box>

                          <Grid className="boxContainer">
                            <Grid data-test-id="click1" onClick={
                              () => {
                                this.handleBoxClick(1)
                                setFieldValue('selectedId', 1)
                              }
                            }>
                              <Box className="boxContain">
                                <Box className={`boxInside ${ResolveCondition(this.state.selectedId === 1, "boxSelected", "")}`}>
                                  <img src={image_group} alt="ONE-HOST" className="img" />
                                </Box>
                                <div className="label1">ONE HOST</div>
                              </Box>
                            </Grid>

                            <Grid data-test-id="click2" onClick={() => { this.handleBoxClick(2); setFieldValue('selectedId', 2) }}>
                              <Box className="boxContain">
                                <Box className={`boxInside ${ResolveCondition(this.state.selectedId === 2, "boxSelected", "")}`}>
                                  <img src={image_group_1} alt="CO-HOSTED" className="img" />
                                </Box>
                                <div className="label1">CO-HOSTED</div>
                              </Box>
                            </Grid>
                          </Grid>

                        </Grid>


                        <Grid className="fields">
                          <Grid item xs={6}>
                            <Button
                              type="submit"
                              className={ResolveCondition(Object.keys(errors || {}).length > 0 && Object.keys(touched).length == 0, "btnSignUp", "btnSelectedSignUp")}
                              disabled={Object.keys(errors || {}).length > 0}
                              style={{ textTransform: "capitalize", color: "white", marginBottom: "10%" }}
                              data-test-id="submit-btn"
                            >Continue</Button>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>

          </Grid>
        </Grid>
        </Box>
      </StyleWrapperSetupPodcastProfile>
      <Footer navigation={this.props.navigation} id={"1"} />
      </Box>
      </>

    );
  }

}
const StyleWrapperSetupPodcastProfile = styled("div")({
  padding:"20px 50px",
  background: "black",
  // margin: "0 auto",
  height: "auto",
  "& *": {
    boxSizing: "border-box",
  },
  "& .label1": {
    fontFamily: "Nexa-Heavy",
    fontWeight: 900,
    fontSize: "18px",
    color: "white",
    textAlign: "center",
    padding: "6% 14%"
  },
  "& .boxInside": {
    background: "#44403C",
    height: "223px",
    width: "320px",
    borderRadius: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },
  "& .btnSelectedSignUp": {
    marginBottom: "7%",
    cursor: "pointer",
    width: "392px",
    marginTop: "5%",
    height: "56px",
    borderRadius: "8px",
    fontFamily: "Nexa-Heavy",
    color: "white",
    margin: "auto",
    fontWeight: 900,
    display: "flex",
    fontSize: "14px",
    background: "#FF0807",
    "&:hover": {
      backgroundColor: "#FF0807",
    }
  },
  "& .boxSelected": {
    background: "#FF0807"
  },
  "& .boxContain": {
    background: "black",
    height: "289px",
    width: "320px",
    borderRadius: "16px",
    margin: "8px"
  },
  "& .boxContainer": {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: "6%",
    marginBottom: "8%"
  },
  "& .container": {
    // width: "90%",
    height: "auto",
    backgroundColor: "#312F2D",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItens: "center",
    margin: "auto",
    borderRadius: "0px 0px 8px 8px"
  },
  "& .btnSignUp": {
    marginBottom: "7%",
    cursor: "pointer",
    width: "392px",
    marginTop: "5%",
    height: "56px",
    borderRadius: "8px",
    fontFamily: "Nexa-Heavy",
    color: "white",
    margin: "auto",
    background: "#B2B3B3",
    fontWeight: 900,
    display: "flex",
    fontSize: "14px",
    "&:hover": {
      background: "#B2B3B3",
      color: "white"
    },
  },

  "& .btnBrowse": {
    height: "26px",
    width: "110px",
    background: "#FF0807",
    color: "white",
    textTransform: "capitalize",
    fontFamily: "Nexa-Heavy",
  },
  "& .title": {
    color: "white",
    fontFamily: "Nexa-Heavy",
    textTransform: "capitalize",
    paddingTop: "1%"
  },
  "& .label": {
    fontFamily: "Nexa-Heavy",
    color: "white",
    fontSize: "14px",
    fontWeight: 700,
    textTransform: "capitalize"
  },
  "& .field": {
    height: "56px",
    width: "954px",
  },
  "& .subContainer": {
    height: "auto",
    display: "flex",
    marginTop: "28px",
    justifyContent: "center",
    margin: "auto",
    width: "70%",

  },
  "& .redContainer": {
    height: "80px",
    margin: "auto",
    borderRadius: "16px 16px 0px 0px",
    backgroundColor: "#FF0807",    
  },
  "& .mainTitle": {
    margin: "auto",
    height: "40px",
    color: "white",
    fontFamily: "Nexa-Heavy",
    fontWeight: 900,
    fontSize: "30px",
    display: "flex",
    alignItems: "center",
    marginBottom: "21px"
  },
  "& .subContain": {
    position: "relative",
    height: "47rem",
    paddingRight: "40px",
    overflow: "hidden",
    overflowY: "scroll",
    scrollbarColor: "#FF0807 #4D4D4D",
    scrollbarWidth: "thin",
  },
})

// Customizable Area End
