import React from "react";


// Customizable Area Start
import {
  styled, Box, Grid, Typography, Button, IconButton, Dialog,
  DialogContent,
} from "@mui/material";
import { rectangle45, goBack } from "./assets";
import NavigationMenu from "../../../blocks/navigationmenu/src/NavigationMenu.web";
import Footer from "../../../blocks/navigationmenu/src/Footer.web";
import { handleNavigation } from "../../utilities/src/CustomHelpers";
import CloseIcon from '@mui/icons-material/Close';
import AudioPlayerComponent from "../../audiolibrary/src/AudioPlayerComponent.web";
const MIN_WINDOW_HEIGHT = 900
// Customizable Area End

import Settings2Controller, {
  Props,
  configJSON,
} from "./Settings2Controller";

export default class Settings2 extends Settings2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Box style={{ overflowY: "auto", maxHeight: "100%" }} ref={this.scrollRef}>
          <StyledWrapperSetting>
            <Box display="flex" alignItems="center" mb={3}>
              <img onClick={this.handleGoBack} src={goBack} alt="go Back" style={{ marginBottom: '20px', marginLeft: "16%", width: "2%" }} />
              <Typography variant="h4" sx={{ color: '#fff', marginBottom: '20px', marginLeft: "1%", fontWeight: "bold", fontFamily: "Nexa-Heavy", fontSize: "30px" }}>
                Settings
              </Typography>
            </Box>
            <Grid container spacing={3}>
              <Box className="main-container">
                <Box className="settings-container-1">
                  <Box className="outer-box-container-1">
                    <Typography className="inner-texts" variant="subtitle1" component="h2">How do you want to be notified?</Typography>
                    <Typography className="inner-texts" variant="subtitle1" component="h2" style={{ marginTop: '0%' }}>Email Notifications: <Typography variant="body1" component="span" style={{ fontFamily: "Nexa-Regular", marginLeft: "1%" }}>Comments Mentions</Typography></Typography>
                    <Typography className="inner-texts" variant="subtitle1" component="h2" style={{ marginTop: '-2%' }}>Push Notifications: <Typography variant="body1" component="span" style={{ fontFamily: "Nexa-Regular", marginLeft: "2%" }}>Likes, Comments, Mentions, Communitiy</Typography></Typography>
                    <Box className="button-container-1"><Button className="box-inner-button" data-test-id="change-notif-btn" onClick={() => { handleNavigation('SettingsNotifications', this.props) }}>Change Notifications</Button></Box>
                  </Box>
                </Box>
                <Box className="settings-container-2">
                  <Box className="outer-box-container-2">
                    <Typography className="inner-texts" variant="subtitle1" component="h2" style={{ marginLeft: "1%" }}>
                      Subscriptions
                    </Typography>
                    <Grid container spacing={2}>
                      {this.state.subscriptions.map((subscription, index) => (
                        <Grid item xs={4} key={index}>
                          <Box className="subscription-container">
                            <img
                              style={{ width: "60px", height: "60px" }}
                              src={rectangle45}
                            />
                            <Box style={{ flex: '1 0 auto', paddingLeft: "10px" }}>
                              <Typography variant="body1" className="inner-texts">
                                {subscription.name}
                              </Typography>
                              <Typography variant="body2" className="inner-texts-artists">
                                {subscription.artist}
                              </Typography>
                            </Box>
                            <Box style={{ alignSelf: 'center', marginLeft: 'auto', fontWeight: 'bold' }}>
                              <Typography variant="body1" className="inner-texts">
                                {subscription.price}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                    <Box className="button-container">
                      <Button variant="contained" className="box-inner-button">
                        See All Subscriptions
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box className="settings-container-3">
                  <Box className="outer-box-container-3">
                    <Typography className="inner-texts" variant="h6" component="h2">
                      Billing
                    </Typography>

                    <Grid container>
                      <Grid item xs={12} sm={4}>
                        <Typography className="inner-texts" variant="subtitle1" component="h2">
                          Free Resident Member Plan
                        </Typography>
                        <Typography variant="body1" component="span" className="inner-texts" style={{ fontFamily: "Nexa-Regular" }}>
                          Free Plan
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={4} className="grid-container" style={{ marginTop: "-1%", marginLeft: "1%" }}>
                        <Box>
                          <Typography className="inner-texts" variant="subtitle1" component="h2">
                            Card Details
                          </Typography>
                          <Typography
                            variant="body1"
                            component="span"
                            style={{ color: "#fff", fontFamily: "Nexa-Regular" }}
                          >
                            Visa ending on ****3235
                          </Typography>
                        </Box>
                        <Box>
                          <Button variant="outlined" className="inner-button" style={{ marginLeft: "25%", marginTop: "-3%" }}>
                            Edit
                          </Button>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={4} className="grid-container" style={{ marginTop: "-10%", marginLeft: "75%" }}>
                        <Box>
                          <Typography
                            className="inner-texts"
                            variant="subtitle1"
                            component="h2"
                            style={{
                              marginLeft: "7%",
                              whiteSpace: "nowrap",
                            }}
                          >
                            Address Information
                          </Typography>
                          <Typography
                            variant="body1"
                            component="span"
                            style={{ color: "#fff", fontFamily: "Nexa-Regular", marginLeft: "7%", whiteSpace: "nowrap" }}
                          >
                            Lorem Ipsum #03******
                          </Typography>
                        </Box>
                        <Box>
                          <Button variant="outlined" className="inner-button" style={{ marginLeft: "42%" }}>
                            Edit
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box className="settings-container-4">
                  <Box className="outer-box-container-4">
                    <Typography className="inner-texts" variant="h6" component="h2">Account</Typography>
                    <Box display="flex">
                      <Grid item xs>
                        <Button variant="outlined" className="account-inner-button-1" data-test-id="del-account" onClick={() => this.setState({ isModalOpen: true })}>Delete Account</Button>
                      </Grid>
                      <Grid item xs>
                        <Button variant="outlined" className="account-inner-button" data-test-id= "terms-conditions" onClick={() => {this.props.handleCommonPathNav("TermsConditions")}}>Terms & Conditions</Button>
                      </Grid>
                      <Grid item xs style={{ marginLeft: "1%" }}>
                        <Button variant="outlined" className="account-inner-button" data-test-id="privacy-policy" onClick={() => {
                          window.location.href = 'https://www.podcasttown.net/privacy-policy';
                        }}>Privacy Policy</Button>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </StyledWrapperSetting>
          <div style={{
            backgroundColor: "#030303"
          }}>
            <Footer navigation={this.props.navigation} id={"1"} />
          </div>
        </Box>
        <Dialog
          open={this.state.isModalOpen}
          PaperProps={{
            sx: {
              backgroundColor: "transparent",
              borderRadius: "8px 8px 32px 8px",
              width: "550px",
              height: "350px",
            },
          }}
        >
          <DialogContent
            style={{ backgroundColor: "#44403C", color: "white", padding: 0, }}

          >
            <IconButton
              data-test-id="onCloseModal"
              id="categoriesModal"
              onClick={this.handleCloseModal}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: 'white'
              }}
            >
              <CloseIcon />
            </IconButton>
            <Box id="categoriesModal" style={{ paddingTop: "35px" }}>
              <h2 style={{ textAlign: "center", fontFamily: "Nexa-Heavy" }}>Are You Sure You Want To Delete Your Account?</h2>
              <p style={{ textAlign: "center", fontFamily: "Nexa-Heavy" }}>This Step Cannot be Undone</p>
            </Box>
            <Box style={{
              width: "498px", height: "104px", borderTop: "1px solid white", display: "flex",
              alignItems: "center", marginTop: "25px",
              justifyContent: "flex-end",
            }}>

              <Button style={{
                height: "56px",
                width: "152px",
                borderRadius: "8px",
                backgroundColor: "black",
                textTransform: "capitalize",
                color: "white",
                fontWeight: 900,
                fontSize: "14px",
                margin: "10px"
              }} onClick={this.handleCloseModal}>Nevermind</Button>
              <Button style={{
                height: "56px",
                width: "152px",
                borderRadius: "8px",
                backgroundColor: "#FF0807",
                textTransform: "capitalize",
                color: "white",
                fontWeight: 900,
                fontSize: "14px",

                margin: "10px"
              }} onClick={this.handleDeleteAccount}>Yes, Delete</Button>

            </Box>

          </DialogContent>
        </Dialog>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const StyledWrapperSetting = styled("div")({
  margin: "0 auto",
  height: "auto",
  width: "100%",
  background: "black",
  "& *": {
    boxSizing: "border-box",
  },
  "& .settings-container-1": {
    backgroundColor: "#44403C",
    width: "76%",
    marginLeft: "18%"
  },
  "& .settings-container-2": {
    backgroundColor: "#312F2D",
    width: "76%",
    marginLeft: "18%"
  },
  "& .settings-container-3": {
    backgroundColor: "#030303",
    width: "76%",
    marginLeft: "18%"
  },
  "& .settings-container-4": {
    backgroundColor: "#44403C",
    width: "76%",
    marginLeft: "18%"
  },
  "& .outer-heading": {
    color: "white",
    fontSize: "30px",
    fontWeight: 900,
    lineHeight: "40px",
    paddingBottom: "16px",
    letterSpacing: "-0.005em",
    textAlign: "left",
    paddingLeft: "16px",
  },
  "& .container": {
    backgroundPosition: "center",
    width: "1412px",
    height: "87px",
    padding: "50px 0px 0px 0px",
    flexDirection: "row"
  },
  "& .main-container": {
    width: "95%",
    height: "auto"
  },
  "& .outer-box-container-1": {
    width: "95%",
    margin: "0 auto",
    padding: "40px 10px 10px 10px",
    boxSizing: "border-box",
    maxWidth: "70%",
  },
  "& .outer-box-container-2": {
    width: "95%",
    margin: "0 auto",
    padding: "10px",
    boxSizing: "border-box",
    maxWidth: "70%"
  },
  "& .outer-box-container-3": {
    width: "95%",
    margin: "0 auto",
    padding: "20px 60px 40px 20px",
    boxSizing: "border-box",
    maxWidth: "70%",
    display: 'flex',
    flexDirection: 'column',
    gap: "10%"
  },
  "& .outer-box-container-4": {
    width: "100%",
    margin: "0 auto",
    padding: "20px 60px 40px 20px",
    boxSizing: "border-box",
    maxWidth: "70%"
  },
  "& .subscription-container": {
    display: 'flex',
    color: '#fff',
    padding: '10px',
    backgroundColor: "#312F2D",
    border: "0px",
    borderRadius: '4px',
  },
  "& .grid-container": {
    display: "flex",
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  "& .inner-texts": {
    color: 'white',
    padding: '10px 0',
    fontWeight: "bold",
    fontSize: '16px',
    fontFamily: "Nexa-Heavy",
  },
  "& .button-container-1": {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "5%",
    marginBottom: "2%"
  },
  "& .button-container": {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    marginLeft: "5%",
    marginBottom: "2%"
  },
  "& .inner-texts-artists": {
    color: 'white',
    padding: '10px 0',
    fontWeight: "bold",
    fontSize: '16px',
    fontFamily: "Nexa-Heavy",
    marginTop: "-10%"
  },
  "& .box-inner-button": {
    backgroundColor: "#FF0807",
    color: "white",
    borderRadius: "8px",
    width: "184px",
    height: "32px",
    margin: "6px",
    padding: "6px 10px 6px 10px",
    textTransform: "capitalize",
    fontWeight: "bold",
    fontFamily: "Nexa-Heavy",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#FF0807"
    }
  },
  "& .inner-button": {
    color: "#fff",
    borderColor: "#fff",
    textTransform: "capitalize",
    fontWeight: "bold",
    padding: "10px 20px",
    marginRight: '10px',
    width: "5.5rem",
    height: "35.5px",
    borderRadius: "8px",
    "&:hover": {
      borderColor: "#fff",
    }
  },
  "& .account-inner-button": {
    color: "#fff",
    borderColor: "#fff",
    marginTop: "10px",
    textTransform: "capitalize",
    fontWeight: "bold",
    fontFamily: "Nexa-Heavy",
    width: "272px",
    height: "51px",
    borderRadius: "8px",
    marginLeft: '4%',
    "&:hover": {
      borderColor: "#fff"
    }
  },
  "& .account-inner-button-1": {
    color: "#fff",
    borderColor: "#fff",
    marginTop: "10px",
    textTransform: "capitalize",
    fontWeight: "bold",
    fontFamily: "Nexa-Heavy",
    width: "272px",
    height: "51px",
    borderRadius: "8px",
    "&:hover": {
      borderColor: "#fff"
    }
  }
})
// Customizable Area End
