import React, { createRef } from 'react'
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { next, prev } from "../../dashboard/src/assets";
import { getStorageData } from 'framework/src/Utilities';
export const configJSON = require("./config");


export interface Props {
    navigation: any;
    id: string;
    handleCommonPath:(path:string) => void;
}

interface IplaylistInfo {
    title: string;
    image: string;
}

interface ISelectedPlaylist {
    id: string;
    cover_image: string ; 
    name: string;
    description: string;
    episode_time: string | null; 
}

interface IMainPlaylist {
  id: string;
  cover_image: string ; 
  name: string;
  description: string;
  episode_time: string | null; 
  songAdded: boolean;
}

interface ArrowProps {
    onClick: () => void;
}

interface SS {
    id: any;
}
  
interface S {
    playlistInfo: IplaylistInfo | null;
    selectedPlaylistInfo: ISelectedPlaylist[];
    selectedPlaylist: number | null;
    addSongId: null | number;
    mainPlaylistInfo: IMainPlaylist[];
}



export default class OtherPlayListEditController extends BlockComponent<Props, S, SS> {

    getShowPlaylistApiCallId: string = ""
    getMainPlaylistApiCallId: string = ""
    getAddSongsApiCallId: string = ""
    scrollref: any = createRef();
  
    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);
      this.state = {
        playlistInfo: {
            title:"",
            image:""
        },
        selectedPlaylistInfo: [],
        selectedPlaylist: null,
        addSongId: null,
        mainPlaylistInfo: []
      };
  
      this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
      ];
      
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount(): Promise<void> {
        this.getSongPlaylist();
        this.getMainPlaylist()
        setTimeout(() => {
          if (this.scrollref.current) {
            this.scrollref.current.scrollTo({ top: 0, behavior: "smooth" });
          }
        }, 0);
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Received", message);
    
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
    
          const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
    
          if(this.getShowPlaylistApiCallId === apiRequestCallId) {
            this.handleShowplaylistResponse(from, message);
          }

          if(this.getMainPlaylistApiCallId === apiRequestCallId) { 
            this.handleMainPlaylistResponse(from, message);
          }

          if(this.getAddSongsApiCallId === apiRequestCallId) {
            this.handleAddSongApiResponse(from, message)
          }
        }
    }

    handleAllPlaylist = () => {
        this.props.handleCommonPath("Playlist")
    }

    handlePlaylistRowClick = (index: number) => {
        this.setState({ selectedPlaylist: index });
    }

    getSearchSlickSettings(){
        const innerWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
    
        return(
           {
            infinite: true,
            dots: false,
            slidesToShow: 1,
            speed: 800,
            slidesToScroll: 1,
            nextArrow: React.createElement(this.nextSearchArrow, { onClick: () => {}, innerWidth }),
            initialSlide: 0,
            // prevArrow: React.createElement(this.prevSearchArrow, { onClick: () => {}, innerWidth }),
            responsive: [
              {
                breakpoint: 1920,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                }
              },
              {
                breakpoint: 1700, 
                settings: {
                  slidesToShow: 1, 
                  slidesToScroll: 1
                }
              },
              {
                breakpoint: 1440,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1
                }
              },
            ]
           }
        )
    }
    
    prevSearchArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
        <img
        src={prev} 
        onClick={onClick} 
        alt="Previous Arrow"
        style={{
            left: innerWidth > 1440 ? "-21.5%" : "-0.5%",
            position: 'absolute',
            top: innerWidth > 1440 ? "55%" : "40%",
            transform: 'translateY(-50%)',
            zIndex: 1,
            cursor: 'pointer',
        }} 
        />
    )
    }
    
    nextSearchArrow = (props: ArrowProps & { innerWidth: number }) => {
    const { onClick, innerWidth } = props

    return(
        <img 
        onClick={onClick}
        src={next} 
        style={{
            position: 'absolute',
            top: innerWidth > 1440 ? "55%" : "40%",
            right: innerWidth > 1440 ? "-11.5%" : "-5.5%",
            transform: 'translateY(-50%)',
            zIndex: 1,
            cursor: 'pointer',
        }} 
        alt="Next Arrow"
        />
    )
    }

    handleAddBtnClick = async(song_id: string | number, index: number) => {

        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
  
        this.getAddSongsApiCallId = requestMessage?.messageId
  
        const getPlaylistId  = await getStorageData('selected_playlist_id')      
  
        let formdata = new FormData();
        formdata.append("song_id", song_id.toString())
        formdata.append("id", getPlaylistId);
        
        const token = await getStorageData('token')
        
        let cleanedToken = token.slice(1, -1);
  
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.addSong}`
        );
          
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formdata
        );
          
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify({
              token: cleanedToken
          })
        );

        this.setState({addSongId: index})
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiPostMethod
        );
  
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleAddSongApiResponse = async(from: string, message: Message) => {
      let apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiResponse?.message === "Song added successfully") {
        this.showAlert("Successs", apiResponse?.message);
        this.setState({addSongId: null})
        this.getSongPlaylist();
      }
      else {
        this.setState({addSongId: null})
        this.showAlert("Error", "Song Not Added")
      }
    }

    getSongPlaylist = async() => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
        
          this.getShowPlaylistApiCallId = requestMessage?.messageId
    
    
          const getPlaylistId  = await getStorageData('selected_playlist_id')     
    
          let playlistId = getPlaylistId;
    
          const token = await getStorageData('token')
      
          let cleanedToken = token.slice(1, -1);

          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify({
                "Content-Type": configJSON.validationApiContentType,
                token: cleanedToken
            })
            );
    
          requestMessage.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage),
              `${configJSON.songListApiEndPoint}/${playlistId}`
          );
    
          requestMessage.addData(
              getName(MessageEnum.RestAPIRequestMethodMessage),
              configJSON.validationApiMethodType
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);

    }

    handleShowplaylistResponse = async(from: string, message: Message) => {
        let apiResponse = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
    
          if(apiResponse.playlist.data.id) {
            
            const filteredPlaylist: ISelectedPlaylist[] = 
            apiResponse?.playlist?.data?.attributes?.songs?.data?.map((item: any) => ({
              id: item.id,
              cover_image: item.attributes.cover_image || null,
              name: item.attributes.name || '',
              description: item.attributes.description || '',
              episode_time: item.attributes.episode_time || null,
            })) || [];
          
          this.setState({ selectedPlaylistInfo: filteredPlaylist })
  
            this.setState({
              playlistInfo: {
                title: apiResponse?.playlist?.data?.attributes?.title || '',
                image: apiResponse?.playlist?.data?.attributes?.image || '',
              },
            });

          }
    }

    getMainPlaylist = async() => {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      this.getMainPlaylistApiCallId = requestMessage?.messageId

      const getPlaylistId  = await getStorageData('main_playlist_id')     

      let playlistId = getPlaylistId;

      const token = await getStorageData('token')
  
      let cleanedToken = token.slice(1, -1);

      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `${configJSON.songListApiEndPoint}/${playlistId}`
      );
    
      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify({
            "Content-Type": configJSON.validationApiContentType,
            token: cleanedToken
        })
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleMainPlaylistResponse = async(from: string, message: Message) => {
      let apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiResponse?.playlist?.data?.id) {
        const filteredPlaylist: IMainPlaylist[] = 
        apiResponse?.playlist?.data?.attributes?.songs?.data?.map((item: any) => ({
          id: item.id,
          name: item.attributes.name || '',
          description: item.attributes.description || '',
          cover_image: item.attributes.cover_image || null,
          songAdded: item.attributes.added.song_added,
          episode_time: item.attributes.episode_time || null,
        })) || [];

        this.setState({mainPlaylistInfo: filteredPlaylist})
      }
    }
}